<div class="slim-mainpanel">
  <div class="container-fluid pd-lg-x-120">
    <div class="card pd-20 mg-t-60">
      <div class="row mg-l-0 mg-r-0 justify-content-between align-items-center">
        <h2 class="pd-l-0 tx-primary">
          <i class="ion-document-text pd-r-10"></i>
          Search results
        </h2>
      </div>
      <div class="table-wrapper table-responsive">
      <table
        id="datatable1"
        class="table table-expandable display responsive nowrap"
      >
        <thead>
          <tr>
            <th class="text-center"></th>
            <th class="text-center">Contract Number</th>
            <th class="text-center">Contract Status</th>
            <th class="text-center">Coverage Type</th>
            <th class="text-center">VIN</th>
            <th class="text-center">Vehicle</th>
            <th class="text-center">Customer name</th>
            <th class="text-center"></th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let contract of contracts; let i = index">
            <tr>
              <td class="text-center">
                <i
                  (click)="previewDetails1(contract, i)"
                  *ngIf="contractListShow[i]"
                  class="ion-ios-arrow-up"
                  data-toggle="collapse"
                  [attr.data-target]="'#collapse' + i"
                ></i>
                <i
                  (click)="previewDetails1(contract, i)"
                  *ngIf="!contractListShow[i]"
                  class="ion-ios-arrow-down"
                  data-toggle="collapse"
                  [attr.data-target]="'#collapse' + i"
                ></i>
              </td>
              <td class="text-center">{{ contract?.contractNumber }}</td>
              <td class="text-center">{{ contract?.contractStatus }}</td>
              <td class="text-center">{{ contract?.coverageType }}</td>
              <td class="text-center">{{ contract?.vehicle.vIN }}</td>
              <td class="text-center">
                {{ contract?.vehicle?.year }} {{ contract?.vehicle?.make }}
                {{ contract?.vehicle?.model }}
              </td>
              <td class="text-center">
                {{ contract?.customer?.firstName }}
                {{ contract?.customer?.lastName }}
              </td>
              <td class="text-center">
                <button *ngIf="!contract?.disable"
                  (click)="setContract(contract, true)"
                  class="btn btn-oblong btn-primary btn btn-sm"
                >
                  Start new claim
                </button>
                <div *ngIf="contract?.disable"  placement="top" tooltipClass="balance-contract-text" [ngbTooltip]="contract?.disableMessage" >
                    <button 
                    disabled
                    class="btn btn-oblong btn-primary btn btn-sm"
                    >
                    Start new claim
                    </button>
              </div>
              </td>
            </tr>
            <tr class="hide-table-padding">
              <td colspan="12" class="p-0 m-0">
                <div
                  id="collapse{{ i }}"
                  class="card row mg-l-0 mg-r-0 collapse out"
                >
                  <app-claim-list-detail-tabs
                    [customerInformation]="customerInformation1 + i"
                    [vehicleInformation]="vehicleInformation1 + i"
                    [contractInformation]="contractInformation1 + i"
                    [indexNum]="indexNum + i"
                  ></app-claim-list-detail-tabs>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
    </div>
  </div>
</div>
