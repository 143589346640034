import { DatePipe, getNumberOfCurrencyDigits } from '@angular/common';
import { ReturnStatement } from '@angular/compiler';
import { Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Attachment } from 'src/app/_model/attachment';
import { Claim } from 'src/app/_model/claim';
import { Job } from 'src/app/_model/job';
import { Labor } from 'src/app/_model/labor';
import { Note } from 'src/app/_model/note';
import { Part } from 'src/app/_model/part';
import { Sublet } from 'src/app/_model/sublet';
import { ClaimService } from 'src/app/_services/claim.service';

@Component({
  selector: 'app-claim-submit',
  templateUrl: './claim-submit.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./claim-submit.component.css'],
})
export class ClaimSubmitComponent implements OnInit, OnDestroy {
  showDetails: boolean = false;
  showDetails1: boolean = false;
  showDetails2: boolean = false;
  showDetailsTotal2: boolean = true;
  showDetailsTotal1: boolean = true;
  customerInformation1: string = 'customerInformation1';
  vehicleInformation1: string = 'vehicleInformation1';
  contractInformation1: string = 'contractInformation1';
  customerInformation2: string = 'customerInformation2';
  vehicleInformation2: string = 'vehicleInformation2';
  contractInformation2: string = 'contractInformation2';
  noteForm: FormGroup;
  noteList: Note[] = [];
  claimSupportAttachments: Attachment[] = [];
  requestPaymentAttachments: Attachment[] = [];

  claimSubscription: Subscription;
  claim: Claim;
  uploadForm: FormGroup;
  jobPopupOpened: boolean = false;
  jobList: Job[] = [];
  jobList1Show: boolean[] = [];
  jobList2Show: boolean[] = [];
  stepUpdated: boolean = false;
  jobDetails:Job;
  labor:Labor;
  part:Part;
  sublet: Sublet;
  claimSubmitted:boolean = false;
  disabledField:boolean = false;
  indexNum=0;
  header:string="Delete Claim";
  message:string = "";
  buttonMessage:string="Delete"
  addNotePressed: boolean = false;
  uploadAttachmentPressed: boolean = false;
  submittedNote:boolean = false;
  files:File[]=[];
  lossDate:string="";
  createdDate: string = "";
  scsSentDate: string = "";
  paymentRequestForm: FormGroup;
  paymentRequesting: boolean = false;
  paymentRequestSubmitted: boolean = false;
  filesPayment: File[] = [];
  claimId: string;
  popUpTitle: string ="";
  popUpText: string = "";
  typeNum : number = 0;
  partTax : number = 0;
  laborTax : number = 0;
  types: string = "image/bmp,text/csv,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/gif,text/html,image/vnd.microsoft.icon,image/jpeg,application/pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.rar,application/rtf,image/tiff,text/plain,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/xml,text/xml,application/zip,image/jng,image/x-png,image/png";
  typeList:string[] = this.types.split(',');
  payeeNameLabel: string = "";
  paymentMethod: string = "";
  emailOrFaxValue: string = "";
  transformedFax: string = "";
  emailOrFaxLabel: string = "";

  submissionForMechanicalClaim: FormGroup;
  mechanicalUnder1500: boolean = false;
  submittedMechanicalClaim: boolean = false;

  @ViewChildren('tt') mytooltips:QueryList<NgbTooltip>;
  type: string;

  exportReportCalled: boolean = false;
  exportButtonVisible: boolean = false;

  mechanicalSelfAuthMax: number = 0;

  constructor(
    private readonly fb: FormBuilder,
    private claimService: ClaimService,
    private formBuilder: FormBuilder,
    private router: Router,
    public datepipe: DatePipe,
    private toastr: ToastrService
  ) {
    this.noteForm = this.fb.group({
      claimId: [],
      noteText: [],
    });
    this.submissionForMechanicalClaim = this.fb.group({
      reviewType: ['manualReview', Validators.required]
    });

    this.exportButtonVisible = this.claimService?.exportButtonVisible;
  }
  ngOnInit(): void {
    this.toastr.clear();
    window.scroll(0,0);
    this.disabledField = this.claimService.previewActiveClaim;

    this.claimSubscription = this.claimService.claim.subscribe((value) => {
      this.claim = value;
      this.mechanicalSelfAuthMax = this.claimService?.selfAuthMax;
      
      if (this.claimService?.automationEnabled && this.claim?.totalRequested <= this.claimService?.selfAuthMax && this.claim?.coverageType == "MECHANICAL") {
        this.mechanicalUnder1500 = true;
        this.submissionForMechanicalClaim.controls.reviewType.setValue("manualReview");
      } else {
        this.mechanicalUnder1500 = false;
      }

      let date = new Date(this.claim.lossDate);
      let dateOffset = new Date().getTimezoneOffset()/60;
      let tmpDate:Date = date;
      tmpDate.setHours(date.getHours() + dateOffset + 1);

      this.lossDate = this.datepipe.transform(tmpDate,'MM/dd/yyyy');

      this.createdDate = this.adjustDate(this.claim.sysDateI);
      
      let statusSending: any = this.claim.statusHistory.filter(e=> e.newValue == 70);

      this.scsSentDate = this.adjustDate(statusSending[0]?.sysDateI);

      this.transformedFax = this.transformFax(this.claim.fax);
      
    this.laborTax = this.claimService.laborTax;
    this.partTax = this.claimService.partTax;
    this.payeeNameLabel = this.claimService.payeeNameLabel;
    if (this.claim.payMethod || this.claimService.paymentMethod) {
    this.paymentMethod = this.claimService.paymentMethod;
    if( this.claim.payMethod) {
      this.paymentMethod = this.claim.payMethod;
    }
    
    let billingMethod: string = this.claim.billingMethod;
    if( this.claim.billingMethod) {
      billingMethod = this.claim.billingMethod;
    }
    if (this.paymentMethod == 'Credit Card') {
      if (billingMethod?.toLowerCase() == "e-mail") {
        this.emailOrFaxLabel = "E-Mail";
        this.emailOrFaxValue = this.claim.email;

      } else {
        this.emailOrFaxLabel = "Fax";
        this.emailOrFaxValue = this.transformedFax;
      }
    }
  }

      this.getNotes();
      this.claimService.getJobs(this.claim.id).subscribe(
        (result: any) => {

          this.jobList = result;
          if (this.jobList) {
            for (let i = 0; i < this.jobList.length; i++) {
              this.jobList1Show[i] = false;
              this.jobList2Show[i] = false;
            }
          }
        },
        (error) => {
          console.log(error);
          this.toastr.error('Something went wrong while fetching jobs, please try again. ' + 'Error code: '+error.status, 'Create claim', {
            positionClass: 'toast-top-center', timeOut:6000
          });
        }
      );
    });
    this.uploadForm = this.formBuilder.group({
      profile: [''],
      uploadFile: [''],
    });
    this.paymentRequestForm = this.fb.group({
      paymentRequestText: [, Validators.required]
    });
    this.getAttachments();
  }
  ngOnDestroy() {
    this.claimSubscription?.unsubscribe();
  }
  setJob(i){
    if(!this.mytooltips.toArray().find(elem=>elem.isOpen())){
      this.jobDetails=this.jobList[i];
      console.log("open " +i);
    } else console.log("skip "+i)
    return true;
  }

  openToolTip(i){
    if(!this.mytooltips.toArray().find(elem=>elem.isOpen())){
      this.jobDetails=this.jobList[i];
      this.mytooltips.toArray()[i].open();
    }
  }
  setLabor(labor){
    this.labor=labor;
    return true;
  }
  setSublet(sublet:Sublet){
    this.sublet=sublet;
    return true;
  }
  setPart(part){
    this.part=part;
    return true;
  }
  previewDetails() {
    this.showDetails = !this.showDetails;
  }
  getNotes() {
    this.claimService.getNotes(this.claim.id).subscribe(
      (result: any) => {
        this.noteList = result;
      },
      (error) => {
        console.log(error);
        this.toastr.error('Something went wrong while fetching notes, please try again. ' + 'Error code: '+error.status, 'Create claim', {
          positionClass: 'toast-top-center', timeOut:6000
        });
      }
    );
  }
  resetNoteForm(){
    this.addNotePressed = false;
    this.noteForm.controls.noteText.setValue(null);
  }
  get fNote(){
    return this.noteForm.controls;
  }

  resetMechanicalClaimSubmit() {
    this.submittedMechanicalClaim = false;
    this.submissionForMechanicalClaim.controls.reviewType.setValue('manualReview');
  }
  get fSubmissionForMechanicalClaim(){
    return this.submissionForMechanicalClaim.controls
  }
  submitNoteForm() {

    this.submittedNote = true;
    if (!this.noteForm.controls.noteText.value) return;

    if(this.addNotePressed)
      return;
    this.submittedNote = false;
    this.addNotePressed = true;
    
    let noteData: Note = {
      text: this.noteForm.controls.noteText.value,
      author: this.claimService.userName,
      //add is aftermarket
      claimId: this.claim.id,
      createDate:new Date().toString()
      //dodati update claim-a na step 3
    };
    this.claimService.addNote(this.claim.id, noteData).subscribe(
      (result: any) => {
        this.noteForm.controls.noteText.setValue(null);
        this.getNotes();   
        
        if(this.claimService.previewActiveClaim){
          this.toastr.success('Successfully added note and sent to Zurich.', 'Notes', {
            positionClass: 'toast-top-center',
          });
        }
        else {
          this.toastr.success('Successfully added note', 'Notes', {
            positionClass: 'toast-top-center',
          });
        }
        this.addNotePressed = false;
      },
      (error) => {
        this.addNotePressed = false;
        console.log(error);
        if(error.status && error.status == 403){
          this.toastr.error(
            'The claim is being edited by another user, please try again later.',
            'Create claim',
            {
              positionClass: 'toast-top-center',
            }
          );
        } else 
        this.toastr.error(
          'Something went wrong while adding note, please try again. ' + 'Error code: '+error.status,
          'Attachments',
          {
            positionClass: 'toast-top-center',
          }
        );
      }
    );
  }
  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.uploadForm.get('profile').setValue(file);
    }
  }
  onSubmit() {
    this.uploadForm.controls.uploadFile.setValue('');
    const formData = new FormData();
    formData.append('file', this.uploadForm.get('profile').value);

    if(this.uploadAttachmentPressed)
      return;
    
    this.uploadAttachmentPressed=true;

    this.claimService.addAttachment(this.claim.id, formData).subscribe(
      (res) => {
        this.getAttachments();
        this.uploadForm.controls.uploadFile.setValue('');
        if(this.claimService.previewActiveClaim){
          this.toastr.success('Successfully added attachment and sent to Zurich.', 'Attachments', {
            positionClass: 'toast-top-center',
          });
        }
        else {
          this.toastr.success('Successfully added attachment', 'Attachments', {
            positionClass: 'toast-top-center',
          });
        }

        this.uploadAttachmentPressed= false;
      },
      (error) => {
        this.uploadAttachmentPressed= false;
        this.removeFiles();
        console.log(error);
        if(error.status && error.status == 403){
          this.toastr.error(
            'The claim is being edited by another user, please try again later.',
            'Create claim',
            {
              positionClass: 'toast-top-center',
            }
          );
        } else 
        this.toastr.error(
          'Something went wrong while adding attachment, please try again. ' + 'Error code: '+error.status,
          'Attachments',
          {
            positionClass: 'toast-top-center', timeOut:6000
          }
        );
      }
    );
  }

  getAttachments() {
    this.claimService.getAttachments(this.claim.id, "CLAIM").subscribe(
      (result: any) => {
       this.claimSupportAttachments = [];
       this.requestPaymentAttachments = [];
       for(let i =0; i<result?.length; i++) {
         if (result[i].type != "payment-request" || !result[i].type) {
          this.claimSupportAttachments.push(result[i]);
         }
         if (result[i].type == "payment-request") {
          this.requestPaymentAttachments.push(result[i]);
         }
       }
      },
      (error) => {
        console.log(error);
        this.toastr.error('Something went wrong while fetching attachments, please try again. ' + 'Error code: '+error.status, 'Create claim', {
          positionClass: 'toast-top-center', timeOut:6000
        });
      }
    );
    // this.claimService.getAttachments(this.claim.id, "PAYMENT_REQUEST").subscribe(
    //   (result: any) => {
    //     console.log(result);
       
    //    for(let i =0; i<result?.length; i++) {
    //      if (result[i].type == "payment-request") {
    //       this.requestPaymentAttachments.push(result[i]);
    //      }
    //    }
    //   },
    //   (error) => {
    //     console.log(error);
    //     this.toastr.error('Something went wrong while fetching attachments, please try again. ' + 'Error code: '+error.status, 'Create claim', {
    //       positionClass: 'toast-top-center', timeOut:6000
    //     });
    //   }
    // );
  }

  deleteAttachment(attachment) {
    this.claimService.deleteAttachment(attachment.fileId).subscribe(
      (result: any) => {
        this.toastr.success('Successfully deleted attachment', 'Attachments', {
          positionClass: 'toast-top-center',
        });
        this.getAttachments();
      },
      (error) => {
        console.log(error);
        if(error.status && error.status == 403){
          this.toastr.error(
            'The claim is being edited by another user, please try again later.',
            'Create claim',
            {
              positionClass: 'toast-top-center',
            }
          );
        } else 
        this.toastr.error('Something went wrong while deleting attachment, please try again. ' + 'Error code: '+error.status, 'Create claim', {
          positionClass: 'toast-top-center', timeOut:6000
        });
      }
    );
  }
  deleteNote(note) {
    let noteId = note?.id ? note.id : note._id;
    this.claimService.deleteNote(note.claimId, noteId).subscribe(
      (result: any) => {
        this.toastr.success('Successfully deleted note', 'Notes', {
          positionClass: 'toast-top-center',
        });
        this.getNotes();
      },
      (error) => {
        console.log(error);
        if(error.status && error.status == 403){
          this.toastr.error(
            'The claim is being edited by another user, please try again later.',
            'Create claim',
            {
              positionClass: 'toast-top-center',
            }
          );
        } else 
        this.toastr.error('Something went wrong while deleting note, please try again. ' + 'Error code: '+error.status, 'Notes', {
          positionClass: 'toast-top-center', timeOut:6000
        });
      }
    );
  }
  previewDetails1Table(i) {
    this.jobList1Show[i] = !this.jobList1Show[i];
  }
  previewDetails2Table(i) {
    this.jobList2Show[i] = !this.jobList2Show[i];
  }

  previewDetails1() {
    this.showDetails1 = !this.showDetails1;
  }

  previewDetails2() {
    this.showDetails2 = !this.showDetails2;
  }
  previewDetailsTotal1() {
    this.showDetailsTotal1 = !this.showDetailsTotal1;
  }
  previewDetailsTotal2() {
    this.showDetailsTotal2 = !this.showDetailsTotal2;
  }
  previewDetailsRow1() {
    this.showDetails1 = !this.showDetails1;
  }
  previewDetailsRow2() {
    this.showDetails2 = !this.showDetails2;
  }

  calculateSumDeductibles() {
    let sum = 0;
    for (let i = 0; i < this.jobList.length; i++)
      sum += this.jobList[i].deductibleAmount;
    return sum;
  }

  mechanicalClaimSubmit() {
    this.submittedMechanicalClaim = true;
    this.updateStep(true);
  }

  updateStep($event) {

    if(this.claimSubmitted || !$event)
      return;


    this.claimSubmitted=true;

    let claimObj: Claim = {
      step: 3,
      id: this.claim.id
    };
    let userInfo = JSON.parse(localStorage.getItem('myAccountData'));


    let tmpZipCode = userInfo?.zip_code?.trim();
    if(tmpZipCode?.length >= 5)
      tmpZipCode = tmpZipCode.substring(0, 5);

    let tmpClaimDataObj = {
      accountName: userInfo.account_name,
      accountNumber: userInfo.account_number,
      address1: userInfo.addr_ln1,
      address2: userInfo.addr_ln2,
      city: userInfo.city,
      email: userInfo.user_email,
      name: userInfo.user_name,
      phone: userInfo.user_phone,
      roles: userInfo.user_roles,
      state: userInfo.state_abbr,
      userAcceptsTextMsgs: userInfo.user_accepts_text_msgs,
      zip: tmpZipCode
    }

    let claimDataObj;
    let stpRequested: boolean = false;

    if (this.mechanicalUnder1500) {
      if (this.submissionForMechanicalClaim.controls?.reviewType?.value == "selfAuthorized") {
        stpRequested = true;
      } else {
        stpRequested = false;
      }
      claimDataObj = {
        ...tmpClaimDataObj,
        stpRequested: stpRequested
      }
    } else {
      claimDataObj = tmpClaimDataObj;
    }

    this.claimService.sendClaim(claimDataObj, claimObj.id).subscribe(
      (value) => {
        this.toastr.success('Successfully submitted claim', 'Submit claim', {
          positionClass: 'toast-top-center',
        });
       
        document.getElementById("closeConfirmActionButton1")?.click();
        document.getElementById("cancelMechanicalClaimModal")?.click();

        let notifyUserBussinessHours: boolean = false;
        let date = new Date();
        //sunday is 0

        if(date.getDay() >= 1 && date.getDay() <= 5){
          if(date.getHours() >= 20){
            notifyUserBussinessHours = true;
          }
        } else if(date.getDay() == 6){
          if(date.getHours() >= 14){
            notifyUserBussinessHours = true;
          }
        } else if(date.getDay() == 0){
          notifyUserBussinessHours = true;
        }

        let time: number = 5000;
        if(notifyUserBussinessHours){
          this.toastr.info("Claims submitted after 8pm CT Monday - Friday, after 2pm CT Saturday, anytime on Sunday will be processed on the next business day.", 'Submit claim', {
            positionClass: 'toast-top-center',
          });
          time = 7000;
        }
        setTimeout(()=> this.setHome(), time);
        
        // this.claimService.updateClaim(claimObj).subscribe(
        //   (result:any) => {
        //     this.stepUpdated = true;
        //     this.claimService.updateClaimStatus(result.id, 20).subscribe(claimUpdated=>{
        //       this.toastr.success('Successfully submitted claim', 'Submit claim', {
        //         positionClass: 'toast-top-center',
        //       });
        //       document.getElementById("closeConfirmActionButton1").click();
        //       this.setHome();
              
        //     },(error) => {
            
        //       this.claimSubmitted = false;
        //       console.log(error);
        //       this.toastr.error(
        //         'Something went wrong while updating claim status, please try again. ' + 'Error code: ' + error.status,
        //         'Submit claim',
        //         {
        //           positionClass: 'toast-top-center',
        //         }
        //       );
        //     });
            
        //   }
        //   ,  (error) => {
            
        //     this.claimSubmitted = false;
        //     console.log(error);
        //     this.toastr.error(
        //       'Something went wrong while updating claim, please try again. ' + 'Error code: ' + error.status,
        //       'Submit claim',
        //       {
        //         positionClass: 'toast-top-center',
        //       }
        //     );
        //   });
      },
      (error) => {
        // setTimeout(()=>this.claimSubmitted = false,500);
        console.log(error);
     
        this.claimSubmitted = false;
        if(error.status && error.status == 403){
          this.toastr.error(
            'The claim is being edited by another user, please try again later.',
            'Create claim',
            {
              positionClass: 'toast-top-center',
            }
          );
        } else 
        this.toastr.error(
          'Something went wrong while sending claim, please try again. ' + 'Error: ' + error?.error?.message,
          'Submit claim',
          {
            positionClass: 'toast-top-center',
          }
        );
      }
    );
  }
  goToHomeScreen() {
    this.updateStep(false);
    this.router.navigate(['']);
  }

  downloadAttachment(attachment) {
    this.claimService.downloadAttachment(attachment).subscribe(
      (result: any) => {

        const blob = new Blob([result], {
          type:
            result.type,
        });
        // const url = window.URL.createObjectURL(blob);
        // window.open(url);
        let link = window.document.createElement("a");
       link.href = window.URL.createObjectURL(blob);
       link.download = attachment.filename;
       link.click();
      },
      (error) => {
        console.log(error);
        if(error.status && error.status == 403){
          this.toastr.error(
            'The claim is being edited by another user, please try again later.',
            'Create claim',
            {
              positionClass: 'toast-top-center',
            }
          );
        } else 
        this.toastr.error(
          'Something went wrong while dowloading file, please try again. ' + 'Error code: ' + error.status,
          'Submit claim',
          {
            positionClass: 'toast-top-center',
          }
        );
      }
    );
  }
  setHome() {
    this.claimService.currentUrl.next('HOME');
    this.router.navigate(['']);
  }
  transformFax(faxValue) {
    if (!faxValue) return;
    let newVal =faxValue?.replace(/\D/g, '');
    if (!newVal)
      return;
    
   
    if (newVal?.length === 0) {
      newVal = '';
    } else if (newVal?.length <= 3) {
      newVal = newVal?.replace(/^(\d{0,3})/, '($1)');
    } else if (newVal?.length <= 6) {
      newVal = newVal?.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
    } else if (newVal?.length <= 10) {
      newVal = newVal?.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
    } else {
      newVal = newVal?.substring(0, 10);
      newVal = newVal?.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
    }
    //this.claim.fax=newVal;
    return newVal;
  }
  getDeductible(job:Job){
    if(!job?.deductibleAmount)
      return 0;
    else return job?.deductibleAmount;
  }
  getDeductiblesTotal(){
    let deductibleSum=0;
    for (let i = 0; i < this.jobList.length; i++){
        deductibleSum += this.getDeductible(this.jobList[i]);
    }
    return deductibleSum;
  }
  deleteClaim($event){
    if($event && !this.claimService.startClaimFromSearch){
      this.claimService.deleteClaim(this.claimService.claim.value.id).subscribe((result:any)=>{
        document.getElementById("closeConfirmActionButton0").click();
        this.toastr.success('Successfully deleted claim', '', {
          positionClass: 'toast-top-center',
        });
        this.router.navigate(['']);
      }, (error) => {
        console.log(error);
        if(error.status && error.status == 403){
          this.toastr.error(
            'The claim is being edited by another user, please try again later.',
            'Create claim',
            {
              positionClass: 'toast-top-center',
            }
          );
        } else 
        this.toastr.error(
          'Something went wrong while deleting claim, please try again. ' + 'Error code: '+error.status,
          'Create claim',
          {
            positionClass: 'toast-top-center',
          }
        );
      })
    } else if($event && this.claimService.startClaimFromSearch){
      document.getElementById("closeConfirmActionButton0").click();
      this.toastr.success('Successfully deleted claim', '', {
        positionClass: 'toast-top-center',
      });
      this.router.navigate(['']);
    }

  }
  setClaimDelete(){
    this.message="Are you sure you want to delete this claim?";
    this.header = "Delete Claim"
    this.buttonMessage = "Delete";
  }
  submitClaim(){
    this.header = "Submit Claim"
    this.buttonMessage = "Submit";
    this.message="Submission of this claim is an acknowledgement that the charges being submitted are accurate and appropriate. Reimbursement is solely based on the terms and conditions of the Vehicle Service Contract. Only authorized charges will be paid.";
  }
  onSelectFiles($event) {
    if(!this.files || this.files.length <=0 ){
      this.files = $event.addedFiles;
      this.uploadFiles();
    }
    else{
      for(let i = 0; i < $event?.allowedFiles?.length; i++)
        this.files.push($event?.allowedFiles[i]);
        this.uploadFiles();
    }
  }
  onRemove(i:number){
    if(i >= 0 && i < this.files?.length)
      this.files.splice(i, 1);
  }
  uploadFiles(){
   
    if(this.files && this.files.length > 0){
      this.toastr.info('Uploading attachments', 'Attachments', {
        positionClass: 'toast-top-center',
      });
      let tmpFiles:File[]= [];

      for(let i = 0; i < this.files?.length; i++){
        if(this.typeList.some((type)=>this.files[i].type == type)) {
        const formData = new FormData();
        formData.append('file', this.files[i]);

        this.claimService.addAttachment(this.claim.id, formData).subscribe(
          (res) => {
            tmpFiles.push(this.files[i]);

            if(this.claimService.previewActiveClaim){
              this.toastr.success('Successfully added attachment and sent to Zurich.', 'Attachments', {
                positionClass: 'toast-top-center',
              });
            }
            else {
              this.toastr.success('Successfully added attachment', 'Attachments', {
                positionClass: 'toast-top-center',
              });
            }
            this.getAttachments();
            if(this.files?.length == tmpFiles.length)
              this.removeFiles();
          },
          (error) => {
            console.log(error);
            this.removeFiles();
            if(error.status && error.status == 403){
              this.toastr.error(
                'The claim is being edited by another user, please try again later.',
                'Create claim',
                {
                  positionClass: 'toast-top-center',
                }
              );
            } else 
            this.toastr.error(
              'Something went wrong while adding attachment, please try again. ' + 'Error code: '+error.status,
              'Attachments',
              {
                positionClass: 'toast-top-center', timeOut:6000
              }
            );
          }
        );
      } else {
        tmpFiles.push(this.files[i]);
        
        this.toastr.error(
          'File: "' + this.files[i].name + '" has unsuported type.',
          'Attachments',
          {
            positionClass: 'toast-top-center', timeOut:6000
          }
        );
        if(this.files?.length == tmpFiles.length)
              this.removeFiles();
      }
      }
    }

  }
  removeFiles(){
    this.uploadAttachmentPressed=false;
      this.files=[];
  }

  get fPaymentRequest(){
    return this.paymentRequestForm.controls;
  }
  paymentRequestClose() {
    this.paymentRequestForm.controls.paymentRequestText.setValue(null);
    this.paymentRequestSubmitted = false;
    this.paymentRequesting = false;
  }
  resetPaymentRequest() {
    this.paymentRequestForm.controls.paymentRequestText.setValue(null);
    this.paymentRequestSubmitted = false;
    this.paymentRequesting = false;
    this.removePaymentFiles();
  }
  getClaim(claimId:string){
    this.claimService.getClaimById(claimId).subscribe(
      (result: any) => {
        this.claimService.claim.next(result);
      }
    );
  }
  submitPaymentRequestForm() {
    this.paymentRequestSubmitted = true;
   
    if (!this.paymentRequestForm.valid || !this.paymentRequestForm.controls.paymentRequestText?.value || this.paymentRequesting) {
      this.paymentRequesting = false;
      return;
    }
    this.paymentRequesting = true;
    let comment = this.paymentRequestForm.controls.paymentRequestText?.value;
    if(this.filesPayment && this.filesPayment.length > 0){

      let tmpFiles:File[]= [];
      for(let i = 0; i < this.filesPayment?.length; i++) {
        if(this.typeList.some((type)=>this.filesPayment[i].type == type)) {

        const formData = new FormData();
        formData.append('file', this.filesPayment[i]);

        let counter = i;
        this.claimService.addAttachmentWithAdditionalParams(this.claim.id, formData, null, this.type).subscribe(
          (res) => {
            tmpFiles.push(this.filesPayment[counter]);

            if(this.filesPayment?.length == tmpFiles.length) {
              // this.toastr.success('Successfully requested payment', 'Request payment', {
              //   positionClass: 'toast-top-center',
              // });
              this.requestPaymentCall(comment, this.type);

              this.paymentRequestForm.controls.paymentRequestText?.value;
              this.removePaymentFiles();
            }
          },
          (error) => {
            console.log(error);
            this.removePaymentFiles();
            this.paymentRequestSubmitted = false;
            this.paymentRequesting = false;

            if(this.typeNum == 1 ) {
            this.toastr.error(
              'Something went wrong while requesting payment, please try again. ' + 'Error code: '+error.status,
              'Request payment',
              {
                positionClass: 'toast-top-center', timeOut:6000
              }
            );
            } else {
              this.toastr.error(
                'Something went wrong while adding attachments, please try again. ' + 'Error code: '+error.status,
                'Attachments',
                {
                  positionClass: 'toast-top-center', timeOut:6000
                }
              );
            }
          }
        );

      } else {
        tmpFiles.push(this.files[i]);
        
        this.toastr.error(
          'File: "' + this.files[i].name + '" has unsuported type.',
          'Attachments',
          {
            positionClass: 'toast-top-center', timeOut:6000
          }
        );
        if(this.files?.length == tmpFiles.length)
              this.removeFiles();
      }
    }
    } else {
      this.requestPaymentCall(comment, this.type);
    }

  }
  requestPaymentCall(comment, type) {
    this.claimService.addAttachmentWithAdditionalParams(this.claim.id, null, comment, this.type).subscribe(
      (res) => {
        this.getClaim(this.claim.id);

        if(this.typeNum == 1 ) {
          this.toastr.success('Successfully requested payment', 'Request Payment', {
            positionClass: 'toast-top-center',
          }); 
        } else {
            
          this.toastr.success('Successfully added attachments', 'Attachments', {
            positionClass: 'toast-top-center',
          });
          }
          document.getElementById('paymentRequestModalButton').click();
          this.paymentRequestForm.controls.paymentRequestText?.value;
          this.removePaymentFiles();
          this.paymentRequestSubmitted = false;
          this.paymentRequesting = false;
          this.claimId = null;
          this.getNotes();   
          this.getAttachments();
      },
      (error) => {
        console.log(error);
        this.removePaymentFiles();
        this.paymentRequestSubmitted = false;
        this.paymentRequesting = false;

        if(this.typeNum == 1) {
        this.toastr.error(
          'Something went wrong while requesting payment, please try again. ' + 'Error code: '+error.status,
          'Request payment',
          {
            positionClass: 'toast-top-center', timeOut:6000
          }
        );
        } else {
          this.toastr.error(
            'Something went wrong while adding attachments, please try again. ' + 'Error code: '+error.status,
            'Attachments',
            {
              positionClass: 'toast-top-center', timeOut:6000
            }
          );
        }
      }
    );
  }
  onSelectFilesPayment($event) {
    if (!this.filesPayment || this.filesPayment.length <= 0) {
      this.filesPayment = $event.addedFiles;
      //this.uploadFiles();
    }
    else {
      for (let i = 0; i < $event?.addedFiles?.length; i++)
        this.filesPayment.push($event?.addedFiles[i]);
      // this.uploadFiles();
    }
  }

  removePaymentFiles(){
    this.filesPayment=[];
}
onRemovePaymentFiles(i:number){
  if(i >= 0 && i < this.filesPayment?.length)
    this.filesPayment.splice(i, 1);
}
setType(type, typeNum){
  this.type = type;
  this.typeNum = typeNum;
  if( typeNum == 1) {
    this.popUpTitle='Request payment';
    this.popUpText = 'Attach completed RO to request payment';
  } else {
    this.popUpTitle='Add attachments';
    this.popUpText = '';
   // this.typeNum = undefined;
  }
}

  exportReport() {
    if (this.exportReportCalled) {
      return;
    }
    this.exportReportCalled = true;

    this.claimService.runReport(this.claim?.id).subscribe((value: any) => {

      if (value?.reportId) {
        this.claimService.downloadReport(value?.reportId).subscribe((result: any) => {

          const blob = new Blob([result], {
            type:
              result.type,
          });

          let link = window.document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = value?.reportName;
          link.click();
          this.exportReportCalled = false;
        },
          (error: any) => {
            console.log(error);
            this.exportReportCalled = false;
            this.toastr.error(
              'Error occured while downloading report, please try again. Error Code:' + error.code,
              'Create claim',
              {
                positionClass: 'toast-top-center',
              }
            );

          })
      } else {
        this.exportReportCalled = false;
        this.toastr.error(
          'Error occured while generating report, please try again. ' + value?.error,
          'Create claim',
          {
            positionClass: 'toast-top-center',
          }
        );
      }
    },
      (error) => {
        this.exportReportCalled = false;
        console.log(error);
        this.toastr.error(
          'Error occured while generating report, please try again.',
          'Create claim',
          {
            positionClass: 'toast-top-center',
          }
        );
      })
  }

  
 adjustDate(value: any): string {
  if (!value) {
    return "";
  }

  console.log( moment(value).tz('America/Chicago').format('MM/DD/YYYY h:mm A'));

  return moment(value).tz('America/Chicago').format('MM/DD/YYYY h:mm A') + " CT";
}
}

