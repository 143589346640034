import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Claim } from '../_model/claim';
import { ClaimService } from '../_services/claim.service';
import { ContractService } from '../_services/contract.service';

@Component({
  selector: 'app-draft-claim-list',
  templateUrl: './draft-claim-list.component.html',
  styleUrls: ['./draft-claim-list.component.css'],
})
export class DraftClaimListComponent implements OnInit {
  showDetails1: boolean = false;
  showDetails2: boolean = false;
  showDetails3: boolean = false;
  customerInformation1: string = 'customerInformation1';
  vehicleInformation1: string = 'vehicleInformation1';
  contractInformation1: string = 'contractInformation1';
  customerInformation2: string = 'customerInformation2';
  vehicleInformation2: string = 'vehicleInformation2';
  contractInformation2: string = 'contractInformation2';
  draftClaim: boolean = true;
  indexNum = 0;
  claimList: any = [];
  claimListShow: boolean[] = [];
  pageLoading:boolean = true;

  filterForm:FormGroup;
  allPressed:boolean=true;

  filterFormSubmitted:boolean=false;
  filterLoading:boolean = false;

  page:number = 1;
  pageSize:number = 20;
  totalSize:number = 0;
  currentSortField: string = '';
  sortTableIndicator: number = 0;
  sortQuery: string ="";
  tmpPage = this.page - 1;
  tmpPageParam = "&page=" + this.tmpPage;
  parametersDraft: string = "?v=DRAFT&accountNumber=" + this.claimService.accountNumber;
  header:string="Delete Claim";
  message:string = "";
  buttonMessage:string="Delete"
  deleteClaimId: string = "";
  sortFieldAndDirection: string = "default";
  constructor(
    private claimService: ClaimService,
    private contractService: ContractService,
    private router: Router,
    private toastr: ToastrService,
    private readonly fb: FormBuilder) {
      this.filterForm = this.fb.group({
        claimNumber: [],
        roNumber: [],
        vehicleInfo:[],
        createdDateFrom:[],
        createdDateTo:[],
        customerName: [],
        userName:[],
        amount:[,  Validators.pattern("^[0-9]*$")      ]
      });
    }

  ngOnInit(): void {
    this.parametersDraft = "?v=DRAFT&accountNumber=" + this.claimService.accountNumber;
    this.toastr.clear();
    this.onPageChange(this.page);//this.getDrafts();
    this.getCount();

  }
  getCount(){
    this.claimService.getClaimsCount("?v=DRAFT&accountNumber=" + this.claimService.accountNumber).subscribe((value:any)=>{
      this.totalSize = value;
      }, error=>{
            console.log(error);
            this.toastr.error('Something went wrong while fetching number of draft claims, please try again. ' + 'Error code: '+error.status, 'Draft Claims', {
              positionClass: 'toast-top-center', timeOut:12000
            });
            
      }
     );
  }
  previewDetails1(contractNumber, i) {
    this.showDetails1 = !this.showDetails1;
    this.claimListShow[i] = !this.claimListShow[i];
    if (this.claimListShow[i]) {
      this.claimService.claim.next(this.claimList[i]);
      if (this.claimList[i].jobs)
        this.claimService.job.next(this.claimList[i]?.jobs[0]);
      this.claimService.currentStep = this.claimList[i]?.step;
      this.contractService.contractIndex.next(i);
      this.contractService.contractId.next(contractNumber);
      this.setContract(this.claimList[i], false, i, false);
    }
  }
  setContract(claim:Claim, goToNewClaim, i, editClaim) {
    let contractInfo = {
      lastName: claim.lastName,
      vin: claim.vin,
      contractNumber: claim.contractNumber
    };
    
    if (goToNewClaim) {
      if(claim.statusCode != 70){
      this.claimService.editClaim=editClaim;
      if(claim.step == 0)
        this.claimService.editClaim = true;
      this.claimService.previewActiveClaim = false;
      this.claimService.startClaimFromSearch = false;
      this.claimService.claim.next(this.claimList[i]);
      if (this.claimList[i].jobs)
        this.claimService.job.next(this.claimList[i]?.jobs[0]);
      this.claimService.currentStep = this.claimList[i]?.step;
      this.contractService.contractIndex.next(i);
      this.claimService.currentUrl.next("NONE");
      this.router.navigate(['/new-claim']);
      }
      else {
        this.claimService.editClaim = true;
        this.claimService.previewActiveClaim = true;
        this.claimService.startClaimFromSearch = false;
        this.claimService.claim.next(claim);
        if (claim.jobs)
          this.claimService.job.next(claim.jobs[0]);
        this.claimService.currentStep = 3;
        this.claimService.currentUrl.next("NONE");
        this.router.navigate(['/new-claim']);
      }
    }
  }

  // deleteClaim(claim:Claim){
  //   this.claimService.deleteClaim(claim.id).subscribe((result:any)=>{
  //     this.onPageChange(this.page);
  //     this.getCount();
  //     this.toastr.success('Successfully deleted claim', '', {
  //       positionClass: 'toast-top-center',
  //     });
  //   }, (error) => {
  //     console.log(error);
  //     this.toastr.error(
  //       'Something went wrong while deleting claim, please try again. ' + 'Error code: '+error.status,
  //       'Create claim',
  //       {
  //         positionClass: 'toast-top-center',
  //       }
  //     );
  //   })
  // }
  editClaim(claim){

  }
  previewDetails2() {
    this.showDetails2 = !this.showDetails2;
  }
  previewDetails3() {
    this.showDetails3 = !this.showDetails3;
  }
  getDrafts(){
    this.claimService.getAllDraft("&accountNumber=" + this.claimService.accountNumber,"&size=500").subscribe(
      (res) => {
        this.claimList = res;
        if (this.claimList){
          for(let i=0; i<this.claimList; i++)
            this.claimListShow[i]=false;
       this.pageLoading=false;
      }
      },
      (error) => {
        console.log(error);
        this.toastr.error('Something went wrong while fetching draft claims, please try again. ' + 'Error code: '+error.status, 'Draft Claims', {
          positionClass: 'toast-top-center', timeOut:12000
        });
        this.pageLoading=false;
      }
    );
  }
  submitFilterForm(){
    this.filterFormSubmitted=true;
    this.allPressed=false;
   

    if(!this.filterForm.valid){
      return;
    }
    let parameters:string="?";
    
    if(this.filterForm.controls.claimNumber.value){
      parameters+="claimNumber_like="+this.filterForm.controls.claimNumber.value;
    }
    if(this.filterForm.controls.roNumber.value){
      if(parameters.length>1)
        parameters+="&";
    parameters+="repairOrderNumber_like="+this.filterForm.controls.roNumber.value;
    }
    if(this.filterForm.controls.vehicleInfo.value){
      if(parameters.length>1)
        parameters+="&";
    parameters+="vehicleInfo_like="+ this.filterForm.controls.vehicleInfo.value;
    }
    if(this.filterForm.controls.customerName.value){
      if(parameters.length>1)
        parameters+="&";
    parameters+="customerName_like="+this.filterForm.controls.customerName.value;
    }
    if(this.filterForm.controls.amount.value){
      if(parameters.length>1)
        parameters+="&";
    parameters+="amount_eq="+ +this.filterForm.controls.amount.value;
    }
    if(this.filterForm.controls.userName.value){
      if(parameters.length>1)
        parameters+="&";
    parameters+="userFullName_like="+this.filterForm.controls.userName.value;
    }
    if(this.filterForm.controls.createdDateFrom.value){
      if(parameters.length>1)
        parameters+="&";
    parameters+="sysDateI_from=" + new Date(this.filterForm.controls.createdDateFrom.value).toISOString();
    }
    if(this.filterForm.controls.createdDateTo.value){
      if(parameters.length>1)
        parameters+="&";

      let date:Date = new Date(this.filterForm.controls.createdDateTo.value);
      date.setDate(date.getDate() + 1);
      
      parameters+="sysDateI_to=" +date.toISOString();
    }
      this.filterLoading=true;
      if(parameters.length>1)
        parameters+="&";
      
      this.page=1;
      let tmpPage = this.page - 1;
      let tmpPageParam = "&page=" + this.tmpPage;

      parameters+="v=DRAFT&accountNumber=" + this.claimService.accountNumber;

      let tmpSize = "&size=" + this.pageSize;
    this.parametersDraft = parameters;
    this.filterClaims(parameters + tmpSize + tmpPageParam , true);
  }

  filterClaims(parameters:String, modalForm: boolean){
    if(this.sortQuery?.length >= 0)
      parameters += this.sortQuery;
    
    this.claimService.getFilteredClaims(parameters).subscribe((result:any)=>{
      
      this.claimList=result;
      this.countWithFilter();
    
      this.filterLoading=false;
      if(this.claimList){
        this.claimListShow=[];
        for(let i=0; i<this.claimList; i++)
          this.claimListShow[i]=false; 
        }
        if(modalForm)
          document.getElementById('filterModal').click();
    }, (error)=>{
      console.log(error);
      this.toastr.error('Something went wrong while filtering claims, please try again. ' + 'Error code: '+ error.status, 'Draft Claims', {
        positionClass: 'toast-top-center', timeOut:6000
      });
    });
  }
  filterClaimsAll(sort: boolean){
    this.allPressed=true;
    if(!sort)
      this.page = 1;
    this.parametersDraft = "?v=DRAFT&accountNumber=" + this.claimService.accountNumber;

    this.onPageChange(this.page);

    this.filterLoading=false;
    this.filterForm.reset();
  }
  onPageChange(page){
    this.pageLoading = true;
    let tmpPage = page-1;
    let tmpPageParam = "&page=" + tmpPage;
    let tmpSize = "&size=" + this.pageSize;
    let parameters = this.parametersDraft + tmpSize + tmpPageParam;
    if(this.sortQuery?.length >= 0)
      parameters += this.sortQuery;

    this.claimList=[];
    this.claimService.getFilteredClaims(parameters).subscribe((result:any)=>{
      this.claimList=result;
      this.claimListShow=[];
      if(this.claimList){
        for(let i=0; i<this.claimList.length; i++)
          this.claimListShow[i]=false;
        this.pageLoading=false;
        }
    });
    this.countWithFilter();
  
   }

   countWithFilter(){
    this.claimService.getClaimsCount(this.parametersDraft).subscribe((value:any)=>{
      this.totalSize = value;
      }, error=>{
            console.log(error);
            this.toastr.error('Something went wrong while fetching number of draft claims, please try again. ' + 'Error code: '+error.status, 'Draft Claims', {
              positionClass: 'toast-top-center', timeOut:12000
            });
            
      }
     );
   }

   sort(field:string){
    if(field !== this.currentSortField){
      this.sortTableIndicator = 1;
      this.currentSortField = field;
    } else {
      this.sortTableIndicator = ++this.sortTableIndicator % 3;
    }

    switch(this.sortTableIndicator){
      //DEFAULT SORT
      case 0:{
        this.sortQuery="";
        this.sortFieldAndDirection = "default";

        break;
      }
      //ASCENDING
      case 1:{
        this.sortQuery = "&sort=" + field + ",asc";
        this.sortFieldAndDirection = field + "ASC";

        break;
      }
      //DESCENDING
      case 2:{
        this.sortQuery = "&sort=" + field + ",desc";
        this.sortFieldAndDirection = field + "DESC";

        break;
      }
    }

    if (this.allPressed) this.filterClaimsAll(true);
    else  {
      let tmpPage = this.page-1;
      let tmpPageParam = "&page=" + tmpPage;
      let tmpSize = "&size=" + this.pageSize;
      this.filterClaims(this.parametersDraft + tmpPageParam + tmpSize, false);
    }
  }
  deleteClaim($event){
    if($event){
      this.claimService.deleteClaim(this.deleteClaimId).subscribe((result:any)=>{
        this.onPageChange(this.page);
        this.getCount();
        document.getElementById("closeConfirmActionButton0").click();
        this.toastr.success('Successfully deleted claim', '', {
          positionClass: 'toast-top-center',
        });
      }, (error) => {
        console.log(error);
        this.toastr.error(
          'Something went wrong while deleting claim, please try again. ' + 'Error code: '+error.status,
          'Create claim',
          {
            positionClass: 'toast-top-center',
          }
        );
      })
    }
  }
  setClaimDelete(claimId: string){
    this.deleteClaimId = claimId;
    this.message="Are you sure you want to delete this draft claim?";
  }
}
