import { Component, OnInit } from '@angular/core';
import { fakeAsync } from '@angular/core/testing';
import { BehaviorSubject, Subject } from 'rxjs';
import { ReleaseNotes } from 'src/app/_model/releaseNotes';

@Component({
  selector: 'app-release-notes-main',
  templateUrl: './release-notes-main.component.html',
  styleUrls: ['./release-notes-main.component.css']
})
export class ReleaseNotesMainComponent implements OnInit {

  constructor() { }
  june2022: ReleaseNotes[];
  october2022: ReleaseNotes[];
  april2023: ReleaseNotes[];
  june2023: ReleaseNotes[];
  december2023: ReleaseNotes[];
  
  changingValue: Subject<boolean> = new Subject();
  currentMonth: BehaviorSubject<ReleaseNotes[]> = new BehaviorSubject([]);

  monthList: any;
  selectedRelaseNote: any;
  indexNum: number = 0;
  hasNextMonth: boolean = true;
  hasPreviousMonth: boolean = false;
  ngOnInit(): void {
    this.june2022 = [
      {
        details: "If your part is not found, the description box now lists the limit of character remaining for the customer description.",
        detailsTitle: "Part Description character count",
        imgPath: "./assets/slim/img/partDescField.png",
        pageNum: "1/5"
      },
      {
        details: "Claim statuses now automatically refresh when they are changed.",
        detailsTitle: "Claims Auto Refresh",
        imgPath: "./assets/slim/img/claimsAutoRefresh.png",
        pageNum: "2/5"
      },
      {
        details: "If another user is actively working on a claim, a message will appear if you try to edit items on the same claim.",
        detailsTitle: "Draft Claims Lockout",
        imgPath: "./assets/slim/img/draftClaimsLockout.png",
        pageNum: "3/5"
      },
      {
        details: "Attaching notes and documents is now faster!",
        detailsTitle: "Speed Improvements",
        imgPath: "./assets/slim/img/speedImprovements.png",
        pageNum: "4/5"
      },
      {
        details: "Create Another Checkbox for Rental works properly.",
        detailsTitle: "Bug Fixes",
        imgPath: "./assets/slim/img/bugFixes.png",
        pageNum: "5/5"
      }
    ];

    this.october2022 = [
      {
        details: "When the claim is authorized, the amount becomes a hyperlink and by clicking it you can now start the process of requesting a payment through ZOC!",
        detailsTitle: "Payment request",
        imgPath: "./assets/slim/img/paymentRequestDollar.png",
        pageNum: "1/5"
      },
      {
        details: "New section Support is available where you can check the release notes, report a bug, give us feedback, or check the about section!",
        detailsTitle: "Help menu",
        imgPath: "./assets/slim/img/helpMenu.png",
        pageNum: "2/5"
      },
      {
        details: "Instructions have been added to the job summary screen on how to update claims that were previously submitted. Updating claims is as simple as sending us a note on the details page. If you try to adjust specific items on the job summary page, it will provide an easy way to enter a note and remind you of this process.",
        detailsTitle: "Updating a submitted claim",
        imgPath: "./assets/slim/img/updatingSubmittedClaimNotice.png",
        pageNum: "3/5"
      },
      {
        details: "We changed the label from Loss Date to Repair Order Date to clarify that this date should match the RO number. This should diminish the payment request delays!",
        detailsTitle: "Changing the label to 'Repair Order Date'",
        imgPath: "./assets/slim/img/repairOrderDate.png",
        pageNum: "4/5"
      },

      {
        details: "ZOC will check the RO number to avoid submission errors that happened previously and will now advise Service Advisors on how to proceed forward!",
        detailsTitle: "RO number Review",
        imgPath: "./assets/slim/img/roNumberReview.png",
        pageNum: "5/5"
      }
    ];

    this.april2023 = [
      {
        details: "We updated the Claim information page by automatically prefilling dealer information including the Labor rate, which is now pre-populated and calculated, based on the date selected but can be adjusted in the job/part section!",
        detailsTitle: "Claim information page update",
        imgPath: "./assets/slim/img/ClaimInformationPageScreenshot.png",
        pageNum: "1/4",
        imgWidthFull: true,
        detailsSecondRow: "Tip: Labor rate can still be adjusted on the job page to make entry of menu priced items easy."
      },
      {
        details: "Documents attached to the payment request will now show up in their own section on the Claims Details page. Claims and Payments are handled by different people at Zurich, so we wanted to ensure users knew that it was important to attach your document using the proper button.",
        detailsTitle: "Payment documents",
        imgPath: "./assets/slim/img/paymentsDocuments.png",
        pageNum: "2/4",
        imgWidthFull: true
      },
      {
        details: "Parts, Labor, and Sublet boxes will now stay open and allow you to add items until the box is closed. This replaces the extra clicks when using the \"create another\" feature. This change was based on user feedback and will help users enter multiple items faster!",
        detailsTitle: "Adding Items to Jobs",
        imgPath: "./assets/slim/img/addingItemsToJobs.png",
        pageNum: "3/4"
      },
      {
        details: "We are continuously resolving technical matters to guarantee a seamless submission process for the users! We resolved an item that was causing sending issues with our claims and resulting in phone calls. We also fixed an area where auto save was not working as intended.",
        detailsTitle: "Bug Fixes",
        imgPath: "./assets/slim/img/bugFixes.png",
        pageNum: "4/4"
      }
    ];

    this.june2023 = [
      {
        details: "Minor optimizations to improve the user experience.",
        detailsTitle: "Bug Fixes",
        imgPath: "./assets/slim/img/bugFixes.png",
        pageNum: "1/1"
      }
    ];

    this.december2023 = [
      {
        details: "ZOC will identify claims that qualify for self authorization and present the option to self authorize those claims upon submission.",
        detailsTitle: "Self authorization process (Eligible dealers/claims only)",
        detailsTitleSecondRow: "*Requirements corrected 12/15",
        imgPath: "./assets/slim/img/selfAuthorized.png",
        imgWidthFull: true,
        pageNum: "1/1",
        detailsSecondRow:"Self authorization will result in immediate authorization of the claim.  Once the repair is complete, please request payment the same as you do on other ZOC claims. This process replaces the previous mail/fax self authorization process."
      }
    ];

    this.monthList =
      [
        {
          month: "December 2023",
          values: this.december2023
        },
        {
          month: "June 2023",
          values: this.june2023
        },
        {
          month: "April 2023",
          values: this.april2023
        },
        {
          month: "October 2022",
          values: this.october2022
        },
        {
          month: "June 2022",
          values: this.june2022
        },

      ];
    this.currentMonth.next(this.monthList[0].values);
    
    this.selectMonth(0);

  };


  updateCounter() {
    this.changingValue.next(true);
    this.currentMonth.next(this.monthList[0].values);
    this.selectedRelaseNote = this.monthList[0];
  }

  selectMonth(indexNum: number) {
    console.log(indexNum);
    let tmpIndexNum = this.indexNum + indexNum;

    this.selectedRelaseNote = this.monthList[tmpIndexNum];
    this.currentMonth.next(this.monthList[tmpIndexNum].values);
  }
  increaseCounter() {
    if(this.indexNum >= this.monthList?.length){
      this.hasNextMonth = false;
      return;
    }
    

    if(this.indexNum+3 < this.monthList?.length){
      this.indexNum += 3;
      if((this.monthList?.length - this.indexNum) < 3){
        this.hasNextMonth = false;
      } else {
        this.hasNextMonth = true;
      }
      this.hasPreviousMonth = true;
      return;
    } else if(this.indexNum+2 < this.monthList?.length){
      if(this.monthList?.length %3 == 2){
        this.indexNum += 2;
        this.hasNextMonth = false;
        this.hasPreviousMonth = true;
        return;
      }
    } else if(this.indexNum+1 < this.monthList?.length){
      if(this.monthList?.length %3 == 1){
        this.indexNum += 1;
        this.hasNextMonth = false;
        this.hasPreviousMonth = true;
        return;
      }
    }
    this.hasNextMonth = false;
    if(this.indexNum > 3) {
      this.hasPreviousMonth = true;
    } else {
      this.hasPreviousMonth = false;
    }
    
  }
  decreaseCounter() {
    if((this.monthList?.length - this.indexNum) > 0 && this.monthList?.length > 3){
      this.hasNextMonth = true;
    }
    if(this.indexNum >= 3)
      this.indexNum -= 3;

    if(this.indexNum < 3){
      this.indexNum = 0;
      this.hasPreviousMonth = false;
    }
  }
}
