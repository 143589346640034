import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { NEVER, Observable, throwError } from 'rxjs';

import { OktaAuthService } from '@okta/okta-angular';
import { environment } from 'src/environments/environment';
import { ClaimService } from '../_services/claim.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {
    constructor(private authenticationService: OktaAuthService, private claimService: ClaimService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with basic auth credentials if available
        const token = this.authenticationService.getAccessToken();
    
        if (environment.isOktaEnabled && (request.url.startsWith(environment.apiUrl) || (environment.environmentName == 'znadev' && request.url.startsWith(environment.subletUrl)))) {
            if(request.url.includes('documents/content') || request.url.includes('/stp/report/'))
                request = request.clone({             
                        responseType:"blob"    
                });
                
            request = request.clone({
                setHeaders: { 
                    Authorization: `Bearer ${token}`,
                    'Ocp-Apim-Subscription-Key' : `${environment.APISubscriptionKey}`,
                    'User' : this.claimService.userInfoData
                }
            });
        }else if(!environment.isOktaEnabled && request.url.startsWith(environment.apiUrl)){
            if(request.url.includes('documents/content') || request.url.includes('/stp/report/'))
                request = request.clone({             
                        responseType:"blob"    
            });
            request = request.clone({
                setHeaders: { 
                    Authorization: 'Basic ZGNpdEFwaTpkY2l0QXBpMTIzIw==',
                    'User' : this.claimService.userInfoData
                }  
            });
        }

        return next.handle(request)
        .pipe(catchError(error => {
            if (!!error.status && error.status === 401) {
                window.location.href = '';
                return NEVER;
            }
            return throwError(error);;
          }));
        }
}