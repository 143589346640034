import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-search-contract',
  templateUrl: './search-contract.component.html',
  styleUrls: ['./search-contract.component.css']
})
export class SearchContractComponent implements OnInit {

  @Output() messageEvent = new EventEmitter<boolean>();
  checkBoxAgree: FormControl;
  isInValidFormSubmitted:boolean=false;
  searchContractForm: FormGroup;
  constructor(    private readonly fb: FormBuilder    ) {
    this.searchContractForm = this.fb.group({
      vinNumber: new FormControl('', Validators.compose([
        Validators.maxLength(17),
		    Validators.minLength(17),
		    Validators.required
      ])),
    })
   }

  ngOnInit(): void {
  }
  goToNextPage(){
    this.messageEvent.emit(true);
  }
  onClickSearch(){
    
    if (!this.searchContractForm.valid){
      this.isInValidFormSubmitted=true;
      return;
    }
    this.isInValidFormSubmitted=false;
    this.goToNextPage();
}
}
