<div id="releaseNotesModal" class="modal">
  <div  class="modal-dialog modal-lg modal-width-release-notes" role="document">
      <div class="modal-content tx-size-sm">
        <div class="modal-header pd-x-20 pd-b-30">
          <h6 class="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
            Release Notes
          </h6>
          <button
          type="button"
          class="close"
          aria-label="Close"
          id="closeReleaseNotesModalButton"
          data-dismiss="modal"
          (click)="updateCounter()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div class="row">
          <div class="col-md">
            <div class="card bd-0 " style="background: #f8f9fa;">
              <div class="card-body bd-0">
                <div class="row justify-content-center mg-b-10">
                  <div class="row justify-content-around">
                    <div style="align-self: center; background: #f8f9fa;"><i 
                      class="fa fa-angle-left fa-3x mg-r-5" (click)="decreaseCounter()" [ngClass]="{
                          'blue-arrow': hasPreviousMonth
                        }"></i></div>
                        <div class=" justify-content-around" style="min-width:330px;">
                      <button *ngIf="monthList.length > indexNum" class="btn " (click)="selectMonth(0)" [ngClass]="{
                          'blue-button': monthList[indexNum]?.month == selectedRelaseNote?.month,
                          'tx-primary white-button': monthList[indexNum]?.month != selectedRelaseNote?.month
                        }">
                        {{monthList[indexNum]?.month}}
                        </button>
                        <button  *ngIf="monthList.length > indexNum + 1" class="btn" (click)="selectMonth(1)"[ngClass]="{
                          'blue-button': monthList[indexNum + 1]?.month == selectedRelaseNote?.month,
                          'tx-primary white-button': monthList[indexNum + 1]?.month != selectedRelaseNote?.month
                        }">
                         {{ monthList[indexNum + 1]?.month}}
                        </button> 
                        <button *ngIf="monthList.length > indexNum + 2" class="btn" (click)="selectMonth(2)"[ngClass]="{
                          'blue-button': monthList[indexNum + 2]?.month == selectedRelaseNote?.month,
                          'tx-primary white-button': monthList[indexNum + 2]?.month != selectedRelaseNote?.month
                        }">
                         {{ monthList[indexNum + 2]?.month}}
                        </button>
                      </div> 
                        <div style="align-self: center; background: #f8f9fa;"><i 
                          class="fa fa-angle-right fa-3x mg-r-5" (click)="increaseCounter()" [ngClass]="{
                              'blue-arrow': hasNextMonth
                            }"></i></div>
                  </div>
                </div>
                <div class="row mg-b-20 justify-content-around" style="background: #f8f9fa;">
                    
                      <app-new-item-list
                          [monthValue]="currentMonth"
                          [resetCounter]="changingValue"
                          class="card col-12 bd-0" style="background: #f8f9fa;"
                      ></app-new-item-list>
                      
                   
                </div>


              <div class="row justify-content-end">
                <div>
                  <button  (click)="updateCounter()" data-dismiss="modal" class="btn btn-oblong btn-primary btn">
                    Thanks for the update
                  </button>                  

                </div>
              </div>
              </div>
              <!-- card-body -->
            </div>
            <!-- card -->
          </div>
        </div>
        <!-- row -->

      </div>
  </div>
  <!-- modal-dialog -->
</div>