<div class="slim-header pd-lg-x-120">
  <div class="container-fluid pd-l-0">
    <div class="slim-header-left">
      <!-- <div class="dropdown dropdown-c">
        <a href="#" class="logged-user tx-info" data-toggle="dropdown">
          <i class="fa fa-bars fa-2x"></i>
          <span>Menu</span>
        </a>
        <div class="dropdown-menu dropdown-menu-right">
          <nav class="nav">
            <a
              (click)="setClaimsActive()"
              routerLink="/claims"
              class="nav-link"
            >
              All Claims</a
            >
            <a
              (click)="setDraftsActive()"
              routerLink="/drafts"
              class="nav-link"
            >
              Draft Claims</a
            >
          </nav>
        </div>
      </div> -->
      <!-- dropdown -->

      <div class="dropdown dropdown-c mg-l-0" (click)="getUserInfo()">
        <a href="#" class="logged-user tx-info mg-l-0" data-toggle="dropdown">
          <!-- add  data-toggle="dropdown" in a tag above, removed because causing error -->
          <span class="icon icon--user-male_48_outline mg-r-0 mg-l-0"></span>
          <span class="font-weight-bold" style="margin-left: 0px !important;">{{ userInfo?.user_name }}</span>
          <!--<div class="form-row">Service Advisor</div> -->
          <i class="fa fa-angle-down"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-right">
          <nav class="nav">
            <a
              class="nav-link"
              data-toggle="modal"
              data-target="#myAccountInfoModal"
              ><i class="icon ion-person"></i> View Profile</a
            >
          </nav>
        </div>
        <!-- dropdown-menu -->
      </div>
      <!-- dropdown -->
      <div id="myAccountInfoModal" class="modal">
        <div class="modal-dialog modal-lg modal-width" >
          <div class="modal-content tx-size-sm">
            <div class="modal-header pd-x-20" style="background: white;">
              <h6 class="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                Account Information
              </h6>
              <button
                type="button"
                class="close"
                aria-label="Close"
                id="closeMyAccointModalButton"
                data-dismiss="modal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="col-12 tx-primary pd-0">
              <div class="card pd-20" style="background: #f8f9fa;">
                <div class="card-body">
                  <div class="row">
                  <div class=" col-6">
                    <div class=" col-12">
                      <label class="form-control-label pd-x-0 col-12"> 
                        <label class="tx-bold">User Name:</label>
                        {{ userInfo?.user_name }}
                        <!-- {{ userInfo?.user_roles  }} -->
                      </label>
                      
                    </div>
                    <div class="col-12">
                      <label class="form-control-label pd-x-0 col-12"
                        >
                        <label class="tx-bold">Role:</label>
                        {{ userInfo?.user_roles }}
                        </label
                      >
                    
                    </div>
                    <div class="col-12">
                      <label class="form-control-label pd-x-0 col-12"> 
                        <label class="tx-bold">Email:</label>
                        {{ userInfo?.user_email }}
                      </label>
                    
                    </div>
                    <div class="col-12">
                      <label class="form-control-label pd-x-0 col-12"> 
                        <label class="tx-bold">Phone:</label>
                        {{ userInfo?.user_phone }}
                        
                      </label>
                    </div>
                </div>
                <div class=" col-6">
                  <div class="col-12">
                    <label class="form-control-label pd-x-0 col-12">
                      <label class="tx-bold">Account Number:</label>
                      {{ userInfo?.account_number }}
                      </label
                    >
                  </div>
                  <div class="col-12">
                    <label class="form-control-label pd-x-0 col-12"
                    ><label class="tx-bold">Account Name:</label>
                    {{ userInfo?.account_name }}
                  </label>
                  </div>
                  <div class="col-12">
                    <label class="form-control-label pd-x-0 col-12">
                      <label class="tx-bold">Address:</label>
                      {{ userInfo?.addr_ln1 }},  <label *ngIf="userInfo?.addr_ln2">({{ userInfo?.addr_ln2 }}),</label>{{ userInfo?.city }}, {{ userInfo?.state_abbr }}, {{ userInfo?.zip_code }}
                      </label
                    >
                  </div>
                </div>
              </div>
              <div class="row justify-content-end"><button
                type="button"
                class="btn btn-oblong btn-secondary"
                data-dismiss="modal"
                id="closeMyAccointModalButton"
              >
                Close
              </button></div>
                </div>
                <!-- <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-oblong btn-secondary"
                    data-dismiss="modal"
                    id="closeMyAccointModalButton"
                  >
                    Close
                  </button>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="ht-md-60 pd-l-20 d-flex align-items-center justify-content-center"
      >
        <ul
          class="nav nav-underline align-items-center flex-sm-row"
          role="tablist"
        >
          <li class="nav-item" style="padding:10px !important">
            <a
              routerLink=""
              (click)="setDashboardActive(true)"
              class="nav-link Show tx-info"
              [ngClass]="{ active: dashboardPageActive }"
              style="color: #5b93d3 !important"
              data-toggle="tab"
              href="#"
              role="tab"
              aria-selected="false"
              ><span class="icon icon--home_48_outline mg-r-4"></span>Home</a
            >
          </li>
          <li (click)="setClaimsActive()" class="nav-item" style="padding:10px !important">
            <a
              class="nav-link Show tx-info "
              [ngClass]="{ active: claimsPageActive }"
              style="color: #5b93d3 !important"
              routerLink="/claims"
              href="/claims"
              role="tab"
              ><span class="icon icon--file-blank_48_outline mg-r-4"></span
              >Active Claims</a
            >
          </li>
          <li (click)="setDraftsActive()" class="nav-item" style="padding:10px !important">
            <a
              class="nav-link Show tx-info"
              [ngClass]="{ active: draftsPageActive }"
              style="color: #5b93d3 !important"
              routerLink="/drafts"
              href="/drafts"
              role="tab"
              ><span class="icon icon--file-text_48_outline mg-r-0"></span>Draft
              Claims
            </a>
          </li>
          <li>
            <div class="dropdown dropdown-c mg-l-0 pd-10" (click)="getUserInfo()">
              <a href="#" class="logged-user tx-info mg-l-0" data-toggle="dropdown">
                <!-- add  data-toggle="dropdown" in a tag above, removed because causing error -->
                <i class="fa fa-question-circle mg-r-5" aria-hidden="true" ></i>
                <span style="margin-left: 0px !important;">Support</span>
                <!--<div class="form-row">Service Advisor</div> -->
                <i class="fa fa-angle-down"></i>
              </a>
              <div class="dropdown-menu dropdown-menu-right">
                <nav class="nav">
                  <a
                    id="releaseNotesLink"
                    class="nav-link col-12 text-center"
                    data-toggle="modal"
                    data-target="#releaseNotesModal"
                    data-backdrop='static' 
                    data-keyboard='false'
                    >Release Notes</a
                  >
                  <a
                    class="nav-link col-12 text-center"
                    data-toggle="modal"
                    data-target="#reportABug"
                    (click)="resetReportABugForm()"
                    >Report a bug</a
                  >
                  <a
                  class="nav-link col-12 text-center"
                  id="feedbackButtonPopUp"
                  data-toggle="modal"
                  data-target="#feedback"
                  (click)="resetFeedbackForm()"
                  >Feedback</a
                >
                <a
                class="nav-link col-12 text-center"
                data-toggle="modal"
                data-target="#aboutModal"
                >About</a
              >
                </nav>
              </div>
              
              <!-- dropdown-menu -->
            </div>
          </li>
        </ul>
      </div>
  
      <!-- <div class="search-box">
        <button class="btn bg-info">
          <i class="fa tx-white fa-search"></i>
        </button>
        <input type="text" class="form-control" placeholder="Search" />
      </div> -->
      <!-- search-box -->
    </div>
    <!-- header-left -->

    <div class="slim-header-right">
      <div class="media">
        <a (click)="setDashboardActive(true)" routerLink="">
          <img
            src="./assets/slim/img/zurich-logo.jpg"
            class="wd-100 h-70"
            alt=""
        /></a>
      </div>
    </div>
    <!-- slim-header-rigth -->
  </div>
  <!-- container -->
</div>
<!-- slim-header -->

<div id="reportABug" class="modal">
  <div  class="modal-dialog modal-lg modal-width-bug" role="document">
    <form
      fxLayout="column"
      [formGroup]="reportABugForm"
      (ngSubmit)="submitReportABugForm()"
    >
      <div class="modal-content tx-size-sm">
        <div class="modal-header pd-x-20 pd-b-30">
          <h6 class="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
            Report a bug
          </h6>
          <button
          type="button"
          class="close"
          aria-label="Close"
          id="closeReportABugModalButton"
          data-dismiss="modal"
          (click) = "closeReportABug()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div class="row">
          <div class="col-md">
            <div class="card">
              <div class="card-body">
                <div class="row justify-content-around">
                  <div>
                    <h4 class="tx-primary">Briefly describe what you were doing and how ZOC failed</h4>
                  </div>
                </div>
                <div class="row mg-b-20 justify-content-around">
                    
                      <textarea                       
                        class="form-control col-10"
                        rows="2"
                        placeholder="Type here..."
                        [formControlName]="'reportABugText'"
                        [ngClass]="{
                          'is-invalid': reportABugSubmitted && fReportABug.reportABugText.errors
                        }"
                      ></textarea>
                      
                   
                </div>
                <!-- tab-content -->
                <div class="row justify-content-center pd-b-20">
                  <ngx-dropzone (change)="onSelectFiles($event)">

                    <ngx-dropzone-label>
                      <i placement="top" ngbTooltip="Add Attachment" class="fa fa-2x far tx-primary fa-upload mg-r-10"></i>
                      <br>
                      Drag and drop screenshots here <br>
                      if you have them.
                      </ngx-dropzone-label>
                  
                    <ngx-dropzone-preview *ngFor="let f of files; let i = index" [removable]="true" (removed)="onRemove(i)">
                  
                      <ngx-dropzone-label>{{ f.name }}</ngx-dropzone-label>
                  
                    </ngx-dropzone-preview>
                  
                  </ngx-dropzone>
                <!-- tab-content -->
              </div>
              <div class="row justify-content-end">
                <div class="col-3 col-sm-3 col-md-2  pd-l-0 pd-r-0">
                  <button type="submit" class="btn btn-oblong btn-primary btn">
                    Submit
                  </button>                  
                  <div *ngIf="reportingABug" class="spinner-border " style="width: 2rem; height: 2rem; vertical-align: middle;" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
              </div>
              <!-- card-body -->
            </div>
            <!-- card -->
          </div>
        </div>
        <!-- row -->

      </div>
    </form>
  </div>
  <!-- modal-dialog -->
</div>

<div id="feedback" class="modal">
  <div  class="modal-dialog modal-lg modal-width-bug" role="document">
    <form
      fxLayout="column"
      [formGroup]="feedbackForm"
      (ngSubmit)="submitFeedbackForm()"
    >
      <div class="modal-content tx-size-sm">
        <div class="modal-header pd-x-20 pd-b-30">
          <h6 class="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
            Submit Feedback
          </h6>
          <button
          type="button"
          class="close"
          aria-label="Close"
          id="closeFeedbackModalButton"
          data-dismiss="modal"
          (click)="closeFeedback()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div class="row">
          <div class="col-md">
            <div class="card">
              <div class="card-body">
                <div class="row justify-content-around">
                  <div>
                    <h4 class="tx-primary">Let us know how we can improve Zurich Online Claims!</h4>
                  </div>
                </div>
                <div class="row mg-b-20 justify-content-around">
                    
                      <textarea                       
                        class="form-control col-10"
                        rows="2"
                        placeholder="Type here..."
                        [formControlName]="'feedbackText'"
                        [ngClass]="{
                          'is-invalid': feedbackSubmitted && fFeedback.feedbackText.errors
                        }"
                      ></textarea>
                      
                   
                </div>
                <div class="row justify-content-center">
                  <div class="col-11">
                    <p >This form is only to provide feedback on the user experience of ZOC. 
                      Real time help is available via the Live Chat in My Zurich Navigator.
                    </p>
                  </div>
                </div>
                <!-- tab-content -->

              <div class="row justify-content-end">
                <div class="col-3 col-sm-3 col-md-2  pd-l-0 pd-r-0">
                  <button type="submit" class="btn btn-oblong btn-primary btn">
                    Submit
                  </button>                  
                  <div *ngIf="feedbackSent" class="spinner-border " style="width: 2rem; height: 2rem; vertical-align: middle;" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
              </div>
              <!-- card-body -->
            </div>
            <!-- card -->
          </div>
        </div>
        <!-- row -->

      </div>
    </form>
  </div>
  <!-- modal-dialog -->
</div>
<div id="aboutModal" class="modal">
  <div  class="modal-dialog modal-lg modal-width-bug" role="document">
   
      <div class="modal-content tx-size-sm">
        <div class="modal-header pd-x-20 pd-b-30">
          <h6 class="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
            About
          </h6>
          <button
          type="button"
          class="close"
          aria-label="Close"
          id="aboutModalButton"
          data-dismiss="modal"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div class="row">
          <div class="col-md">
            <div class="card">
              <div class="card-body">
                <div class="row justify-content-center">
                  <div class="col-10 text-center tx-bold tx-primary">
                    <h4>Zurich Online Claims v2.4.10 as of July 2024.
                    </h4>
                  </div>
                </div>
              </div>
              <!-- card-body -->
            </div>
            <!-- card -->
          </div>
        </div>
        <!-- row -->

      </div>
  </div>
  <!-- modal-dialog -->
</div>
<app-release-notes-main></app-release-notes-main>