import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-item',
  templateUrl: './new-item.component.html',
  styleUrls: ['./new-item.component.css']
})
export class NewItemComponent implements OnInit {
  @Input() details: string;
  @Input() detailsTitle: string;
  @Input() detailsTitleSecondRow: string;

  @Input() imgSourcePath: string;
  @Input() pageNum: string;
  @Input() imageWidthFull: boolean;
  @Input() detailsSecondRowText: string;

  constructor() { }

  ngOnInit(): void {
  }

}
