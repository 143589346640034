import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Claim } from 'src/app/_model/claim';
import { Note } from 'src/app/_model/note';
import { ClaimService } from 'src/app/_services/claim.service';
import { ContractService } from 'src/app/_services/contract.service';
declare var $: any;

@Component({
  selector: 'app-create-new-claim',
  templateUrl: './create-new-claim.component.html',
  styleUrls: ['./create-new-claim.component.css'],
})
export class CreateNewClaimComponent implements OnInit, OnDestroy {
  @ViewChild('noteModalVar') el:ElementRef;

  currentStep: number = 0;
  currentStepTitles: string[] = [
    'Claim Information',
    'Job Summary',
    'Claim Details',
  ];
  currentStepTitle: string = this.currentStepTitles[0];
  goToSearchResultBoolean: boolean = false;
  goToContractInformationBoolean: boolean = false;
  goToJobsSummaryBoolean: boolean = false;
  previousStep: number;
  noteForm: FormGroup;
  chevronPressed: boolean = false;
  claimList: Claim[] = [];
  claimSubscription: Subscription;
  claim: Claim;
  claimShow:boolean = false;
  customerInformation: string = 'customerInformation1';
  vehicleInformation: string = 'vehicleInformation1';
  contractInformation: string = 'contractInformation1';
  indexNum=0;
  hideButton:boolean=true;
  submittedNote:boolean = false;
  activeClaim:boolean = true;
  files:File[]=[];
  notesToBeSent:Note[]=[];
  clickedInsideNoteForm : boolean = false;
  indexNumNote=-2;
  header:string="Notes";
  message:string = "Note has not yet been submitted";
  buttonMessage:string="Continue "
  openedConfirmDialog: boolean = false;
  openConfirmDialog: boolean = true;
  hideCloseButton: boolean = true;
  clickFromCode: boolean = false;
  counter: number = 2;
  openNotes: boolean = false;
  sendingNote: boolean = false;
  closeButtonPressed: boolean = false;
  goToNextStep() {
    // if (this.currentStep == this.currentStepTitles.length - 1) {
    //   this.router.navigate(['/submit']);
    //   return;
    // }
    if (!this.claimService.editClaim)
      ++this.currentStep;
    this.currentStepTitle = this.currentStepTitles[this.currentStep];
    console.log("CURRENT STEP" + this.currentStep);
    this.goToPreviousStep(1);
  }
  constructor(private router: Router, private contractService: ContractService, public claimService: ClaimService, private readonly fb: FormBuilder,private toastr: ToastrService, private eRef: ElementRef) {
    this.noteForm = this.fb.group({
      noteText: [, Validators.required]
    });
    
  }
  
  ngOnInit(): void {
    this.claimSubscription = this.claimService.claim.subscribe((value) => {
      console.log("SUB TO CLAIM CREATE" + this.claimService.startClaimFromSearch);
      if(!this.claimService.startClaimFromSearch){ 
        this.claim = value;
        this.currentStep = this.claim.step;
        this.submitNotes();
        this.uploadFiles();
      } else{
        this.claim = {
          accountNumber: this.claimService.accountNumber,
          email:this.claimService.email,
          contractNumber: this.contractService.contract?.value?.contractNumber,
          step: 0,
          vin: this.contractService.contract?.value?.vehicle?.vIN,
          year: this.contractService.contract?.value?.vehicle?.year?.toString(),
          make: this.contractService.contract?.value?.vehicle?.make,
          model: this.contractService.contract?.value?.vehicle?.model,
          firstName : this.contractService.contract?.value?.customer?.firstName,
          lastName : this.contractService.contract?.value?.customer?.lastName,
          userId: this.claimService.userId
        };
      }
    });
    this.currentStep = this.claimService.currentStep;
    this.previousStep = this.currentStep;
    if (this.claimService.editClaim) {
      this.goToPreviousStep(0);
    }

    if (this.currentStep >= this.currentStepTitles.length) {
      this.currentStep = 2;
      this.previousStep = 2;
    }
    if(this.claimService.previewActiveClaim){
      this.currentStep = 3;
      this.previousStep = 2;
      this.currentStepTitle = this.currentStepTitles[0];
      this.chevronPressed = true;
    }
  }

  ngOnDestroy(): void {
    this.claimSubscription?.unsubscribe();
  }
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(this.openNotes){
      this.openNotes = false;
      return;
    }
    if(this.clickFromCode){
      this.clickFromCode = false;
      return;
    }
    --this.counter;
    
      setTimeout(()=>{
        if(this.counter <= 0 ){
          this.openNotes = false;
          
            if(!this.openedConfirmDialog){
              if(this.el?.nativeElement?.contains(event.target) && !this.closeButtonPressed)//contains(event.target)) 
              {
                console.log("clicked inside");

              } else {
                this.closeButtonPressed = false;
                if(this.noteForm.controls.noteText?.value && this.openConfirmDialog){
                  $('#confirmActionModal-2')?.modal({ backdrop: 'static', keyboard: false }, 'show');
                  
                  this.openedConfirmDialog = true;
                  this.openConfirmDialog = false;
                } else if(!this.noteForm.controls.noteText?.value){
                  this.openNotes = false;
                  this.clickFromCode = true;
                  this.counter = 1;
                  //document.getElementById('closeconfirmActionModalButton')?.click();
                  document.getElementById('closeNoteModalButton')?.click();
                } 
                console.log("clicked outside");
              }
            }
        }
      }, 500);
    
  }
  openNoteSection(){
    this.noteForm.controls.noteText.setValue(null);
    this.openNotes = true;
  }
  closeNotes(){
    this.openNotes = false;
  }
  closeNotesAlert(){
    this.openNotes = false;
    this.closeButtonPressed = true;
  }
  goToSearchResult($event) {
    this.goToSearchResultBoolean = $event;
  }
  goToContractInformation($event) {
    this.goToContractInformationBoolean = $event;
  }

  goToJobsSummary($event) {
    this.goToJobsSummaryBoolean = $event;
    this.currentStep = this.claimService.currentStep;
    if (!this.claimService.editClaim)
      ++this.currentStep;
    this.goToPreviousStep(2);
  }

  goToPreviousStep(previousStep: number) {
    console.log("PREVIOUS STEP" + previousStep + " CURRENT" +this.currentStep + " start" +this.claimService.startClaimFromSearch);
    if (!this.claimService.previewActiveClaim && (previousStep > this.currentStep)) return;

    this.claimService.editClaim = true;
    this.previousStep = previousStep;
    this.chevronPressed = true;
    // this.currentStep = previousStep;
    this.currentStepTitle = this.currentStepTitles[this.previousStep];
  }
  previewDetails(contractNumber) {
    this.claimShow = !this.claimShow;

    if (this.claimShow) {
      this.contractService.contractIndex.next(0);
      if(contractNumber)
        this.contractService.contractId.next(contractNumber);
      this.setContract(this.claim);
    }
  }
  setContract(claim) {
    let contractInfo = {
      lastName: claim.lastName,
      vin: claim.vin,
      contractNumber: claim.contractNumber
    };
    
  }
  resetNoteForm(){
    this.noteForm.controls.noteText.setValue(null);
    this.submittedNote = false;
  }
  submitNoteForm(note?:Note) {
    
    let noteData: Note;
    if(note){
      noteData= note;
    } else{
    this.submittedNote = true;
    if (!this.noteForm.controls.noteText.value || this.sendingNote) return;
    noteData = {
      text: this.noteForm.controls.noteText.value,
      author: this.claimService.userName,
      claimId: this.claim.id,
      createDate:new Date().toString()
    };
  }
    if(!this.claim?.id){
      this.toastr.info('Your note will be added when you continue to next step', 'Notes', {
        positionClass: 'toast-top-center',
      });
      document.getElementById('closeNoteModalButton').click();
      this.notesToBeSent.push(noteData);
      this.resetNoteForm();
      return;
    }
    this.openNotes = false;
    this.sendingNote = true;

    this.claimService.addNote(this.claim.id, noteData).subscribe(
      (result: any) => {
        console.log(result);
        this.noteForm.controls.noteText.setValue(null);
        this.sendingNote = false;
        if(this.claimService.previewActiveClaim)
          this.toastr.success('Successfully added note and sent to Zurich.', 'Notes', {
            positionClass: 'toast-top-center',
          });
        else {
          this.toastr.success('Successfully added note', 'Notes', {
            positionClass: 'toast-top-center',
          });
        }
        document.getElementById('closeNoteModalButton')?.click();
        this.submittedNote = false;
      },
      (error) => {
        this.sendingNote = false;
        this.submittedNote = false;
        console.log(error);
        if(error.status && error.status == 403){
          this.toastr.error(
            'The claim is being edited by another user, please try again later.',
            'Create claim',
            {
              positionClass: 'toast-top-center',
            }
          );
        } else 
        this.toastr.error(
          'Something went wrong while adding note, please try again. ' + 'Error code: '+error.status,
          'Notes',
          {
            positionClass: 'toast-top-center',
          }
        );
      }
    );
  }
  get fNote(){
    return this.noteForm.controls;
  }
  scroll(target:string){
    let el=document.getElementById(target);
    el?.scrollIntoView();
  }
  submitNotes(){
    for(let i=0; i < this.notesToBeSent?.length; i++){
      this.submitNoteForm(this.notesToBeSent[i]);
    }
    this.notesToBeSent=[];
  }
  calculateTotalAgreedAllJobs(){
    //CalculationUtil.calculateTotalAgreedAllJobs
    //CalculationUtil.calculateTotalAgreedRowParts(claim.jobs)
   // calculateTotalAgreedRowParts(),
    //calculateTotalAgreedRowLabors(), getTaxesTotal()
    if(this.claim.claimNumber){
      return this.claim.totalAgreed;
    }
  }
  
  getRepairOrderNum($event){
    if($event && this.claim)
      this.claim.repairOrderNumber = $event;
  }
  onSelectFiles($event){
    if(!this.files || this.files.length <=0 ){
      this.files = $event.addedFiles;
      this.uploadFiles();
    }
    else{
      for(let i = 0; i < $event?.allowedFiles?.length; i++)
        this.files.push($event?.allowedFiles[i]);
      this.uploadFiles();
    }
  }
  onRemove(i:number){
    if(i >= 0 && i < this.files?.length)
      this.files.splice(i, 1);
  }
  uploadFiles(){
    let types: string = "image/bmp,text/csv,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/gif,text/html,image/vnd.microsoft.icon,image/jpeg,application/pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.rar,application/rtf,image/tiff,text/plain,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/xml,text/xml,application/zip,image/x-png,image/png";
    let typeList:string[] = types.split(',');
    if(this.files && this.files.length > 0){
      
      if(!this.claim?.id){
        this.toastr.info('Your attachments will be added when you continue to next step', 'Attachments', {
          positionClass: 'toast-top-center',
        });
        document.getElementById('closeAttachmentFormButton')?.click();
        return;
      }
      this.toastr.info('Uploading attachments', 'Attachments', {
        positionClass: 'toast-top-center',
      });
      let tmpFiles:File[]= [];
      document.getElementById('closeAttachmentFormButton')?.click();
      for(let i = 0; i < this.files?.length; i++){
         if(typeList.some((type)=>this.files[i].type == type)){

        console.log(this.files[i].name);
        console.log(this.files[i].type);
        const formData = new FormData();
        formData.append('file', this.files[i]);

        this.claimService.addAttachment(this.claim.id, formData).subscribe(
          (res) => {
            tmpFiles.push(this.files[i]);
            console.log(res);
            if(this.claimService.previewActiveClaim){
              this.toastr.success('Successfully added attachment and sent to Zurich.', 'Attachments', {
                positionClass: 'toast-top-center',
              });
            }
            else {
              this.toastr.success('Successfully added attachment', 'Attachments', {
                positionClass: 'toast-top-center',
              });
            }
            if(this.files?.length == tmpFiles.length)
              this.removeFiles();
          },
          (error) => {
            console.log(error);
            this.removeFiles();
            if(error.status && error.status == 403){
              this.toastr.error(
                'The claim is being edited by another user, please try again later.',
                'Create claim',
                {
                  positionClass: 'toast-top-center',
                }
              );
            } else 
            this.toastr.error(
              'Something went wrong while adding attachment, please try again. ' + 'Error code: '+error.status,
              'Attachments',
              {
                positionClass: 'toast-top-center', timeOut:6000
              }
            );
          }
        );
      } else {
        this.toastr.error(
          'File: "' + this.files[i].name + '" has unsuported type.',
          'Attachments',
          {
            positionClass: 'toast-top-center', timeOut:6000
          }
        );
      }
      }
    }

  }
  removeFiles(){
      this.files=[];
  }
  returnToNotes($event){
    this.clickFromCode = true;
    if($event) {
      this.counter = 1;
      this.openedConfirmDialog = false;
      this.openConfirmDialog = true;
      this.openConfirmDialog = true;
      this.openedConfirmDialog = false;
      this.noteForm.controls.noteText.setValue(null);
      $('#confirmActionModal-2')?.modal( 'hide');

      document.getElementById('closeNoteModalButton')?.click();
      
    } else {
      this.counter = 2;
      //document.getElementById('closeconfirmActionModalButton')?.click();
      $('#confirmActionModal-2')?.modal( 'hide');

      //document.getElementById('closeNoteModalButton')?.click();

      this.openConfirmDialog = true;
      this.openedConfirmDialog = false;
    }
  }
}
