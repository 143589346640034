import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { Claim } from 'src/app/_model/claim';
import { ClaimService } from 'src/app/_services/claim.service';
import { Attachment } from 'src/app/_model/attachment';

@Component({
  selector: 'app-claim-complaints',
  templateUrl: './claim-complaints.component.html',
  styleUrls: ['./claim-complaints.component.css'],
})
export class ClaimComplaintsComponent implements OnInit,OnDestroy {
  @Output() messageEvent = new EventEmitter<boolean>();
  claimComplaintForm: FormGroup;
  claimSubscription: Subscription;
  claim: Claim;
  selectedQuestion1 = false;
  selectedQuestion2 = false;
  selectedQuestion3 = false;
  submitted: boolean = false;
  attachments: Attachment[] = [];
  uploadForm: FormGroup;
  constructor(
    private claimService: ClaimService,
    private readonly fb: FormBuilder,
    private formBuilder: FormBuilder
  ) {
    this.claimComplaintForm = this.fb.group({
      repairOrderNumber: new FormControl('', Validators.required),
      customerComplaintDesc: [, Validators.required],
      causeDesc: [],
      correctionDesc: [],
      // modificationsDesc: [, Validators.required],
      // commercialUseDesc: [, Validators.required],
      // anyModificationsBoolean: [, Validators.required],
      // isCarTowedIn: [, Validators.required],
      // commercialUse: [, Validators.required],
    });
  }
  ngOnDestroy(): void {
    this.claimSubscription?.unsubscribe();
  }
  get f() {
    return this.claimComplaintForm.controls;
  }
  ngOnInit(): void {
    this.claimSubscription = this.claimService.claim.subscribe((value) => {
      this.claim = value;
    });
    this.uploadForm = this.formBuilder.group({
      profile: [''],
      uploadFile: [''],
    });
  }
  goToNextPage() {
    this.messageEvent.emit(true);
  }

  selectYesNo(value, question) {
    if (value == 'Yes' && question == '1') {
      this.selectedQuestion1 = true;
    } else if (value !== 'Yes' && question == '1') {
      this.selectedQuestion1 = false;
    } else if (value == 'Yes' && question == '2') {
      this.selectedQuestion2 = true;
    } else if (value !== 'Yes' && question == '2') {
      this.selectedQuestion2 = false;
    } else if (value == 'Yes' && question == '3') {
      this.selectedQuestion3 = true;
    } else if (value !== 'Yes' && question == '3') {
      this.selectedQuestion3 = false;
    }
  }

  submitForm() {
    this.submitted = true;

    if (!this.claimComplaintForm.valid) {
      if (!this.selectedQuestion2)
        this.claimComplaintForm.controls.modificationsDesc.setErrors(null);
      if (!this.selectedQuestion3)
        this.claimComplaintForm.controls.commercialUseDesc.setErrors(null);

      if (!this.claimComplaintForm.valid) return;
    }
    this.submitted = false;
    let claimInfo: Claim = {
      id: this.claim.id,
      repairOrderNumber: this.claimComplaintForm.controls.repairOrderNumber
        .value,
      step: 2,
      customerComplaintDesc: this.claimComplaintForm.controls
        .customerComplaintDesc.value,
      causeDesc: this.claimComplaintForm.controls.causeDesc.value,
      correctionDesc: this.claimComplaintForm.controls.correctionDesc.value,
      hasCommercialUse: this.selectedQuestion3,
      hasModifications: this.selectedQuestion2,
      isCarTowed: this.selectedQuestion1,
    };
    if (this.selectedQuestion2)
      claimInfo.modificationsDesc = this.claimComplaintForm.controls.modificationsDesc.value;
    if (this.selectedQuestion3)
      claimInfo.commercialUseDesc = this.claimComplaintForm.controls.commercialUseDesc.value;

    this.claimService.updateClaim(claimInfo).subscribe(
      (result: any) => {
        console.log(result);
        this.goToNextPage();
      },
      (error) => console.log(console.error())
    );

    console.log(this.claimComplaintForm.controls.value);
  }
  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.uploadForm.get('profile').setValue(file);
    }
  }
  onSubmit() {
    this.uploadForm.controls.uploadFile.setValue('');
    const formData = new FormData();
    formData.append('file', this.uploadForm.get('profile').value);

    this.claimService.addAttachment(this.claim.id, formData).subscribe(
      (res) => {
        console.log(res);
        this.getAttachments();
        this.uploadForm.controls.uploadFile.setValue('');
      },
      (err) => console.log(err)
    );
  }

  getAttachments() {
    this.claimService.getAttachments(this.claim.id).subscribe(
      (result: any) => {
        console.log(result);
        this.attachments = result;
      },
      (error) => console.log(error)
    );
  }
  deleteAttachment(attachment) {
    this.claimService.deleteAttachment(attachment.fileId).subscribe(
      (result: any) => {
        console.log(result);
        this.getAttachments();
      },
      (error) => console.log(error)
    );
  }
  downloadAttachment(attachment) {
    this.claimService.downloadAttachment(attachment).subscribe(
      (result: any) => {
        console.log(result);
        const blob = new Blob([result], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      },
      (error) => {
        console.log(error);

        const blob = new Blob([error.url], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        // const url = window.URL.createObjectURL(error.url);
        window.open(error.url);
      }
    );
  }
}
