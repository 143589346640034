<div class="slim-mainpanel pd-x-0">
  <div class="container-fluid pd-x-0">
    <div *ngIf="exportButtonVisible" class="row justify-content-end mg-b-2 pd-x-15">
      <button class="btn btn-oblong btn-primary btn btn-block chevron-button" (click)="exportReport()">
        Export Report
        <div *ngIf="exportReportCalled" class="spinner-border " style="width: 1rem; height: 1rem; vertical-align: middle;" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </button>
    </div>
    <div class="card pd-10 ">
      <div class="row pd-b-30 pd-t-40 pd-x-10">
        <div class="card col-12 bd-0 pd-l-15 pd-x-20">
          <div class="card-header d-flex justify-content-between pd-y-5">
            <div
              (click)="previewDetailsTotal1()"
              class="card-option row align-items-center tx-24"
            >
              <a class="pd-r-10 mg-l-5"
                ><i *ngIf="!showDetailsTotal1" style="cursor: pointer;" class="ion-ios-arrow-down"></i>
                <i *ngIf="showDetailsTotal1" style="cursor: pointer;" class="ion-ios-arrow-up"></i
              ></a>
              <label class="section-title mg-b-0 tx-14 mg-r-50 mg-t-0">TOTALS 
              </label>
              <label class="section-title mg-b-0 tx-14  mg-r-10 mg-t-0">REQUESTED 
              </label>
              <label class="section-title mg-b-0 tx-14  mg-r-30 mg-t-0">{{  claim.totalRequested | currency: "$" }}
              </label>
              <label class="section-title mg-b-0 tx-14  mg-r-10  mg-t-0">AUTHORIZED 
              </label>
              <label *ngIf="claim.claimNumber && (claim.statusCode == 40 || claim.statusCode == 60 || claim.statusCode == 80 || claim.statusCode == 90)" class="section-title mg-b-0 tx-14  mg-r-30  mg-t-0">{{ claim.totalAgreed | currency: "$" }}
              </label>
              <label *ngIf="!claim.claimNumber || (claim.statusCode != 40 && claim.statusCode != 60 && claim.statusCode != 80 && claim.statusCode != 90)" class="section-title mg-b-0 tx-14  mg-r-30  mg-t-0">-</label>
            </div>
            <div
            *ngIf="claim?.statusCode == 40 || claim?.statusCode == 90 || claim.statusCode == 60 || claim.statusCode == 80" 
              class="card-option row align-items-center tx-24"

            >
            <button
                        class="btn btn-oblong btn-primary btn btn-block chevron-button"
                        data-toggle="modal"
                        data-target="#paymentRequest" 
                        (click)="setType('PAYMENT_REQUEST', 1);resetPaymentRequest(); "
                      >
                        Request Payment
            </button> 
            </div>
            <!-- card-option -->
            
          </div>
          <!-- card-header -->
          <ng-template #laborDiffView>
            <span class="tx-black">{{
              jobDetails?.laborRequestedTotal | currency: "$"
            }}</span>
          </ng-template>
          <ng-template #subletDiffView>
            <span class="tx-black">{{
              jobDetails?.subletRequestedTotal | currency: "$"
            }}</span>
          </ng-template>
          <ng-template #otherDiffView>
            <span class="tx-black">{{
              jobDetails?.otherRequestedTotal | currency: "$"
            }}</span>
          </ng-template>
          <ng-template #partDiffView>
            <span class="tx-black">{{
              jobDetails?.partRequestedTotal | currency: "$"
            }}</span>
          </ng-template>
          <ng-template #laborBillingRateDiffView>
            <span class="tx-black">{{
              labor.laborBillingRate | currency: "$"
            }}</span>
          </ng-template>
          <ng-template #subletUnitPriceDiffView>
            <span class="tx-black">{{
              sublet.unitPrice | currency: "$"
            }}</span>
          </ng-template>
          <ng-template #laborHoursDiffView>
            <span class="tx-black">{{ labor.laborHours }}</span>
          </ng-template>
          <ng-template #laborTaxDiffView>
            <span class="tx-black">{{ labor.tax | currency: "$" }}</span>
          </ng-template>
          <ng-template #subletQuantityDiffView>
            <span class="tx-black">{{ sublet.quantity }}</span>
          </ng-template>

          <ng-template #partUnitPriceIndividualDiffView>
            <span class="tx-black">{{ part.unitPrice | currency: "$" }}</span>
          </ng-template>
          <ng-template #partQuantityIndividualDiffView>
            <span class="tx-black">{{ part.quantity }}</span>
          </ng-template>
          <ng-template #partPriceIndividualDiffView>
            <span class="tx-black">{{
              calculateRequestedPart() | currency: "$"
            }}</span>
          </ng-template>
          <ng-template #partTaxIndividualDiffView>
            <span class="tx-black">{{ part.tax | currency: "$" }}</span>
          </ng-template>
          <ng-template #laborPriceIndividualDiffView>
            <span class="tx-black">{{
              calculateRequestedLabor() | currency: "$"
            }}</span>
          </ng-template>
          <ng-template #subletIndividualDiffView>
            <span class="tx-black">{{
              calculateRequestedSublet() | currency: "$"
            }}</span>
          </ng-template>

          <ng-template #totalRowPartsDiffView>
            <span class="tx-black">{{
              claim.partRequestedTotal | currency: "$"
            }}</span>
          </ng-template>
          <ng-template #totalRowLaborsDiffView>
            <span class="tx-black">{{
              claim.laborRequestedTotal | currency: "$"
            }}</span>
          </ng-template>
          <ng-template #totalRowSubletsDiffView>
            <span class="tx-black">{{
              claim.subletRequestedTotal | currency: "$"
            }}</span>
          </ng-template>
          <ng-template #totalRowOthersDiffView>
            <span class="tx-black">{{
              claim.otherRequestedTotal | currency: "$"
            }}</span>
          </ng-template>
          <ng-template #customerComplaintDesc>
            <span class="tx-white">{{jobDetails.customerComplaintDesc}}
            </span>
          </ng-template>
          <ng-template #causeDesc>
            <span class="tx-white">{{jobDetails.causeDesc}}
            </span>
          </ng-template>
          <ng-template #correctionDesc>
            <span class="tx-white">{{jobDetails.correctionDesc}}
            </span>
          </ng-template>
          <ng-template #modificationsDesc>
            <span class="tx-white">{{claim.modificationsDesc}}
            </span>
          </ng-template>
          <ng-template #commercialUseDesc>
            <span class="tx-white">{{claim.commercialUseDesc}}
            </span>
          </ng-template>
          <ng-template #subletDescription>
            <span class="tx-white">{{jobDetails?.sublets[0]?.description}}
            </span>
          </ng-template>
          <ng-template #subletJobDescription>
            <span class="tx-white">{{jobDetails?.lossDescription}}
            </span>
          </ng-template>
          <ng-template #partDescription>
            <span class="tx-white">{{part.partDescription}}
            </span>
          </ng-template>
          <ng-template #laborDescription>
            <span class="tx-white">{{labor.laborDescription}}
            </span>
          </ng-template>
          <ng-template #scsUpdateNote>
            <span class="tx-white">{{jobDetails.scsUpdateNote}}
            </span>
          </ng-template>
          <ng-template #partScsUpdateNote>
            <span class="tx-white">{{part.scsUpdateNote}}<label *ngIf="part.scsUpdateNote && part.note">; </label>{{part.note}}
            </span>
          </ng-template>
          <ng-template #laborScsUpdateNote>
            <span class="tx-white">{{labor.scsUpdateNote}}<label *ngIf="labor.scsUpdateNote && labor.note">; </label>{{labor.note}}
            </span>
          </ng-template>
          <ng-template #subletScsUpdateNote>
            <span class="tx-white">{{sublet.scsUpdateNote}}<label *ngIf="sublet.scsUpdateNote && sublet.note">; </label>{{sublet.note}}
            </span>
          </ng-template>
          <ng-template #jobTireTax>
            <span class="tx-white">{{jobDetails.taxRequestedTotal | currency: "$"}}
            </span>
          </ng-template>
          <ng-template #fullJob >
            <div class="row">
              <div class="col-12 pd-b-10">
                <label class="section-title mg-b-0 tx-14 mg-t-0" style="text-align:start;"
                  >{{ jobDetails?.jobNumber }} - {{ jobDetails?.jobComponent }}/{{
                    jobDetails?.lossDescription
                  }}
                </label>
              </div>
              <div *ngIf="jobDetails?.type != 'BENEFIT'" style="max-width: 250px;text-align:start;" class="col-xl-4 col-md-6 col-sm-12 tx-black">
                <label class="form-control-label col-12 p-style" triggers="click" placement="bottom" tooltipClass="my-custom-class-inner-td" [ngbTooltip]="customerComplaintDesc" [disableTooltip]="!jobDetails?.customerComplaintDesc"
                  >CUSTOMER COMPLAINT: {{jobDetails?.customerComplaintDesc}}
                </label>
                <label class="form-control-label col-12 p-style" triggers="click" placement="bottom" tooltipClass="my-custom-class-inner-td" [ngbTooltip]="causeDesc" [disableTooltip]="!jobDetails?.causeDesc"
                  >CAUSE: {{jobDetails?.causeDesc}}
                </label>
                <label class="form-control-label col-12 p-style" triggers="click" placement="bottom" tooltipClass="my-custom-class-inner-td" [ngbTooltip]="correctionDesc" [disableTooltip]="!jobDetails?.correctionDesc"
                  >CORRECTION: {{jobDetails?.correctionDesc}}
              </label>
              </div>
              <div  *ngIf="jobDetails?.type != 'BENEFIT'" style="max-width: 450px;text-align:start;" class="col-xl-4 col-md-6 col-sm-12 tx-black">
                <!-- ENGINE -->
                <label *ngIf="jobDetails?.jobComponent?.includes('Engine') && jobDetails?.engineCoolantAndOilStatus" class="form-control-label col-12 p-style" style="width: 450px !important;" triggers="click" placement="bottom" tooltipClass="my-custom-class-inner-td" ngbTooltip="{{jobDetails?.engineCoolantAndOilStatus}}" 
                  >What is the fluid level and condition for coolant and oil?  {{ jobDetails?.engineCoolantAndOilStatus }}
                </label>
                <label *ngIf="jobDetails?.jobComponent?.includes('Engine') && jobDetails?.anyEngineFluidLeaks" class="form-control-label col-12 p-style" triggers="click" placement="bottom" tooltipClass="my-custom-class-inner-td" ngbTooltip="{{jobDetails?.anyEngineFluidLeaks}}" 
                  >Any fluid leaks? {{jobDetails?.anyEngineFluidLeaks }}
                </label>
                <label *ngIf="jobDetails?.jobComponent?.includes('Engine') && jobDetails?.engineFaultCodes" class="form-control-label col-12 p-style" triggers="click" placement="bottom" tooltipClass="my-custom-class-inner-td" ngbTooltip="{{jobDetails?.engineFaultCodes}}" 
                  >Any fault codes present?  {{ jobDetails?.engineFaultCodes }}
                </label>
                <label *ngIf="jobDetails?.jobComponent?.includes('Engine') && jobDetails?.engineTearDownToConfirmTheFailure" class="form-control-label col-12 p-style" triggers="click" placement="bottom" tooltipClass="my-custom-class-inner-td" ngbTooltip="{{jobDetails?.engineTearDownToConfirmTheFailure}}" 
                  >Describe the level of tear down: {{ jobDetails?.engineTearDownToConfirmTheFailure }}
                </label>
                <!-- TRANSMISSION -->
                <label *ngIf="jobDetails?.jobComponent?.includes('Transmission') && jobDetails?.transmissionFluidAndCondition" class="form-control-label col-12 p-style" triggers="click" placement="bottom" tooltipClass="my-custom-class-inner-td" ngbTooltip="{{jobDetails?.transmissionFluidAndCondition}}" 
                  >What is the transmission fluid level and condition? {{jobDetails?.transmissionFluidAndCondition }}
                </label>
                <label *ngIf="jobDetails?.jobComponent?.includes('Transmission') && jobDetails?.anyTransmissionFluidLeaks" class="form-control-label col-12 p-style" triggers="click" placement="bottom" tooltipClass="my-custom-class-inner-td" ngbTooltip="{{jobDetails?.anyTransmissionFluidLeaks}}" 
                  >Any fluid leaks? {{jobDetails?.anyTransmissionFluidLeaks }}
                </label>
                <label *ngIf="jobDetails?.jobComponent?.includes('Transmission') && jobDetails?.transmissionFaultCodes" class="form-control-label col-12 p-style" triggers="click" placement="bottom" tooltipClass="my-custom-class-inner-td" ngbTooltip="{{jobDetails?.transmissionFaultCodes}}" 
                  >Any fault codes present? {{jobDetails?.transmissionFaultCodes }}
                </label>
                <label *ngIf="jobDetails?.jobComponent?.includes('Transmission') && jobDetails?.transmissionOfferStatus" class="form-control-label col-12 p-style" style="width: 450px !important;" triggers="click" placement="bottom" tooltipClass="my-custom-class-inner-td" ngbTooltip="{{jobDetails?.transmissionOfferStatus}}" 
                  >Describe internal parts or repairs offered by the factory? {{ jobDetails?.transmissionOfferStatus }}
                </label>
                <label *ngIf="jobDetails?.jobComponent?.includes('Transmission') && jobDetails?.transmissionTearDownToConfirmTheFailure" class="form-control-label col-12 p-style" triggers="click" placement="bottom" tooltipClass="my-custom-class-inner-td" style="width: 450px !important;" ngbTooltip="{{jobDetails?.transmissionTearDownToConfirmTheFailure}}" 
                  >Has any tear down been done to confirm the failure?  {{ jobDetails?.transmissionTearDownToConfirmTheFailure }}
                </label>
                <!-- TIRE & WHEEL -->
                <label *ngIf="jobDetails?.jobComponent?.includes('Wheel') && jobDetails?.jobComponent?.includes('Tire') && jobDetails?.treadDepth" class="form-control-label col-12 p-style" triggers="click" placement="bottom" tooltipClass="my-custom-class-inner-td" ngbTooltip="{{jobDetails?.treadDepth}}" 
                  >Tread depth: {{ jobDetails?.treadDepth }}
                </label>
              </div>
              <div  *ngIf="jobDetails?.type == 'BENEFIT'" style="max-width: 250px; text-align:start;" class="col-xl-4 tx-black col-md-6 col-sm-12">
                <label class="form-control-label col-12 p-style" triggers="click" placement="right" tooltipClass="my-custom-class-inner-td" [ngbTooltip]="subletDescription" [disableTooltip]="!jobDetails?.sublets[0]?.description"
                  >Description:  {{ jobDetails?.sublets[0]?.description ? jobDetails?.sublets[0]?.description : "No" }}
                </label>
              </div>
              <div *ngIf="jobDetails?.type != 'BENEFIT' && disabledField" class="col-12 tx-black" style="text-align: end; align-self: end;">
                <label class="form-control-label col-12 p-style tx-bold" triggers="click" placement="top" tooltipClass="my-custom-class-inner-td" [ngbTooltip]="scsUpdateNote" [disableTooltip]="!jobDetails?.scsUpdateNote"
                  >{{jobDetails?.scsUpdateNote}}
                </label>
              </div>
              <div *ngIf="jobDetails?.type == 'BENEFIT' && disabledField" class="col-12 tx-black" style="text-align: end; align-self: end;">
                <label class="form-control-label col-12 p-style tx-bold" triggers="click" placement="top" tooltipClass="my-custom-class-inner-td" [ngbTooltip]="scsUpdateNote" [disableTooltip]="!jobDetails?.scsUpdateNote"
                  >{{jobDetails?.scsUpdateNote}}
                </label>
              </div>
            </div>  
            <div class="table-wrapper table-responsive" style="overflow: auto; max-height: 300px; max-width:100%; border: 2px solid black;">
              <table id="dt1" class="table display responsive nowrap">
                <thead>
                  <tr>
                    <th class="wd-15p bg-white zurich-blue" style="min-width: 110px;">Detail Type</th>
                    <th class="wd-15p  bg-white zurich-blue" style="min-width: 80px;">Part No.</th>
                    <th class="wd-15p bg-white zurich-blue" style="max-width: 160px;">Description</th>
                    <th class="wd-15p bg-white zurich-blue" style="min-width: 95px;">Unit Price</th>
                    <th class="wd-15p bg-white zurich-blue" >Quantity</th>
                    <th class="wd-15p bg-white zurich-blue" style="min-width: 140px;">Tax</th>
                    <th class="wd-15p bg-white zurich-blue" style="min-width: 140px;">Requested Total</th>
                    <th class="wd-15p bg-white zurich-blue" style="min-width: 145px;">Authorized Total</th>
                    <th class="wd-15p bg-white zurich-blue" style="max-width: 160px;">Notes</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let part of jobDetails?.parts; let i = index">
                    <td style="text-align-last: center">Part</td>
                    <td style="text-align-last: center">
                      {{ part.partNumber }}
                    </td>
                    <td (mouseover)="setPart(part)" style="max-width: 160px;" class="text-center p-style-td" ><label class="text-center p-style-td" style="max-width: 160px;" tooltipClass="my-custom-class-inner-td" triggers="click" placement="left" [ngbTooltip]="partDescription">{{ part.partDescription }}</label></td>

                    <td style="text-align-last: center">
                      <span *ngIf="( part.agreedUnitPrice !=  part.unitPrice)"
                        class="icon icon--gear_24_outline mg-r-1"
                        (mouseover)="setPart(part)"
                        placement="top"
                        [container]="'body'"
                        tooltipClass="my-custom-class-inner"
                        [ngbTooltip]="partUnitPriceIndividualDiffView"
                      ></span>
                      {{ part.agreedUnitPrice | currency: "$" }}
                    </td>
                    <td style="text-align-last: center">
                      <span *ngIf="( part.agreedQuantity !=  part.quantity)"
                        class="icon icon--gear_24_outline mg-r-1"
                        (mouseover)="setPart(part)"
                        placement="top"
                        [container]="'body'"
                        tooltipClass="my-custom-class-inner"
                        [ngbTooltip]="partQuantityIndividualDiffView"
                      ></span>
                      {{ part.agreedQuantity }}
                    </td>
                    <td style="text-align-last: center">
                      <span *ngIf="( part.agreedTax !=  part.tax)"
                        class="icon icon--gear_24_outline mg-r-1"
                        (mouseover)="setPart(part)"
                        placement="top"
                        [container]="'body'"
                        tooltipClass="my-custom-class-inner"
                        [ngbTooltip]="partTaxIndividualDiffView"
                      ></span>
                      {{ part.agreedTax | currency: "$" }}
                      </td>

                    <td style="text-align-last: center">{{ part.requestedTotalWithTax | currency: "$" }}</td>
                    <td *ngIf="(!jobDetails?.deleted && !part.deleted) && claim.claimNumber && (claim.statusCode == 40 || claim.statusCode == 60 || claim.statusCode == 80 || claim.statusCode == 90)" style="text-align-last: center">
                      <!-- <span *ngIf="(calculateAgreedTotalPart(part) != calculateRequestedPartTooltip(part))"
                        class="icon icon--gear_24_outline mg-r-1"
                        (mouseover)="setPart(part)"
                        placement="top"
                        [container]="'body'"
                        tooltipClass="my-custom-class-inner"
                        [ngbTooltip]="partPriceIndividualDiffView"
                      ></span> -->

                      {{ part.agreedTotalWithTax | currency: "$" }}
                    </td>
                    <td class="text-center tx-14" *ngIf="(jobDetails?.deleted || part.deleted) || !claim.claimNumber || (claim.statusCode != 40 && claim.statusCode != 60 && claim.statusCode != 80 && claim.statusCode != 90)"><label  class="text-center">$0</label></td>
                    <td  style="max-width: 300px;" triggers="click" tooltipClass="my-custom-class-inner-td" placement="left" [ngbTooltip]="partScsUpdateNote" [disableTooltip]="!part.scsUpdateNote && !part.note" class="text-center  p-style-td" (mouseover)="setPart(part)">
                      <span style=" white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;" *ngIf="part.scsUpdateNote" >{{ part.scsUpdateNote }}</span>
                      <span *ngIf="part.scsUpdateNote && part.note">; </span>
                      <span>{{ part.note }}</span>  
                    </td>

                  </tr>
                  <tr *ngFor="let labor of jobDetails?.labors; let i = index">
                    <td style="text-align-last: center">Labor</td>
                    <td style="text-align-last: center"></td>
                    <td (mouseover)="setLabor(labor)"  style="max-width: 160px;" class="text-center p-style-td" ><label style="max-width: 160px;" triggers="click" tooltipClass="my-custom-class-inner-td" class="text-center p-style-td" placement="left" [ngbTooltip]="laborDescription">{{ labor.laborDescription }}</label></td>
                    <td style="text-align-last: center">
                      <span *ngIf="(labor.agreedLaborBillingRate != labor.laborBillingRate)"
                        class="icon icon--gear_24_outline mg-r-1"
                        (mouseover)="setLabor(labor)"
                        placement="top"
                        [container]="'body'"
                        tooltipClass="my-custom-class-inner"
                        [ngbTooltip]="laborBillingRateDiffView"
                      ></span>
                      {{ labor.agreedLaborBillingRate | currency: "$" }}
                    </td>
                    <td style="text-align-last: center">
                      <span *ngIf=" (labor.agreedLaborHours != labor.laborHours)"
                        class="icon icon--gear_24_outline mg-r-1"
                        (mouseover)="setLabor(labor)"
                        placement="top"
                        [container]="'body'"
                        tooltipClass="my-custom-class-inner"
                        [ngbTooltip]="laborHoursDiffView"
                      ></span>
                      {{ labor.agreedLaborHours }}
                    </td>
                    <td style="text-align-last: center">
                      <span *ngIf="( labor.agreedTax !=  labor.tax)"
                        class="icon icon--gear_24_outline mg-r-1"
                        (mouseover)="setLabor(labor)"
                        placement="top"
                        [container]="'body'"
                        tooltipClass="my-custom-class-inner"
                        [ngbTooltip]="laborTaxDiffView"
                      ></span>
                      {{ labor.agreedTax | currency: "$" }}</td>
                    <td style="text-align-last: center">{{ labor.requestedTotalWithTax | currency: "$" }}</td>

                    <td *ngIf="(!jobDetails?.deleted && !labor.deleted) && claim.claimNumber && (claim.statusCode == 40 || claim.statusCode == 60 || claim.statusCode == 80 || claim.statusCode == 90)" style="text-align-last: center">
                      <!-- <span *ngIf="(calculateAgreedTotalLabor(labor) != calculateRequestedLaboTooltip(labor))"
                        class="icon icon--gear_24_outline mg-r-1"
                        (mouseover)="setLabor(labor)"
                        placement="top"
                        [container]="'body'"
                        tooltipClass="my-custom-class-inner"
                        [ngbTooltip]="laborPriceIndividualDiffView"
                      ></span> -->

                      {{ labor.agreedTotalWithTax | currency: "$" }}
                    </td>
                    <td *ngIf="(jobDetails?.deleted || labor.deleted) || !claim.claimNumber || (claim.statusCode != 40 && claim.statusCode != 60 && claim.statusCode != 80 && claim.statusCode != 90)" class="text-center "><label class="text-center">$0</label></td>

                    <!-- <td style="max-width: 160px;" class="text-center p-style-td" (mouseover)="setLabor(labor)"><label style="max-width: 150px;" class="text-center p-style-td" triggers="click" tooltipClass="my-custom-class-inner-td" *ngIf="labor.scsUpdateNote" placement="left" [ngbTooltip]="laborScsUpdateNote" [disableTooltip]="!labor.scsUpdateNote">{{ labor.scsUpdateNote }}<label *ngIf="labor.scsUpdateNote && labor.note">; </label></label><label class="tx-bold">{{ labor.note }}</label>
                    </td> -->
                    <td  style="max-width: 300px;" triggers="click" tooltipClass="my-custom-class-inner-td" placement="left" [ngbTooltip]="laborScsUpdateNote" [disableTooltip]="!labor.scsUpdateNote && !labor.note" class="text-center  p-style-td" (mouseover)="setLabor(labor)">
                      <span style=" white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;" *ngIf="labor.scsUpdateNote" >{{ labor.scsUpdateNote }}</span>
                      <span *ngIf="labor.scsUpdateNote && labor.note">; </span>
                      <span>{{ labor.note }}</span>  
                    </td>
                  </tr>
                  <tr *ngFor="let sublet of jobDetails?.sublets; let i = index">
                    <td style="text-align-last: center">{{ sublet.type == "S" ? "Sublet" : "Other" }}</td>
                    <td style="text-align-last: center"></td>
                    <td style="max-width: 150px;"  (mouseover)="setSublet(sublet)" *ngIf="sublet.description" class="text-center p-style-td" ><label triggers="click" tooltipClass="my-custom-class-inner-td" style="max-width: 150px;" class="text-center p-style-td" placement="left" [disableTooltip]="!jobDetails?.sublets[0]?.description" [ngbTooltip]="subletDescription">{{ sublet.description }}</label></td>
                    <td style="max-width: 150px;"  *ngIf="!sublet.description" class="text-center p-style-td" ><label triggers="click" tooltipClass="my-custom-class-inner-td" style="max-width: 150px;" class="text-center p-style-td" placement="left" [disableTooltip]="!jobDetails?.lossDescription" [ngbTooltip]="subletJobDescription">{{ jobDetails?.lossDescription }}</label></td>

                    <!-- <td class="text-center p-style-td" style="max-width: 150px;" *ngIf="sublet.description">{{ sublet.description }}</td> -->
                    <!-- <td class="text-center p-style-td" style="max-width: 150px;" *ngIf="!sublet.description">{{ jobDetails?.lossDescription }}</td> -->
                    <td style="text-align-last: center">
                      <span *ngIf="(sublet.agreedUnitPrice != sublet.unitPrice)"
                        class="icon icon--gear_24_outline mg-r-1"
                        (mouseover)="setSublet(sublet)"
                        placement="top"
                        [container]="'body'"
                        tooltipClass="my-custom-class-inner"
                        [ngbTooltip]="subletUnitPriceDiffView"
                      ></span>
                      {{ sublet.agreedUnitPrice | currency: "$" }}
                    </td>
                    <td style="text-align-last: center">
                      <span *ngIf="(sublet.quantity && (sublet.agreedQuantity != sublet.quantity))"
                        class="icon icon--gear_24_outline mg-r-1"
                        (mouseover)="setSublet(sublet)"
                        placement="top"
                        [container]="'body'"
                        tooltipClass="my-custom-class-inner"
                        [ngbTooltip]="subletQuantityDiffView"
                      ></span>
                      {{ sublet.agreedQuantity ? sublet.agreedQuantity : "" }}
                    </td>

                    <td class="text-center" ><label class="text-center">$0</label></td>

                    <td style="text-align-last: center">{{ sublet.requestedTotalWithTax | currency: "$" }}</td>

                    <td *ngIf="(!jobDetails?.deleted && !sublet.deleted) && claim.claimNumber && (claim.statusCode == 40 || claim.statusCode == 60 || claim.statusCode == 80 || claim.statusCode == 90)" style="text-align-last: center">
                      <!-- <span *ngIf="(calculateAgreedTotalSublet(sublet) != calculateRequestedSubletTooltip(sublet))"
                        class="icon icon--gear_24_outline mg-r-1"
                        (mouseover)="setSublet(sublet)"
                        placement="top"
                        [container]="'body'"
                        tooltipClass="my-custom-class-inner"
                        [ngbTooltip]="subletIndividualDiffView"
                      ></span> -->

                      {{ sublet.agreedTotalWithTax | currency: "$" }}
                    </td>
                    <td class="text-center" *ngIf="(jobDetails?.deleted || sublet.deleted) || !claim.claimNumber || (claim.statusCode != 40 && claim.statusCode != 60 && claim.statusCode != 80 && claim.statusCode != 90)"><label class="text-center">$0</label></td>

                    <!-- <td style="max-width: 160px;" class="text-center p-style-td"  (mouseover)="setSublet(sublet)"><label triggers="click" tooltipClass="my-custom-class-inner-td" *ngIf="sublet.scsUpdateNote" style="max-width: 160px;" class="text-center p-style-td" placement="left" [ngbTooltip]="subletScsUpdateNote" [disableTooltip]="!sublet.scsUpdateNote">{{ sublet.scsUpdateNote }}<label *ngIf="sublet.scsUpdateNote && sublet.note">; </label></label><label class="tx-bold">{{ sublet.note }}</label>
                    </td> -->
                    <td  style="max-width: 300px;" triggers="click" tooltipClass="my-custom-class-inner-td" placement="left" [ngbTooltip]="subletScsUpdateNote" [disableTooltip]="!sublet.scsUpdateNote && !sublet.note" class="text-center  p-style-td" (mouseover)="setSublet(sublet)">
                      <span style=" white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;" *ngIf="sublet.scsUpdateNote" >{{ sublet.scsUpdateNote }}</span>
                      <span *ngIf="sublet.scsUpdateNote && sublet.note">; </span>
                      <span>{{ sublet.note }}</span>  
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </ng-template>

          <div *ngIf="showDetailsTotal1" class="card-body">
            <div class="table-wrapper table-responsive">
              <table id="datatable1" class="table display responsive nowrap">
                <thead>
                  <tr>
                <th class="text-center bg-white zurich-blue">Job Code</th>
                <th class="text-center bg-white zurich-blue">Parts</th>
                <th class="text-center bg-white zurich-blue">Labor</th>
                <th class="text-center bg-white zurich-blue">Sublet</th>
                <th class="text-center bg-white zurich-blue">Other</th>
                <th class="text-center bg-white zurich-blue">Sub Total</th>
                <!--th *ngIf="checkIfTireAndWheelJobsContainTireTax()"  class="text-center bg-white zurich-blue">Tire Tax</th-->
                <th class="text-center bg-white zurich-blue">Tax</th>
                <th class="text-center bg-white zurich-blue">Deduct</th>
                <th class="text-center bg-white zurich-blue">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let job of jobList; let i = index">
                    <td (mouseover)="setJob(i)" class="text-center">
                      <span
                        #tt="ngbTooltip"
                        class="icon icon--gear_24_outline mg-r-1"
                        (click)="openToolTip(i)"
                        [autoClose]="'outside'"
                        triggers="manual"
                        [container]="'body'"
                        [placement]="['top-left', 'bottom-left']"
                        tooltipClass="my-custom-class-full"
                        [ngbTooltip]="fullJob"
                        style="cursor: pointer;"
                      >
                      </span
                      >{{ job.jobNumber }}
                    </td>
                    <td class="text-center">
                      <span *ngIf="((job.partAgreedTotal != job.partRequestedTotal) || (job.deleted && (job.partAgreedTotal > 0 || job.partRequestedTotal)))"
                        (mouseover)="setJob(i)"
                        class="icon icon--gear_24_outline mg-r-1"
                        placement="top"
                        autoClose="'outside'"
                        tooltipClass="my-custom-class"
                        [ngbTooltip]="partDiffView"
                      >
                      </span>
                      <span *ngIf="job.deleted">{{
                        0 | currency: "$"
                      }}</span>
                      <span *ngIf="!job.deleted">
                      {{
                        job.partAgreedTotal | currency: "$"
                      }}
                    </span>
                      <!-- calculateSumPart bilo -->
                    </td>
                    <td class="text-center"> 
                      <span *ngIf=" (job.laborAgreedTotal != job.laborRequestedTotal) || (job.deleted && (job.laborAgreedTotal > 0 || job.laborRequestedTotal > 0))"
                        (mouseover)="setJob(i)"
                        class="icon icon--gear_24_outline mg-r-1"
                        placement="top"
                        autoClose="'outside'"
                        tooltipClass="my-custom-class"
                        [ngbTooltip]="laborDiffView"
                      >
                      </span>
                      <span *ngIf="job.deleted">{{
                        0 | currency: "$"
                      }}</span>
                      <span *ngIf="!job.deleted">
                      {{
                        job.laborAgreedTotal
                          | currency: "$"
                      }}
                    </span>
                    </td>
                    <td class="text-center">
                      <span *ngIf=" (job.subletAgreedTotal != job.subletRequestedTotal) || (job.deleted && (job.subletAgreedTotal > 0 ||  job.subletRequestedTotal > 0))"
                        (mouseover)="setJob(i)"
                        class="icon icon--gear_24_outline mg-r-1"
                        placement="top"
                        autoClose="'outside'"
                        tooltipClass="my-custom-class"
                        [ngbTooltip]="subletDiffView"
                      >
                      </span>
                      <span *ngIf="job.deleted">{{
                        0 | currency: "$"
                      }}</span>
                      <span *ngIf="!job.deleted">
                      {{
                        job.subletAgreedTotal
                          | currency: "$"
                      }}
                    </span>
                    </td>
                    <td class="text-center">
                      <span *ngIf=" (job.otherAgreedTotal != job.otherRequestedTotal) || (job.deleted && (job.otherAgreedTotal > 0 || job.otherRequestedTotal) > 0)"
                        (mouseover)="setJob(i)"
                        class="icon icon--gear_24_outline mg-r-1"
                        placement="top"
                        autoClose="'outside'"
                        tooltipClass="my-custom-class"
                        [ngbTooltip]="otherDiffView"
                      >
                      </span>
                      <span *ngIf="job.deleted">{{
                        0 | currency: "$"
                      }}</span>
                      <span *ngIf="!job.deleted">
                      {{
                        job.otherAgreedTotal
                          | currency: "$"
                      }}
                    </span>
                    </td>
                    <td class="text-center">
                      {{
                        job.subTotalAgreedTotal | currency: "$"
                      }}
                      
                    </td>
                    <!--td *ngIf="checkIfTireAndWheelJobsContainTireTax()" class="text-center">
                      <span *ngIf=" (job.agreedTax != job.tax)"
                        (mouseover)="setJob(i)"
                        class="icon icon--gear_24_outline mg-r-1"
                        placement="top"
                        autoClose="'outside'"
                        tooltipClass="my-custom-class"
                        [ngbTooltip]="jobTireTax"
                      >
                      </span>
                      {{ job.agreedTax | currency: "$" }}</td-->

                    <td class="text-center">
                      <span *ngIf=" (job.taxAgreedTotal != job.taxRequestedTotal)"
                        (mouseover)="setJob(i)"
                        class="icon icon--gear_24_outline mg-r-1"
                        placement="top"
                        autoClose="'outside'"
                        tooltipClass="my-custom-class"
                        [ngbTooltip]="jobTireTax"
                      >
                      </span>{{ job.taxAgreedTotal | currency: "$" }}</td>
                    <td class="text-center">{{ getDeductible(job) | currency: "$"}}</td>
                    <td class="text-center tx-bold">
                      {{
                        job.totalAgreed | currency: "$"
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center tx-bold">Total</td>
                    <td class="text-center tx-bold">
                      <span  *ngIf="claim.partAgreedTotal != claim.partRequestedTotal"
                        class="icon icon--gear_24_outline mg-r-1"
                        placement="top"
                        autoClose="'outside'"
                        tooltipClass="my-custom-class"
                        [ngbTooltip]="totalRowPartsDiffView"
                      >
                      </span>
                      {{ claim.partAgreedTotal | currency: "$" }}
                    </td>
                    <td class="text-center tx-bold">
                      <span *ngIf="claim.laborAgreedTotal != claim.laborRequestedTotal"
                        class="icon icon--gear_24_outline mg-r-1"
                        placement="top"
                        autoClose="'outside'"
                        tooltipClass="my-custom-class"
                        [ngbTooltip]="totalRowLaborsDiffView"
                      >
                      </span>
                      {{ claim.laborAgreedTotal | currency: "$" }}
                    </td>
                    <td class="text-center tx-bold">
                      <span *ngIf="claim.subletAgreedTotal != claim.subletRequestedTotal"
                        class="icon icon--gear_24_outline mg-r-1"
                        placement="top"
                        autoClose="'outside'"
                        tooltipClass="my-custom-class"
                        [ngbTooltip]="totalRowSubletsDiffView"
                      >
                      </span>
                      {{ claim.subletAgreedTotal | currency: "$" }}
                    </td>
                    <td class="text-center tx-bold">
                      <span *ngIf="claim.otherAgreedTotal != claim.otherRequestedTotal"
                        class="icon icon--gear_24_outline mg-r-1"
                        placement="top"
                        autoClose="'outside'"
                        tooltipClass="my-custom-class"
                        [ngbTooltip]="totalRowOthersDiffView"
                      >
                      </span>
                      {{ claim.otherAgreedTotal | currency: "$" }}
                    </td>
                    <td class="text-center tx-bold">
                    {{
                      claim.subTotalAgreedTotal | currency: "$"
                      }}
                    </td>
                    <!--td *ngIf="checkIfTireAndWheelJobsContainTireTax()" class="text-center tx-bold">{{ calculateTireTaxesAgreedTotal() | currency: "$" }}</td-->
                    <td class="text-center tx-bold">{{ claim.taxAgreedTotal | currency: "$" }}</td>

                    <td class="text-center tx-bold">{{getDeductiblesTotal() |  currency: "$"}}</td>
                    <td class="text-center tx-bold">
                      {{
                        claim.totalAgreed | currency: "$"
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- table-wrapper -->
          </div>
          <!-- card-body -->
        </div>
        <!-- card -->
      </div>

      <div
        class="container-fluid justify-content-around mg-l-0 mg-r-0container-fluid justify-content-around mg-l-0 mg-r-0"
      >
        <div class="col-12 mg-b-50">
          <div class="row justify-content-between">
            <div class="col-3 pd-b-20">
              <div class="row justify-content-between bd-b pd-t-10">
                <label class="form-control-label tx-primary"
                  >Repair order #
                </label>
                <p class="form-control-label text-break mg-b-10">
                  {{ claim.repairOrderNumber }}
                </p>
              </div>
              <!-- <div class="row justify-content-between bd-b pd-t-10">
                <label class="form-control-label tx-primary"
                  >Purchase Date</label
                >
                <p class="form-control-label text-break mg-b-10">01/01/2021</p>
              </div> -->
              <div class="row justify-content-between bd-b pd-t-10">
                <label class="form-control-label tx-primary">Repair Order Date</label>
                <p class="form-control-label text-break mg-b-10">
                  {{ lossDate }}
                </p>
              </div>
              <div class="row justify-content-between bd-b pd-t-10">
                <label class="form-control-label tx-primary"
                  >Loss Odometer
                </label>
                <p class="form-control-label text-break mg-b-10">
                  {{ claim.lossOdometer | number: "1.0-2" }}
                </p>
              </div>
              <div class="row justify-content-between bd-b pd-t-10">
                <label class="form-control-label tx-primary"
                  >Claim created
                </label>
                <p class="form-control-label text-break mg-b-10">
                  {{ createdDate }}
                </p>
              </div>
            </div>

            <div class="col-3 pd-b-20">
              <div class="row justify-content-between bd-b pd-t-10">
                <label class="form-control-label tx-primary"
                  >Vehicle Towed?
                  <!-- {{
                    claim.isCarTowed && !attachments
                      ? "Please attach applicable tow bill if requesting reimbursement"
                      : ""
                  }}-->
                </label>
                <p class="form-control-label text-break mg-b-10">
                  {{ claim.isCarTowed ? "Yes" : "No" }}
                </p>
              </div>
              <div class="row justify-content-between bd-b pd-t-10">
                <label class="form-control-label tx-primary"
                  >Vehicle Modifications?
                </label>
                <p class="form-control-label text-break mg-b-10">
                  {{ claim.hasModifications ? "Yes" : "No" }}
                </p>
              </div>

              <div class="row justify-content-between bd-b pd-t-10">
                <label class="form-control-label tx-primary"
                  >Commercial Use Vehicle
                </label>
                <p class="form-control-label text-break mg-b-10">
                  {{ claim.hasCommercialUse ? "Yes" : "No" }}
                </p>
              </div>
              <div class="row justify-content-between bd-b pd-t-10">
                <label class="form-control-label tx-primary"
                  >Claim submitted
                </label>
                <p class="form-control-label text-break mg-b-10">
                  {{ scsSentDate }}
                </p>
              </div>
            </div>

            <div class="col-3 pd-b-20">
              <div class="row justify-content-between bd-b pd-t-10">
                <label class="form-control-label tx-primary"
                  >Payee Name
                </label>
                <p class="form-control-label text-break mg-b-10">
                  {{ payeeNameLabel }}
                </p>
              </div>
              <div class="row justify-content-between bd-b pd-t-10">
                <label class="form-control-label tx-primary"
                  >Payment Method
                </label>
                <p class="form-control-label text-break mg-b-10">
                  {{ paymentMethod }}
                </p>
              </div>

              <div
                
                class="row justify-content-between bd-b pd-t-10"
              >
                <label class="form-control-label tx-primary"
                  >{{ emailOrFaxLabel }}
                </label>
                <p class="form-control-label text-break mg-b-10">
                  {{ emailOrFaxValue }}
                </p>
              </div>
              <!-- <div
                *ngIf="claim.billingMethod == 'Fax'"
                class="row justify-content-between bd-b pd-t-10"
              >
                <label class="form-control-label tx-primary"
                  >Billing contact fax
                </label>
                <p class="form-control-label text-break mg-b-10">
                  {{ claim.fax }}
                </p>
              </div> -->
            </div>
          </div>
          <!-- <div class="col-12 pd-0">
            <div class="card pd-20">
              <div class="row">
                <label class="form-control-label col-4"
                  >Repair order #: {{ claim.repairOrderNumber }}
                </label>
                <label class="form-control-label col-4"
                  >Loss date: {{ claim.lossDate | date }}</label
                >
                <label class="form-control-label col-4"
                  >Loss Odomoeter:
                  {{ claim.lossOdometer | number: "1.2-2" }}</label
                >
              </div>
              <div class="row">
                <label class="form-control-label col-4"
                  >Was car towed in?: {{ claim.isCarTowed ? "Yes" : "No" }}
                  {{
                    claim.isCarTowed && !attachments
                      ? "Please attach applicable tow bill if requesting reimbursement"
                      : ""
                  }}
                </label>
                <label class="form-control-label col-4"
                  >Any modification?:
                  {{ claim.hasModifications ? "Yes" : "No" }}
                  {{ claim.hasModifications ? claim.modificationsDesc : "" }}
                </label>
                <label class="form-control-label col-4"
                  >Any commercial use?:
                  {{ claim.hasCommercialUse ? "Yes" : "No" }}
                  {{ claim.hasCommercialUse ? claim.commercialUseDesc : "" }}
                </label>
              </div>
              <div class="row">
                <label class="form-control-label col-4"
                  >Labor biling rate: {{ claim.laborBillingRate }}
                </label>
                <label class="form-control-label col-4"
                  >Biliing method: {{ claim.billingMethod }}
                </label>
                <label
                  class="form-control-label col-4"
                  *ngIf="claim.billingMethod == 'Email'"
                  >Billing contact email: {{ claim.email }}
                </label>
                <label
                  class="form-control-label col-4"
                  *ngIf="claim.billingMethod == 'Fax'"
                  >Billing contact fax: {{ claim.fax }}
                </label>
              </div>
            </div>
          </div> -->
        </div>
        <ng-template #notesInfo>
          <div class="row pd-x-20 tx-black pd-b-0 justify-content-center notes-info-tooltip" style="background-color: #f8f9fa; margin-top: -8px; border-top: 1px solid black;">
            <span class="tx-14 mg-b-0 tx-uppercase tx-bold" style="width: 200px; ">
              Notes
            </span> 
            
          </div>
          <div class="row tx-black pd-t-10 bd-0 notes-info-tooltip justify-content-center" style="background-color: white; margin-bottom:-8px; border-top: 1px solid black;">
           Notes are the primary way of communicating with your Zurich Adjuster.
           
          </div>
          <div class="row tx-black pd-t-10 bd-0 notes-info-tooltip justify-content-center" style="background-color: white; margin-bottom:-8px;">
            Notes can be used to answer questions, provide additional details, or request changes/updates to claims.
            
           </div>
           <div class="row tx-black pd-t-10 bd-0 notes-info-tooltip justify-content-center" style="background-color: white; margin-bottom:-8px;">
            Notes entered before submission will be reviewed by an adjuster when the claim is submitted.
            
           </div>
           <div class="row tx-black pd-t-10 bd-0 notes-info-tooltip justify-content-center" style="background-color: white; margin-bottom:-8px; border-bottom: 1px solid black;">
            Notes entered after the claim has been submitted will alert an adjuster that there are additional items to review.
            
           </div>
        </ng-template>
        <div class="slim-mainpanel justify-content-around">
          <div
            class="container-fluid justify-content-around mg-l-0 mg-r-0container-fluid justify-content-around mg-l-0 mg-r-0"
          >
            <div class="row justify-content-around mg-b-50">
              <div id="notesSection" #notesSection class="col-12 pd-0">
                <div class="card pd-20">
                  <form
                    fxLayout="column"
                    [formGroup]="noteForm"
                    (ngSubmit)="submitNoteForm()"
                  >
                  <div class="row pd-b-10">
                    <div class="col-xl-1 col-sm-12 align-self-center pd-r-0">
                      <h4 class="tx-primary">Notes <i class="fa fa-info-circle zurich-blue" style="font-size: 16px; cursor: pointer;" placement="auto" triggers="click" [ngbTooltip]="notesInfo"></i></h4>
                    </div>
                    <div class="col-xl-10 col-sm-12 pd-l-0">
                      
                        <textarea
                        (keyup.enter)="submitNoteForm()"
                        
                          class="form-control "
                          rows="1"
                          placeholder="Type here..."
                          [formControlName]="'noteText'"
                          [ngClass]="{
                            'is-invalid': submittedNote && fNote.noteText.errors
                          }"
                        ></textarea>
                        
                     
                    </div>
                    <div class=" align-self-center pd-l-0" style="text-align: end;">
                      <div class="row mg-l-1">
                    <i placement="top" ngbTooltip="Add Note" style="cursor: pointer;" (click)="submitNoteForm()" class="fa fa-2x far tx-primary fa-send-o"></i>
                  
                    <i placement="top" (click)="resetNoteForm()" style="cursor: pointer;" ngbTooltip="Clear" class="fa fa-2x mg-l-5 mg-r-5 far tx-primary fa-times-circle"></i>
                    <div *ngIf="addNotePressed" class="spinner-border align-self-center" style="width: 2rem; height: 2rem;" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                  </div>
                  </div>
                </form>
                  <ul
                    class="list-group list-group-flush"
                    style="
                      overflow: auto !important;
                      max-height: 350px;
                      border-top: 0 !important;
                      overflow-x: hidden !important;
                    "
                  >
                    <li
                      *ngFor="let note of noteList; let i = index"
                      class="row"
                    >
                      <div *ngIf="note?.noteTypeDescription != 'X-COMMUNICATION'" class="card bd-0 mg-b-10 pd-x-20 col-12">
                        <div class="col-12">
                          <p class="timeline-title">{{ note?.author}}</p>
                          <p class="timeline-author">
                            {{ adjustDate(note?.sysDateI) }}
                          &bull;
                          <span
                          *ngIf="!disabledField"
                          class=" col-2 font-weight-bold txt"
                          (click)="deleteNote(note)"
                        >
                        
                         Delete Note
                    </span>
                           
                          </p>
                          <p class="timeline-text">
                            {{ note?.text }}
                          </p>
                        </div>
                        
                      </div>
                      <div *ngIf="note?.noteTypeDescription == 'X-COMMUNICATION'" class="card bd-0 mg-b-20 pd-x-20 col-12">
                        <div class="col-12" style="text-align: end;">
                          <p class="timeline-title"><img
                            src="./assets/slim/img/zurich-logo.jpg"
                            class="wd-100 h-50"
                            alt=""
                        /></p>
                          <p class="timeline-author">
                            {{ note?.sysDateI | date :'short' }}
                          </p>
                          <p class="timeline-text">
                            {{ note?.text }}
                          </p>
                        </div>
                        <!-- <div class="col-3">
                          <button
                            class="btn btn-oblong col-2 btn-light btn-sm tx-dark font-weight-bold"
                            (click)="deleteNote(note)"
                          >
                            <i class="ion-trash-a"></i>
                          </button>
                        </div> -->
                      </div>
                    </li>
                  </ul>

                  <!-- timeline-group -->
                </div>
                <!-- card -->
                <!-- <div class="card card-quick-post pd-10 bd-t-0">
                  <h6 class="tx-primary">Add new note</h6>
                  <form [formGroup]="noteForm" (ngSubmit)="submitNoteForm()">
                    <div class="form-group">
                      <textarea
                        (keyup.enter)="submitNoteForm()"
                        class="form-control"
                        rows="3"
                        placeholder="Type here..."
                        [formControlName]="'noteText'"
                      ></textarea>
                    </div>
                    form-group 
                    <div class="card-footer justify-content-start">
                      <i placement="top" ngbTooltip="Add Note" (click)="submitNoteForm()" class="fa fa-2x far tx-primary fa-send-o mg-r-10"></i>
                      <i placement="top" (click)="resetNoteForm()" ngbTooltip="Clear" class="fa fa-2x far tx-primary fa-times-circle mg-r-10"></i>
                       <button
                        class="btn-medium btn-primary btn-oblong col-xs-2"
                        type="submit"
                      >
                        Add
                      </button> 
                    </div>
                  </form>
                  
                   card-footer 
                </div> -->
                <!-- card -->
              </div>
              <!-- col-9 -->
            </div>
            <!-- row -->
            <div id="attachmentsSection" class="row justify-content-around mg-b-20">
              <div class="col-12 pd-0">
                <div class="card pd-20 col-12">
                  <div class="row justify-content-between">
                 
                    <h3 class="tx-primary pd-l-20">Attachments  <i class="fa fa-info-circle zurich-blue" style="font-size: 16px; cursor: pointer;" placement="auto" triggers="click" [ngbTooltip]="attachmentTypes"></i></h3>
                 
                  
                    <button
                    *ngIf="claim.step == 3 && disabledField"
                    class="btn btn-oblong btn-primary btn btn-block chevron-button"
                    data-toggle="modal"
                    data-target="#paymentRequest" 
                    (click)="setType('CLAIM', 2);resetPaymentRequest(); "
                  >
                    Add
                  </button> 
                
                </div>
                <label
                *ngIf="claim?.statusCode != 40 && claim?.statusCode != 90 && claim.statusCode != 60 && claim.statusCode != 80"
                class="col-9 control-label"
              >
                Please provide any attachments that will support your claim request
              </label>
                  <label
                  *ngIf="claim?.statusCode == 40 || claim?.statusCode == 90 || claim.statusCode == 60 || claim.statusCode == 80"
                  class="col-9 control-label" style="color:red;"
                >
                These attachments are for Claim Support/Authorization only. Items added here will return to the
                Claims team for review. 
                </label>
                <label
                *ngIf="claim?.statusCode == 40 || claim?.statusCode == 90 || claim.statusCode == 60 || claim.statusCode == 80"
                class="col-9 control-label" style="color:red;"
              >
              Payment Documentation should be provided using the “Request Payment” button above.
              Requesting payment here could cause delays.
              </label>
                <!-- <span class="tx-bold pd-l-15">Accepted document types are:<span class="tx-normal pd-l-15">.bmp, .csv, .doc, .docx, .gif, .html, .ico, .jng, .jpeg, .jpg, .pdf, .ppt, .pptx, .rar, .rtf, .tif, .tiff, .txt, .xls, .xlsx, .xml, .zip</span></span>  -->
               
                  <label
                    *ngIf="claim.isCarTowed && claim.step != 3"
                    class="col-9 control-label"
                  >
                    Based on your selections, the following items are required prior to payment: Roadside/Tow Invoice Sublet Invoice Rental Agreement 
                  </label>
                  <label
                    *ngIf="claim.hasCommercialUse && claim.step != 3"
                    class="col-9 control-label"
                  >
                    Photos of the following items may help expedite the handling of your claim: Commercial Use Vehicle Modifications
                  </label>
                  <div  *ngIf="claim.step != 3 && !disabledField" class="row pd-b-10 pd-t-10 justify-content-center">
                    <ngx-dropzone (change)="onSelectFiles($event)">
  
                      <ngx-dropzone-label>
                        <i placement="top" ngbTooltip="Add Attachment" class="fa fa-2x far tx-primary fa-upload mg-r-10"></i>
                        <br>
                        Drag and drop Files Here to Upload <br>
                        or Click to Select Files
                        </ngx-dropzone-label>
                    
                      <ngx-dropzone-preview *ngFor="let f of files; let i = index" [removable]="true" (removed)="onRemove(i)">
                    
                        <ngx-dropzone-label>{{ f.name }}</ngx-dropzone-label>
                    
                      </ngx-dropzone-preview>
                    
                    </ngx-dropzone>
                 
                </div>
                <!-- <div class="row justify-content-center ">
                  <button
                  type="button"
                  class="btn mg-r-30 btn-oblong btn-secondary btn"
                  data-dismiss="modal"
                  (click)="removeFiles()"
                >
                  Remove all
                </button>
                
                <button (click)="uploadFiles()" class="btn btn-oblong btn-primary btn">
                  Upload
                </button>
                </div> -->
                  <!-- <div class="custom-file pd-b-90">
                  <h6
                    class="custom-file-label custom-file-label-primary"
                    for="customFile"
                  >
                    Choose file
                  </h6>
                </div> -->
                <div class="row">
                <div class="col-6">
                  <label class="col-12 tx-primary h6 control-label"> Claim Support Attachments </label>

                  <ul class="list-group list-group-flush" style="
                  overflow: auto !important;
                  max-height: 150px;
                  border-top: 0 !important;
                  overflow-x: hidden !important;
                "
     
                >
                    <li
                      *ngFor="let attachment of claimSupportAttachments; let i = index"
                      class="row mg-5 justify-content-between"
                     
                    >
                      <div class="ml-3">{{ attachment.filename }}</div>
                      <div>
                      <button
                        class="btn btn-oblong btn-light btn-sm tx-dark font-weight-bold"
                        (click)="deleteAttachment(attachment)"
                        type="button"
                        [disabled]="disabledField"
                      >
                        <i class="ion-trash-a col-12"></i>
                      </button>
                      <button
                        class="btn btn-oblong btn-light btn-sm tx-dark font-weight-bold"
                        (click)="downloadAttachment(attachment)"
                        type="button"
                        
                      >
                        <i class="fa fa-download col-12"></i>
                      </button>
                    </div>
                    </li>
                  </ul>
                </div>
                  <div *ngIf="claim.step == 3 && disabledField && (claim?.statusCode == 40 || claim?.statusCode == 50 || claim?.statusCode == 90 || claim.statusCode == 60 || claim.statusCode == 80)" class="col-6 pl-0">
                  <label class="col-12 tx-primary h6 control-label"> Request Payment Attachments </label>

                  <ul  class="list-group list-group-flush" style="
                  overflow: auto !important;
                  max-height: 150px;
                  border-top: 0 !important;
                  overflow-x: hidden !important;
                "
     
                >
                    <li
                      *ngFor="let attachment of requestPaymentAttachments; let i = index"
                      class="row mg-5 justify-content-between"
                     
                    >
                      <div class="ml-3">{{ attachment.filename }}</div>
                      <div>
                      <button
                        class="btn btn-oblong btn-light btn-sm tx-dark font-weight-bold"
                        (click)="deleteAttachment(attachment)"
                        type="button"
                        [disabled]="disabledField"
                      >
                        <i class="ion-trash-a col-12"></i>
                      </button>
                      <button
                        class="btn btn-oblong btn-light btn-sm tx-dark font-weight-bold "
                        (click)="downloadAttachment(attachment)"
                        type="button"
                        
                      >
                        <i class="fa fa-download col-12"></i>
                      </button>
                    </div>
                    </li>
                  </ul>
                </div>
              </div>
                </div>
              </div>
            </div>
            <div class="row justify-content-between">
              <div class="row justify-content-start-custom">
              <button
              type="button"
              class="btn btn-oblong btn-info btn btn-block mg-b-10 chevron-button mg-r-30"
              (click)="setClaimDelete()"
              data-toggle="modal"
              attr.data-target="#confirmActionModal{{indexNum}}"
              id="deleteClaimButtonFromClaimInfo"
              [disabled]="disabledField"
            >
              Delete
            </button>
          </div>
            
            <div >
              <div class="row justify-content-end-custom">
                <button
                  (click)="setHome()"
                  class="btn btn-oblong mg-b-10 btn-secondary btn btn-block chevron-button"
                >
                  Return to Home page
                </button>
                <button
                *ngIf="claim.step != 3 && !disabledField && mechanicalUnder1500"
                class="btn btn-oblong btn-primary mg-b-10 mg-xs-l-30 mg-sm-l-30 mg-md-l-30  mg-lg-l-30 mg-xl-l-30 mg-xl-r-30 btn btn-block chevron-button"
                style="margin-top: 0px !important"
                (click)="resetMechanicalClaimSubmit()"
                data-toggle="modal"
                data-target="#confirmSubmissionForMechanicalClaim"
              >
                Submit claim
              </button>
                <button
                  *ngIf="claim.step != 3 && !disabledField && !mechanicalUnder1500"
                  (click)="submitClaim()"
                  class="btn btn-oblong btn-primary mg-b-10 mg-xs-l-30 mg-sm-l-30 mg-md-l-30  mg-lg-l-30 mg-xl-l-30 mg-xl-r-30 btn btn-block chevron-button"
                  style="margin-top: 0px !important"
                  data-toggle="modal"
                  attr.data-target="#confirmActionModal{{indexNum + 1}}"
                >
                  Submit claim
                </button>
                <app-confirm-action 
                  style="max-height: 0px;"
                  [header]="header"  
                  [message]="message"
                  [buttonMessage]="buttonMessage"    
                  [indexNum]="indexNum +1"
                  (confirmActionEvent)="updateStep($event)"
              ></app-confirm-action>
                <div  *ngIf="claimSubmitted" class="spinner-border row align-self-center" style="width: 2rem; height: 2rem;" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                </div>
            </div>
            </div>
            <app-confirm-action 
                [header]="header"  
                [message]="message"
                [buttonMessage]="buttonMessage"    
                [indexNum]="indexNum"
                (confirmActionEvent)="deleteClaim($event)"
            ></app-confirm-action>
          </div>
          <!-- container -->
        </div>
        <!-- slim-mainpanel -->
      </div>
    </div>
  </div>
  <ng-template #attachmentTypes>
    <div class="row pd-x-20 tx-black pd-b-0" style="
    border-right: 1px solid black; 
    border-left: 1px solid black;
    border-top: 1px solid black; background-color: #f8f9fa; margin-top: -8px; width: 270px;">
      <span class="tx-14 mg-b-0 tx-uppercase tx-bold" style="width: 270px;">
        Accepted document types are:
      </span> 
    </div>
    <div class="row pd-t-10 bd-0" style="background-color: white; margin-bottom:-8px; width: 270px; 
    border-right: 1px solid black; 
    border-left: 1px solid black;
    border-bottom: 1px solid black;">
      <p class="col-4 tx-black">.bmp <br>.csv <br>.doc <br>.docx <br>.gif<br> .html <br>.ico<br>.jpeg</p>
      <p class="col-4 tx-black">.txt<br>.jpg<br> .pdf<br> .png <br>.ppt <br>.pptx <br>.rar<br> .rtf </p>
      <p class="col-4 tx-black">.tif<br>.tiff <br>.xls <br>.xlsx <br>.xml<br> .zip</p>
    </div>
  </ng-template>
</div>
<ng-template #requestPayment>
  <div class="row pd-x-20 tx-black pd-b-10" style="background-color: #f8f9fa; margin-top: -8px; width: 200px;">
    <span class="tx-14 mg-b-0 tx-uppercase tx-bold" style="width: 200px;">
      Request Payment
    </span> 
    
  </div>
  <div class="row tx-black pd-t-10 bd-0" style="background-color: white; margin-bottom:-8px; width: 200px;">
    Click authorized claims to submit your completed RO for payment
   
  </div>
</ng-template>
<ng-template #addingAttachments>
  <div class="row pd-x-20 tx-black pd-b-10" style="background-color: #f8f9fa; margin-top: -8px; width: 200px;">
    <span class="tx-14 mg-b-0 tx-uppercase tx-bold" style="width: 200px;">
      Adding attachments
    </span> 
    
  </div>
  <div class="row tx-black pd-t-10 bd-0" style="background-color: white; margin-bottom:-8px; width: 200px;">
   Your attachments and note will be sent at one time.
   
  </div>
</ng-template>
<div id="paymentRequest" class="modal">
  <div  class="modal-dialog modal-lg modal-width-payment" role="document">
    <form
      fxLayout="column"
      [formGroup]="paymentRequestForm"
      (ngSubmit)="submitPaymentRequestForm()"
    >
      <div class="modal-content tx-size-sm">
        <div class="modal-header pd-x-20 pd-b-30">
          <h6 class="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
            {{popUpTitle}} <i *ngIf="typeNum == 1" class="fa fa-info-circle zurich-blue" style="font-size: 1.35em;" placement="auto" triggers="click" [ngbTooltip]="requestPayment"></i>
            <i *ngIf="typeNum == 2" class="fa fa-info-circle zurich-blue" placement="auto" triggers="click" [ngbTooltip]="addingAttachments"></i>
          </h6>
          <button
          type="button"
          class="close"
          aria-label="Close"
          id="paymentRequestModalButton"
          data-dismiss="modal"
          (click) = "paymentRequestClose()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div class="row">
          <div class="col-md">
            <div class="card">
              <div class="card-body">
                <div class="row justify-content-around">
                  <div>
                    <h4 class="tx-primary">{{popUpText}}</h4>
                  </div>
                </div>

                <!-- tab-content -->
                <div class="row justify-content-center pd-b-20">
                  <ngx-dropzone (change)="onSelectFilesPayment($event)">

                    <ngx-dropzone-label>
                      <i placement="top" ngbTooltip="Add Attachment" class="fa fa-2x far tx-primary fa-upload mg-r-10"></i>
                      <br>
                      Drag and drop Files Here to Upload <br>
                        or Click to Select Files
                      </ngx-dropzone-label>
                  
                    <ngx-dropzone-preview *ngFor="let f of filesPayment; let i = index" [removable]="true" (removed)="onRemovePaymentFiles(i)">
                  
                      <ngx-dropzone-label>{{ f.name }}</ngx-dropzone-label>
                  
                    </ngx-dropzone-preview>
                  
                  </ngx-dropzone>
                <!-- tab-content -->
              </div>
              <div class="row mg-b-20 justify-content-around">
                 <div *ngIf="typeNum == 2" class="align-self-center tx-primary">Notes</div>   
                <textarea                       
                  class="form-control col-10"
                  rows="2"
                  placeholder="Type here..."
                  [formControlName]="'paymentRequestText'"
                  [ngClass]="{
                    'is-invalid': paymentRequestSubmitted && fPaymentRequest.paymentRequestText.errors
                  }"
                ></textarea>
                
             
          </div>
              <div class="row justify-content-end">
                <div class="pd-l-0 pd-r-0 justify-content-end">
                  <button type="submit" class="btn btn-oblong btn-primary btn">
                    Submit
                  </button>                  
                  <div *ngIf="paymentRequesting" class="spinner-border " style="width: 2rem; height: 2rem; vertical-align: middle;" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
                
              </div>
              <div *ngIf="typeNum == 1" class="row">
                <label class="col-12 pd-t-20 h6 tx-primary control-label"> Request Payment Attachments </label>

                  <ul class="list-group col-12 list-group-flush" style="
                  overflow: auto !important;
                  max-height: 150px;
                  border-top: 0 !important;
                  overflow-x: hidden !important;
                "
     
                >
                    <li
                      *ngFor="let attachment of requestPaymentAttachments; let i = index"
                      class="row mg-5 justify-content-between"
                     
                    >
                      <div class="ml-3">{{ attachment.filename }}</div>
                      <div>
                      <button
                        class="btn btn-oblong btn-light btn-sm tx-dark font-weight-bold"
                        (click)="deleteAttachment(attachment)"
                        type="button"
                        [disabled]="disabledField"
                      >
                        <i class="ion-trash-a col-12"></i>
                      </button>
                      <button
                        class="btn btn-oblong btn-light btn-sm tx-dark font-weight-bold"
                        (click)="downloadAttachment(attachment)"
                        type="button"
                        
                      >
                        <i class="fa fa-download col-12"></i>
                      </button>
                      </div>
                    </li>
                  </ul>
              </div>
              </div>
              <!-- card-body -->
            </div>
            <!-- card -->
          </div>
        </div>
        <!-- row -->

      </div>
    </form>
  </div>
  <!-- modal-dialog -->
</div>

<div #confirmSubmissionForMechanicalClaim id="confirmSubmissionForMechanicalClaim" class="modal">
    <div class="modal-dialog modal-lg modal-width-payment" role="document">
      <form
        fxLayout="column"
        [formGroup]="submissionForMechanicalClaim"
        (ngSubmit)="mechanicalClaimSubmit()"
      >
        <div class="modal-content tx-size-sm">
          <div class="modal-header pd-x-20">
            <h6 class="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
             Submit Claim
            </h6>
          </div>
          <div class="row">
            <div class="col-md">
              <div class="card">
                <div class="card-body">
                
                  <div class="row">
                    <div class="col-12">
                      <label class="pd-b-10">To expedite your claim, you may choose to self-authorize mechanical claims under ${{mechanicalSelfAuthMax}}. By selecting to Self Authorize below, you are confirming the repair is covered by the vehicle owner's contract, parts are billed at MSRP and labor time from a nationally recognized labor manual and all other terms and conditions of the contract have been
                        followed.</label>
                        <div class="col-12 pd-l-0 pd-b-3" [ngClass]="{
                            'red-box': submittedMechanicalClaim && fSubmissionForMechanicalClaim.reviewType.errors
                          }">
                            <div class="form-check">
                                <input [formControlName]="'reviewType'" value="selfAuthorized" name='reviewType' class="form-check-input" type="radio" id="exampleRadios1">
                                <label class="form-check-label" for="exampleRadios1">
                                    Yes, I'd like to Self Authorize this claim.
                                </label>
                            </div>
                            <div class="form-check">
                                <input [formControlName]="'reviewType'" value="manualReview" name='reviewType' class="form-check-input" type="radio" id="exampleRadios2">
                                <label class="form-check-label" for="exampleRadios2" id="exampleRadioLabel2">
                                    Please have an adjuster review this claim. 
                                </label>
                            </div>
                        </div>
                        <label class="pd-t-15">Submission of this claim is an acknowledgement that the charges being submitted are accurate and appropriate. Reimbursement is solely based on the terms and conditions
                            of the Vehicle Service Contract. Only authorized charges will be paid.</label>
                  </div>
                  </div>
                  <!-- tab-content -->
                </div>
                <!-- card-body -->
              </div>
              <!-- card -->
            </div>
          </div>
          <!-- row -->
          <!-- modal-body -->
          <div class="modal-footer">
            <div class="row">
                <div *ngIf="fSubmissionForMechanicalClaim.reviewType?.value == 'selfAuthorized' " class="col tx-danger">
                    After submission, please review the authorization terms including deductible amount on the Claim Details page.
                    Once the work is completed, please use “Request Payment” to submit the Repair Order for payment.
                </div>
                <div class="d-flex justify-content-end align-self-center">
                    <button
                    id="cancelMechanicalClaimModal"
                    type="button"
                    style="max-height: 42px;"
                    class="btn btn-oblong btn-secondary btn mg-r-4"
                    data-dismiss="modal"
                    (click)="resetMechanicalClaimSubmit()"
                    >
                    Cancel
                    </button>
                    <button type="submit" style="max-height: 42px;" class="btn mg-r-4 btn-oblong btn-primary btn">
                    Submit
                    </button>
                </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <!-- modal-dialog -->
  </div>
  <!-- modal -->