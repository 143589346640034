<div>
  <div class="card card-body bg-grandeur tx-white bd-0">
    <div class="media">
      <div class="media-body">
        <h3 class="card-title tx-white card-title-color">
          Enter VIN & Last Name
        </h3>
        <p>Enter the 17 character VIN</p>
        <div >
          <form class="form-row" [formGroup]="searchContractForm" (ngSubmit)="onClickSearch()">
            <div class=" col-md-6 mg-r-10">
            <input
              formControlName="vinNumber"
              id="vinNumber"
              type="text"
              class="form-control col-md-12 mg-r-10"
              placeholder="Enter the 17 character VIN"
            />
            <div class="tx-danger" *ngIf="searchContractForm.controls['vinNumber'].invalid && isInValidFormSubmitted">Enter the 17 character VIN</div>
          </div>
          <div  class=" col-md-4 mg-r-15">
            <div class="row flex-nowrap" >
            <input
              #lastName
              type="text"
              class="form-control col-md-10 mg-r-15"
              id="formGroupLastName"
              placeholder="Enter the Customer's Last Name"
            />
            <button type="submit" class="btn btn-oblong btn-primary">
              Search
            </button>
          </div>
            </div>
          </form>
        </div>
      </div>
      <!-- media-body -->
    </div>
    <!-- media -->
  </div>
</div>
