import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateNewClaimComponent } from './new-claim/create-new-claim/create-new-claim.component';
import { HomeComponent } from './home/home.component';
import { ClaimInformationComponent } from './new-claim/claim-information/claim-information.component';
import { ClaimComplaintsComponent } from './new-claim/claim-complaints/claim-complaints.component';
import { AppLayoutComponent } from './app-layout/app-layout.component';
import { ClaimDetailsComponent } from './new-claim/claim-details/claim-details.component';
import { ContractInformationComponent } from './new-claim/contract-information/contract-information.component';
//import { SearchContractResultsComponent } from './new-claim/search-contract-results/search-contract-results.component';
import { ClaimListComponent } from './claim-list/claim-list.component';
import { DraftClaimListComponent } from './draft-claim-list/draft-claim-list.component';
import { ClaimSubmitComponent } from './new-claim/claim-submit/claim-submit.component';
import { SearchContractResultsComponent } from './search-contract-results/search-contract-results.component';
import { OktaCallbackComponent, OktaAuthGuard } from '@okta/okta-angular';
import { environment } from 'src/environments/environment';
const routes: Routes = [
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      { path: '', component: HomeComponent },
      {
        path: 'new-claim',
        component: CreateNewClaimComponent,
        canActivate:environment.isOktaEnabled?[OktaAuthGuard]:[]
      },
      {
        path: 'claim-information',
        component: ClaimInformationComponent,
        canActivate:environment.isOktaEnabled?[OktaAuthGuard]:[]
      },
      {
        path: 'claim-complaints',
        component: ClaimComplaintsComponent,
        canActivate:environment.isOktaEnabled?[OktaAuthGuard]:[]
      },
      {
        path: 'claim-details',
        component: ClaimDetailsComponent,
        canActivate:environment.isOktaEnabled?[OktaAuthGuard]:[]
      },
      {
        path: 'contract-information',
        component: ContractInformationComponent,
        canActivate:environment.isOktaEnabled?[OktaAuthGuard]:[]
      },
      {
        path: 'claims',
        component: ClaimListComponent,
        canActivate:environment.isOktaEnabled?[OktaAuthGuard]:[]
      },
      {
        path: 'drafts',
        component: DraftClaimListComponent,
        canActivate:environment.isOktaEnabled?[OktaAuthGuard]:[]
      },
      {
        path: 'submit',
        component: ClaimSubmitComponent,
        canActivate:environment.isOktaEnabled?[OktaAuthGuard]:[]
      },
      {
        path: 'search-contract-results',
        component: SearchContractResultsComponent,
        canActivate:environment.isOktaEnabled?[OktaAuthGuard]:[]
      },
      {
        path: 'login/callback',
        component: OktaCallbackComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
