//const CLIENT_ID = '0oatw862qtJlPHJ365d6';
//const ISSUER = 'https://dev-35284006.okta.com/oauth2/default';
// const ISSUER = 'https://qalogin.zurichna.com/oauth2/aus101fy9q40SE6DT0h8';
// const CLIENT_ID = '0oa101frwaeMbOZnl0h8';
// const ISSUER = 'https://qalogin.zurichna.com/oauth2/default';
import { environment } from "src/environments/environment";
//const ISSUER = 'https://qalogin.zurichna.com/oauth2/aus101fy9q40SE6DT0h8';
//const CLIENT_ID = '0oa10r93e3p50Drm30h8';

const OKTA_TESTING_DISABLEHTTPSCHECK = undefined;
export default {
  oidc: {
    clientId: `${environment.CLIENT_ID}`,
    issuer: `${environment.ISSUER}`,
    redirectUri: '/login/callback',
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    testing: {
      disableHttpsCheck: `${OKTA_TESTING_DISABLEHTTPSCHECK}`,
    },
  },
  resourceServer: {
    messagesUrl: 'http://localhost:8000/api/messages',
  },
};
