import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { interval, Subscription } from 'rxjs';
import { Claim } from '../_model/claim';
import { ClaimService } from '../_services/claim.service';
import { ContractService } from '../_services/contract.service';

@Component({
  selector: 'app-claim-list',
  templateUrl: './claim-list.component.html',
  styleUrls: ['./claim-list.component.css'],
})
export class ClaimListComponent implements OnInit, OnDestroy{
  showDetails1: boolean = false;
  showDetails2: boolean = false;
  showDetails3: boolean = false;
  pageLoading:boolean = true;
  claimList: any = [];
  customerInformation1: string = 'customerInformation1';
  vehicleInformation1: string = 'vehicleInformation1';
  contractInformation1: string = 'contractInformation1';
  customerInformation2: string = 'customerInformation2';
  vehicleInformation2: string = 'vehicleInformation2';
  contractInformation2: string = 'contractInformation2';
  claimListShow: boolean[] = [];
  indexNum=0;
  hideButton:boolean=true;
  filterForm:FormGroup;
  statuses:any[] = [];
  filterFormSubmitted:boolean=false;
  filterLoading:boolean = false;
  activeClaim:boolean = true;

  allPressed:boolean=false;
  requestedPressed:boolean=false;
  authorizedPressed:boolean=false;
  requestedStatuses:number[]=[];
  authorizedStatuses:number[]=[];
  claimListHelper: any = [];
  page:number = 1;
  pageSize:number = 20;
  totalSize:number = 0;
  tmpPage = this.page - 1;
  tmpPageParam = "&page=" + this.tmpPage;
  currentSortField: string = '';
  sortTableIndicator: number = 0;
  sortQuery: string ="";
  parametersActive: string = "?v=ACTIVE&accountNumber="+this.claimService.accountNumber;
  sortFieldAndDirection: string = "default";
  numberOfClaimsWithHiddenClaimNumber: number= 0;
  filterByClaimNumber: boolean = false;
  statusUpdateSubscription: Subscription;

  constructor(private claimService: ClaimService,
    private contractService: ContractService,
    private router: Router,
    private readonly fb: FormBuilder,
    private toastr: ToastrService) {
      this.filterForm = this.fb.group({
        claimNumber: [],
        roNumber: [],
        vehicleInfo:[],
        claimStatus: [],
        customerName: [],
        createdDateFrom:[],
        createdDateTo:[],
        userName:[],
        amount:[,  Validators.pattern("^[-]?[0-9]*$")      ]
      });
    }

  ngOnInit(): void {
    this.toastr.clear();
    this.parametersActive = "?v=ACTIVE&accountNumber="+this.claimService.accountNumber;
    setTimeout(()=>this.claimService.getClaimsCount("?v=ACTIVE&accountNumber="+this.claimService.accountNumber).subscribe((value:any)=>{
      this.totalSize = value;
      }, (error)=>{
            console.log(error);
            this.toastr.error('Something went wrong while fetching number of active claims, please try again. ' + 'Error code: '+error.status, 'Active Claims', {
              positionClass: 'toast-top-center', timeOut:12000
            });
            
      }
     ),3000);
     
   //emit value in sequence every 5 second
   const source = interval(5000);
   this.statusUpdateSubscription = source.subscribe(val => this.getUpdatedClaims());

    this.onPageChange(this.page);
    this.claimService.getStatusList().subscribe(
    (res:any) => {
      let j=0;
      let k=0;
      
      for(let i =0; i<res?.length;i++){
        if(res[i].name?.toLowerCase().startsWith("Claim under review".toLowerCase())){
          this.requestedStatuses[j]=res[i].code;
          j++;
        }
        if(res[i].name.toLowerCase() == "Denied Claim".toLowerCase() || res[i].name.toLowerCase() == "Authorized".toLowerCase() || res[i].name.toLowerCase() == "Payment issued".toLowerCase()){
          this.authorizedStatuses[k]=res[i].code;
          k++;
        }
        if(res[i].code != 10 && res[i].code != 0 && res[i].code != 70){
          this.statuses.push(res[i]);
        }
      }
      },
      (error) => {
        console.log(error);
        this.toastr.error('Something went wrong while fetching statuses, you may experience problems with filter. ' + 'Error code: '+error.status, 'Active Claims', {
          positionClass: 'toast-top-center', timeOut:6000
        });
      }
    );
    this.allPressed=true;
  }

  getUpdatedClaims(){
    this.claimService.getEventLog(10).subscribe((result:any)=>{
      if(result?.length > 0){

        if(this.sortTableIndicator == 0){
          this.onPageChange(this.page, true);
        } else {
          --this.sortTableIndicator;
          if(this.sortTableIndicator < 0){
            this.sortTableIndicator = 0;
          }
          this.sort(this.currentSortField, true);
        }
        // for(let i = 0; i < result?.length; i++){
        //   if(result[i]?.claimId){
        //     for(let k = 0; k < this.claimList?.length; k++){
        //       if(result[i].claimId == this.claimList[k].id){
        //         this.claimList[k].statusCode = result[i].claimStatusCode;
        //         this.claimList[k].status = result[i].claimStatus;
        //       }
        //     }
        //   }
        // }
      }
    },
    (error) => {
      console.log(error);
      if (error?.status && error.status == 401) {
        this.statusUpdateSubscription.unsubscribe();
      }
    });
  }

  ngOnDestroy() {
    this.statusUpdateSubscription.unsubscribe();
  }

  previewDetails1(contractNumber,i) {
    for(let j=0; j<this.claimList.length; j++){
        if(i != j){
              this.claimListShow[j]=false;
        }
      }
    this.showDetails1 = !this.showDetails1;
    this.claimListShow[i]=!this.claimListShow[i];
    this.claimService.claim.next(this.claimList[i]);
    if (this.claimListShow[i]) {
      this.contractService.contractIndex.next(i);
      this.contractService.contractId.next(contractNumber);
      this.setContract(this.claimList[i], false);
    }
  }
  setContract(claim, goToNewClaim){
    let contractInfo = {
      lastName: claim.lastName,
      vin: claim.vin,
      contractNumber: claim.contractNumber
    };
 
  if(goToNewClaim){
         this.claimService.currentUrl.next("NONE");
         this.router.navigate(["/new-claim"]);  
       }
  }
  continueToDetails(claim:Claim){
    this.claimService.claim.next(claim);
    this.claimService.currentUrl.next("NONE");
    this.claimService.previewActiveClaim = true;
    this.router.navigate(["/submit"]);
  }
  continueToStepper(claim:Claim){
    this.claimService.editClaim = true;
    this.claimService.previewActiveClaim = true;
    this.claimService.startClaimFromSearch = false;
    this.claimService.claim.next(claim);
    if (claim.jobs)
      this.claimService.job.next(claim.jobs[0]);
    this.claimService.currentStep = 3;
    this.claimService.currentUrl.next("NONE");
    this.router.navigate(['/new-claim']);
  }
  previewDetails2() {
    this.showDetails2 = !this.showDetails2;
  }
  previewDetails3() {
    this.showDetails3 = !this.showDetails3;
  }
  submitFilterForm(){
    this.filterFormSubmitted=true;
    this.allPressed=false;
    this.requestedPressed=false;
    this.authorizedPressed=false;

    if(!this.filterForm.valid){
      return;
    }
    this.numberOfClaimsWithHiddenClaimNumber = 0;
    this.filterByClaimNumber = false;
    let parameters:string="?";
    
    if(this.filterForm.controls.claimNumber.value){
      parameters+="claimNumber_like="+this.filterForm.controls.claimNumber.value;
      this.filterByClaimNumber = true;
    }
    if(this.filterForm.controls.roNumber.value){
      if(parameters.length>1)
        parameters+="&";
    parameters+="repairOrderNumber_like="+this.filterForm.controls.roNumber.value;
    }
    if(this.filterForm.controls.vehicleInfo.value){
      if(parameters.length>1)
        parameters+="&";
    parameters+="vehicleInfo_like="+ this.filterForm.controls.vehicleInfo.value;
    }
    if(this.filterForm.controls.claimStatus.value && this.filterForm.controls.claimStatus.value.code && this.filterForm.controls.claimStatus.value.code != 10){
      if(parameters.length>1)
        parameters+="&";
      parameters+="statusCode_eq="+this.filterForm.controls.claimStatus.value.code;
    } 
    if(this.filterForm.controls.customerName.value){
      if(parameters.length>1)
        parameters+="&";
    parameters+="customerName_like="+this.filterForm.controls.customerName.value;
    }
    if(this.filterForm.controls.amount.value){
      if(parameters.length>1)
        parameters+="&";
    parameters+="amount_eq="+ +this.filterForm.controls.amount.value;
    }
    if(this.filterForm.controls.createdDateFrom.value){
      if(parameters.length>1)
        parameters+="&";
    parameters+="sysDateI_from=" + new Date(this.filterForm.controls.createdDateFrom.value).toISOString();
    }
    if(this.filterForm.controls.userName.value){
      if(parameters.length>1)
        parameters+="&";
    parameters+="userFullName_like="+this.filterForm.controls.userName.value;
    }
    if(this.filterForm.controls.createdDateTo.value){
      if(parameters.length>1)
        parameters+="&";

      let date:Date = new Date(this.filterForm.controls.createdDateTo.value);
      date.setDate(date.getDate() + 1);
      
      parameters+="sysDateI_to=" +date.toISOString();
    }
    
      this.filterLoading=true;
      if(parameters.length>1)
        parameters+="&";
      parameters+="v=ACTIVE&accountNumber="+this.claimService.accountNumber;

    
    this.parametersActive = parameters;
   
    this.filterClaims(parameters , true, false);
  }

  filterClaims(parameters:String, modalForm: boolean, sort: boolean, showSpinner?: boolean){
    if(!sort)
      this.page=1;
    let tmpPage = this.page - 1;
    let tmpPageParam = "&page=" + tmpPage;
    let tmpSize = "&size=" + this.pageSize;

    if(this.sortQuery?.length >= 0)
      parameters += this.sortQuery;

    this.claimService.getFilteredClaims(parameters + tmpSize + tmpPageParam).subscribe((result:any)=>{
      
      this.claimList=result;
      let claimListTmp: any = [];
      
      if(this.filterByClaimNumber){
        for(let i = 0; i < this.claimList?.length; i++){
            if(this.claimList[i].statusCode != 40 && this.claimList[i].statusCode != 60 && this.claimList[i].statusCode != 80){
              this.numberOfClaimsWithHiddenClaimNumber++;
            } else {
              claimListTmp.push(this.claimList[i]);
            }
        }
        this.claimList=claimListTmp;
      }
      
      this.countWithFilter();
      //else this.claimList=this.claimList.concat(result);
      
      this.filterLoading=false;
      if(this.claimList){
        this.claimListShow=[];
        for(let i=0; i<this.claimList; i++)
          this.claimListShow[i]=false; 
        }
        if(modalForm)
          document.getElementById('filterModal').click();
    }, (error)=>{
      console.log(error);
      this.toastr.error('Something went wrong while filtering claims, please try again. ' + 'Error code: '+error.status, 'Active Claims', {
        positionClass: 'toast-top-center', timeOut:6000
      });
    });
  }
  filterClaimsAll(sort: boolean, showSpinner?: boolean){
    if(!this.allPressed)
      this.resetSortQuery();
    this.allPressed=true;
    this.requestedPressed=false;
    this.authorizedPressed=false;
    if(!sort)
      this.page = 1;
    this.parametersActive = "?v=ACTIVE&accountNumber=" + this.claimService.accountNumber;
    
    this.onPageChange(this.page, showSpinner);
    this.filterForm.reset();
  }
  filterClaimsRequested(sort: boolean, showSpinner?: boolean){
    if(!this.requestedPressed)
      this.resetSortQuery();
    this.allPressed=false;
    this.requestedPressed=true;
    this.authorizedPressed=false;
    //this.filterClaims("?v=ACTIVE&size=200",false);
    //this.claimList=this.transform(this.claimListHelper, this.requestedStatuses);
    if(!sort)
      this.page = 1;
    this.parametersActive = "?v=ACTIVE&accountNumber=" + this.claimService.accountNumber + "&paymentStatus_eq=Requested";
   
    this.onPageChange(this.page, showSpinner);
    this.filterForm.reset();
    // let parameters:string="?v=ACTIVE&statusCode_eq=";
    // this.claimList=[];
    // for(let i=0; i< this.requestedStatuses?.length;i++){
    //   parameters+=this.requestedStatuses[i];
    //   this.filterClaims(parameters, false);
    // }
    // this.filterForm.reset();
  }
  filterClaimsAuthorized(sort: boolean, showSpinner?: boolean){
    if(!this.authorizedPressed)
      this.resetSortQuery();
    this.allPressed=false;
    this.requestedPressed=false;
    this.authorizedPressed=true;
    //this.filterClaims("?v=ACTIVE&size=200",false);
    if(!sort)
    this.page = 1;
    this.parametersActive = "?v=ACTIVE&accountNumber=" + this.claimService.accountNumber + "&paymentStatus_eq=Authorized";
    
    this.onPageChange(this.page, showSpinner);

//    this.claimList=this.transform(this.claimListHelper, this.authorizedStatuses);
    this.filterForm.reset();
  }
  transform(items: any[], statusList: number[]): any[] {
    if (!items) return [];
    if (!statusList) return items;
  
    return items.filter((item:Claim) => {
        return statusList.indexOf(item.statusCode) !== -1;
    });
   }

   onPageChange(page, showSpinner?: boolean){
    if(!showSpinner)
      this.pageLoading = true;
//    let parameters = "?v=ACTIVE&size=" + this.pageSize + "&page=" + tmpPage;

      let tmpPage = page-1;
      let tmpPageParam = "&page=" + tmpPage;
      let tmpSize = "&size=" + this.pageSize;
      let parameters = this.parametersActive + tmpSize + tmpPageParam;
      if(this.sortQuery?.length >= 0)
        parameters += this.sortQuery;
    if(!showSpinner)
      this.claimList=[];
    this.claimService.getFilteredClaims(parameters).subscribe((result:any)=>{
      this.claimList=result;
      this.claimListShow=[];
      if(this.claimList){
        for(let i=0; i<this.claimList.length; i++)
          this.claimListShow[i]=false;
        this.pageLoading=false;
        }
    });
    this.countWithFilter();
   }

   countWithFilter(){
    this.claimService.getClaimsCount(this.parametersActive).subscribe((value:any)=>{
      this.totalSize = value;
      if(this.filterByClaimNumber){
        this.totalSize -= this.numberOfClaimsWithHiddenClaimNumber;
        this.numberOfClaimsWithHiddenClaimNumber = 0;
        this.filterByClaimNumber = false;
        if(this.totalSize < 0)
          this.totalSize = 0;
      }
      }, error=>{
            console.log(error);
            this.toastr.error('Something went wrong while fetching number of active claims, please try again. ' + 'Error code: '+error.status, 'Active Claims', {
              positionClass: 'toast-top-center'
            });
            
      }
     );
   }
   sort(field:string, showSpinner?: boolean){
    if(field !== this.currentSortField){
      this.sortTableIndicator = 1;
      this.currentSortField = field;
    } else {
      this.sortTableIndicator = ++this.sortTableIndicator % 3;
    }

    switch(this.sortTableIndicator){
      //DEFAULT SORT
      case 0:{
        this.sortQuery="";
        this.sortFieldAndDirection = "default";

        break;
      }
      //ASCENDING
      case 1:{
        this.sortQuery = "&sort=" + field + ",asc";
        this.sortFieldAndDirection = field + "ASC";

        break;
      }
      //DESCENDING
      case 2:{
        this.sortQuery = "&sort=" + field + ",desc";
        this.sortFieldAndDirection = field + "DESC";

        break;
      }
    }
    if (this.authorizedPressed) this.filterClaimsAuthorized(true, showSpinner);
    else if (this.requestedPressed) this.filterClaimsRequested(true, showSpinner);
    else if (this.allPressed) this.filterClaimsAll(true, showSpinner);
    else  this.filterClaims(this.parametersActive, false, true, showSpinner);
  }

  resetSortQuery(){
      this.sortTableIndicator = 0;
      this.sortQuery="";
  }
}
