import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-confirm-action',
  templateUrl: './confirm-action.component.html',
  styleUrls: ['./confirm-action.component.css']
})
export class ConfirmActionComponent implements OnInit {
  @Input() header: string;
  @Input() message: string;
  @Input() buttonMessage: string;
  @Input() indexNum: number;
  @Input() hideCloseButton: boolean;
  
  @Output() confirmActionEvent = new EventEmitter<boolean>();

  confirmActionForm: FormGroup;


  constructor(
    private readonly fb: FormBuilder,
  ) {
    this.confirmActionForm = this.fb.group({
    });
  }
  ngOnInit(): void {
  }

  confirmActionFormSubmit(){
    this.confirmActionEvent.emit(true);
  }
  cancel(){
    this.confirmActionEvent.emit(false);
  }
}
