<div class="container-fluid">
  <div class="row">
    <div class="col-1" style="align-self: center; background: #f8f9fa;"> <i 
       class="fa fa-angle-left fa-5x mg-r-5" (click)="decreaseCounter()" [ngClass]="{
        'blue-arrow': pageNumber > 0
      }" ></i></div>
    <app-new-item
      [details]="releaseNoteList[pageNumber]?.details"
      [pageNum]="releaseNoteList[pageNumber]?.pageNum"
      [imgSourcePath]="releaseNoteList[pageNumber]?.imgPath"
      [detailsTitle]="releaseNoteList[pageNumber]?.detailsTitle"
      [detailsTitleSecondRow]="releaseNoteList[pageNumber]?.detailsTitleSecondRow"
      [imageWidthFull]="releaseNoteList[pageNumber]?.imgWidthFull"
      [detailsSecondRowText]="releaseNoteList[pageNumber]?.detailsSecondRow"
      class="card col-10 bd-0"
    ></app-new-item>
    <div class="col-1" style="align-self: center; background: #f8f9fa;"><i 
        class="fa fa-angle-right fa-5x mg-r-5" (click)="increaseCounter()" [ngClass]="{
            'blue-arrow': pageNumber +1 < releaseNoteList?.length
          }"></i></div>
  </div>
</div>