<div class="card bd-0">
  <div class="col-12 card card-body bg-grandeur tx-white mg-b-20 bd-0">
    <div class="container-fluid">
      <div class="row">
        <div class="col-1 align-self-center">
          <div class="containter-fluid">
            <!-- <img src="http://via.placeholder.com/500x500" alt=""> -->
            <i class="fa fa-5x fa-automobile"></i>
          </div>
        </div>

        <div class="column pd-l-50 col-8">
          <div class="row mg-l-0">
            <h4>5NPEB4ACXDH617401</h4>
          </div>

          <div class="row">
            <div class="column col-6">
              <!-- <div class="row"> -->
                <div class="row justify-content-between">
                  <div class="col-5">
                    <label class="form-control-label">User:</label>
                  </div>
                  <div class="col-6 pd-l-0">
                    <label class="form-control-label">John Doe</label>
                  </div>
                </div>
             
              <!-- </div> -->
              <!-- <div class="row"> -->
              <div class="row justify-content-between">
                <div class="col-5">
                  <label class="form-control-label">Contract Number:</label>
                </div>
                <div class="col-6 pd-l-0">
                  <label class="form-control-label">VSC-CA004</label>
                  
                </div>
              </div>
              <!-- </div> -->
            </div>
            <div class="column col-6">
              <div class="row justify-content-between">
                <div class="col-6 justify-content-start">
                  <label class="form-control-label"
                    >Customer Last Name:</label
                  >
                </div>
                <div class="col-6 pd-l-0">
                  <label class="form-control-label"
                    >Chesser</label
                  >
                </div>
              </div>
              <div class="row justify-content-between">
                <div class="col-5 justify-content-start">
                  <label class="form-control-label">VIN:</label>
                </div>
                <div class="col-6 pd-l-0">
                  <label class="form-control-label">5NPEB4ACXDH617401</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-3 align-self-center">
          <div class="row justify-content-end">
            
            
            <button routerLink="/new-claim"
             class="btn btn-oblong btn-primary col-8">
              Start new claim
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- media -->
</div>

<!-- Vehicle information -->
<div class="card">
  <div class="card-title pd-t-20">
    <h5 class="tx-black pd-l-20">Vehicle Information</h5>
  </div>
  <div class="row pd-r-20 justify-content-around pd-l-20">
    <div class="col-5 pd-b-20">
      <div class="row justify-content-between bd-b pd-t-10">
        <label class="form-control-label tx-primary">VIN </label>
        <p class="form-control-label text-break mg-b-10">5NPEB4ACXDH617401</p>
      </div>
      <div class="row justify-content-between bd-b pd-t-10">
        <label class="form-control-label tx-primary">Year</label>
        <p class="form-control-label text-break mg-b-10">2006</p>
      </div>
      <div class="row justify-content-between bd-b pd-t-10">
        <label class="form-control-label tx-primary">Make </label>
        <p class="form-control-label text-break mg-b-10">FORD</p>
      </div>
      <div class="row justify-content-between bd-b pd-t-10">
        <label class="form-control-label tx-primary">Model</label>
        <p class="form-control-label text-break mg-b-10">Escape XLS</p>
      </div>
      <div class="row justify-content-between bd-b pd-t-10">
        <label class="form-control-label tx-primary">Sale Odometer </label>
        <p class="form-control-label text-break mg-b-10">123456</p>
      </div>
      <div class="row justify-content-between bd-b pd-t-10">
        <label class="form-control-label tx-primary"
          >Vehicle Purchase Date</label
        >
        <p class="form-control-label text-break mg-b-10">01/01/2020</p>
      </div>
    </div>

    <div class="col-5 pd-b-20">
     
      <div class="row justify-content-between bd-b pd-t-10">
        <label class="form-control-label tx-primary">Effective Date</label>
        <p class="form-control-label text-break mg-b-10">01/01/2020</p>
      </div>
      <div class="row justify-content-between bd-b pd-t-10">
        <label class="form-control-label tx-primary">Term</label>
        <p class="form-control-label text-break mg-b-10">123</p>
      </div>
      <div class="row justify-content-between bd-b pd-t-10">
        <label class="form-control-label tx-primary">Mileage </label>
        <p class="form-control-label text-break mg-b-10">2456</p>
      </div>
      <div class="row justify-content-between bd-b pd-t-10">
        <label class="form-control-label tx-primary">Deductible</label>
        <p class="form-control-label text-break mg-b-10">2345SAPDK90ASD</p>
      </div>
      <div class="row justify-content-between bd-b pd-t-10">
        <label class="form-control-label tx-primary">Program Description</label>
        <p class="form-control-label text-break mg-b-10">14ASDAKAS0</p>
      </div>
    </div>

    
  </div>
</div>

<!-- Customer information Sale Odometer -->
<div class="card mg-t-20">
  <div class="card-title pd-t-20">
    <h5 class="tx-black pd-l-20">Customer Information</h5>
  </div>
  <div class="row pd-r-20 justify-content-around pd-l-20">
    <div class="col-5 pd-b-20">
      <div class="row justify-content-between bd-b pd-t-10">
        <label class="form-control-label tx-primary">Seller Name </label>
        <p class="form-control-label text-break mg-b-10">Seller Name</p>
      </div>
      <div class="row justify-content-between bd-b pd-t-10">
        <label class="form-control-label tx-primary">Purchase Date</label>
        <p class="form-control-label text-break mg-b-10">01/01/2021</p>
      </div>
      <div class="row justify-content-between bd-b pd-t-10">
        <label class="form-control-label tx-primary">Customer Address</label>
        <p class="form-control-label text-break mg-b-10">7777 CA</p>
      </div>
      <div class="row justify-content-between bd-b pd-t-10">
        <label class="form-control-label tx-primary"
          >Customer Primary Phone Number</label
        >
        <p class="form-control-label text-break mg-b-10">+34 54455454</p>
      </div>
      
    </div>

    <div class="col-5 pd-b-20">
      <div class="row justify-content-between bd-b pd-t-10">
        <label class="form-control-label tx-primary"
          >Customer Secondary Phone Number</label
        >
        <p class="form-control-label text-break mg-b-10">+34 54455453</p>
      </div>
      <div class="row justify-content-between bd-b pd-t-10">
        <label class="form-control-label tx-primary">Customer Email</label>
        <p class="form-control-label text-break mg-b-10">jochesser@zoic.com</p>
      </div>
      <div class="row justify-content-between bd-b pd-t-10">
        <label class="form-control-label tx-primary"
          >Customer Alternate Contact</label
        >
        <p class="form-control-label text-break mg-b-10">+1 234 567 8909</p>
      </div>
    </div>
    <div class="col-3 pd-b-20"></div>
  </div>
</div>
