<router-outlet></router-outlet>
<div style="display:none">
    <span 
      data-toggle="modal"
      attr.data-target="#confirmActionModal{{indexNum}}"
      id="sessionExpiration">
    </span>
  </div>
<app-confirm-action 
id="123456789"
[header]="header"  
[message]="message"
[buttonMessage]="buttonMessage"    
[indexNum]="indexNum"
(confirmActionEvent)="refreshSession($event)"
></app-confirm-action>