<div class="slim-mainpanel">
  <div class="container-fluid pd-lg-x-120">
    <div class="card pd-20 mg-t-60">
      <div class="row mg-l-0 mg-r-0 justify-content-between align-items-center">
        <h2 class="pd-l-0 col-md-5 tx-primary">
          <i class=" ion-document-text pd-r-10"></i>
          Draft Claims ({{ totalSize }})
        </h2>
        <div class="d-flex align-items-center justify-content-between pd-y-5">
          <button
            class="btn btn-oblong zurich-blue pd-y-5 mg-r-10"
            data-toggle="modal"
            data-target="#filterModal"
          >
          Filter<span class="icon icon--filter-horizontal_48_outline mg-r-1 zurich-blue"></span>
          </button>
          <button (click)="filterClaimsAll(false)" class="btn btn-oblong btn pd-y-5 mg-r-10"
          [ngClass]="{
            'btn-primary': allPressed,
            'btn-secondary': !allPressed
          }">
            All
          </button>
        </div>
      </div>
      <ng-template #statusSending>
        
        <span >
          The claim is in the process of being sent to the system. This process might take a few seconds. <br>
        </span>
        <span>These claims appear in the Draft section until received by Zurich.<br></span>
        <span>Please report claims using the support feature: “Report a bug” if the claim is in this status for more than a few minutes.</span>
      </ng-template>
      <div *ngIf="pageLoading" class="spinner-border row align-self-center" style="width: 3rem; height: 3rem;" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="table-wrapper table-responsive">
      <table
      *ngIf="!pageLoading"
        id="datatable1"
        class="table table-expandable display responsive nowrap"
      >
        <thead>
          <tr>
            <th class="text-center bg-white"></th>
            <th class="text-center bg-white zurich-blue valign-middle">Status 
              <i *ngIf="sortFieldAndDirection == 'default' || (sortFieldAndDirection != 'statusCodeASC' && sortFieldAndDirection != 'statusCodeDESC') " style="cursor: pointer;" (click)="sort('statusCode')" class="fa fa-sort bg-grey mg-l-5"></i>
              <i *ngIf="sortFieldAndDirection == 'statusCodeASC'"(click)="sort('statusCode')" style="cursor: pointer;" class="fa fa-arrow-up mg-l-5"></i>
              <i *ngIf="sortFieldAndDirection == 'statusCodeDESC'"(click)="sort('statusCode')" style="cursor: pointer;" class="fa fa-arrow-down mg-l-5"></i>
            </th>
            <th class="text-center bg-white zurich-blue valign-middle">Created date 
              <i *ngIf="sortFieldAndDirection == 'default' || (sortFieldAndDirection != 'sysDateIASC' && sortFieldAndDirection != 'sysDateIDESC') " style="cursor: pointer;" (click)="sort('sysDateI')" class="fa fa-sort bg-grey mg-l-5"></i>
              <i *ngIf="sortFieldAndDirection == 'sysDateIASC'"(click)="sort('sysDateI')" style="cursor: pointer;" class="fa fa-arrow-up mg-l-5"></i>
              <i *ngIf="sortFieldAndDirection == 'sysDateIDESC'"(click)="sort('sysDateI')" style="cursor: pointer;" class="fa fa-arrow-down mg-l-5"></i>
            </th>
            <th class="text-center bg-white zurich-blue valign-middle">Repair Order No. 
              <i *ngIf="sortFieldAndDirection == 'default' || (sortFieldAndDirection != 'repairOrderNumberASC' && sortFieldAndDirection != 'repairOrderNumberDESC') " style="cursor: pointer;" (click)="sort('repairOrderNumber')" class="fa fa-sort bg-grey mg-l-5"></i>
              <i *ngIf="sortFieldAndDirection == 'repairOrderNumberASC'"(click)="sort('repairOrderNumber')" style="cursor: pointer;" class="fa fa-arrow-up mg-l-5"></i>
              <i *ngIf="sortFieldAndDirection == 'repairOrderNumberDESC'"(click)="sort('repairOrderNumber')" style="cursor: pointer;" class="fa fa-arrow-down mg-l-5"></i>
            </th>
            <th class="text-center bg-white zurich-blue valign-middle">Vehicle information 
              <i *ngIf="sortFieldAndDirection == 'default' || (sortFieldAndDirection != 'vehicleInfoASC' && sortFieldAndDirection != 'vehicleInfoDESC') " style="cursor: pointer;" (click)="sort('vehicleInfo')" class="fa fa-sort bg-grey mg-l-5"></i>
              <i *ngIf="sortFieldAndDirection == 'vehicleInfoASC'"(click)="sort('vehicleInfo')" style="cursor: pointer;" class="fa fa-arrow-up mg-l-5"></i>
              <i *ngIf="sortFieldAndDirection == 'vehicleInfoDESC'"(click)="sort('vehicleInfo')" style="cursor: pointer;" class="fa fa-arrow-down mg-l-5"></i>
            </th>
            <th class="text-center bg-white zurich-blue valign-middle">Customer name 
              <i *ngIf="sortFieldAndDirection == 'default' || (sortFieldAndDirection != 'customerNameASC' && sortFieldAndDirection != 'customerNameDESC') " style="cursor: pointer;" (click)="sort('customerName')" class="fa fa-sort bg-grey mg-l-5"></i>
              <i *ngIf="sortFieldAndDirection == 'customerNameASC'"(click)="sort('customerName')" style="cursor: pointer;" class="fa fa-arrow-up mg-l-5"></i>
              <i *ngIf="sortFieldAndDirection == 'customerNameDESC'"(click)="sort('customerName')" style="cursor: pointer;" class="fa fa-arrow-down mg-l-5"></i>
            </th>
            <th class="text-center bg-white zurich-blue valign-middle">Amount 
              <i *ngIf="sortFieldAndDirection == 'default' || (sortFieldAndDirection != 'amountASC' && sortFieldAndDirection != 'amountDESC') " style="cursor: pointer;" (click)="sort('amount')" class="fa fa-sort bg-grey mg-l-5"></i>
              <i *ngIf="sortFieldAndDirection == 'amountASC'"(click)="sort('amount')" style="cursor: pointer;" class="fa fa-arrow-up mg-l-5"></i>
              <i *ngIf="sortFieldAndDirection == 'amountDESC'"(click)="sort('amount')" style="cursor: pointer;" class="fa fa-arrow-down mg-l-5"></i>
            </th>
            <th class="text-center bg-white zurich-blue valign-middle">Authorization number 
              <i *ngIf="sortFieldAndDirection == 'default' || (sortFieldAndDirection != 'claimNumberASC' && sortFieldAndDirection != 'claimNumberDESC') " style="cursor: pointer;" (click)="sort('claimNumber')" class="fa fa-sort bg-grey mg-l-5"></i>
              <i *ngIf="sortFieldAndDirection == 'claimNumberASC'"(click)="sort('claimNumber')" style="cursor: pointer;" class="fa fa-arrow-up mg-l-5"></i>
              <i *ngIf="sortFieldAndDirection == 'claimNumberDESC'"(click)="sort('claimNumber')" style="cursor: pointer;" class="fa fa-arrow-down mg-l-5"></i>
            </th>
            <th class="text-center bg-white" style="display: none;"></th>
            <th class="bg-white"></th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let claim of claimList; let i = index">
            <tr>
              <td>
                <i
                  (click)="previewDetails1(claim.contractNumber, i)"
                  *ngIf="this.claimListShow[i]"
                  class="ion-ios-arrow-up"
                  data-toggle="collapse"
                  [attr.data-target]="'#collapse' + i"
                  style="cursor: pointer;"
                ></i>
                <i
                  (click)="previewDetails1(claim.contractNumber, i)"
                  *ngIf="!this.claimListShow[i]"
                  class="ion-ios-arrow-down"
                  data-toggle="collapse"
                  [attr.data-target]="'#collapse' + i"
                  style="cursor: pointer;"
                ></i>
              </td>
              <td class="text-center" *ngIf="claim.statusCode == '10'"><span placement="right" ngbTooltip="This claim has not been submitted yet. Draft Claims have their own list as they are not considered active claims." class="badge badge-pill badge-success" style="line-height: 2; background-color:#6c757d; font-size: 85%; width: 148.7px; height: 28.94px;">{{ claim.status }}</span></td>
              <td class="text-center" *ngIf="claim.statusCode == '70'"><span placement="right" [ngbTooltip]="statusSending" class="badge badge-pill badge-success" style="line-height: 2; background-color:#6c757d; font-size: 85%; width: 148.7px; height: 28.94px;">{{ claim.status }}</span></td>

              <td class="text-center" *ngIf="claim.statusCode == '0'">
                <span
                  class="badge badge-pill badge-success"
                  style="
                    line-height: 2;
                    background-color: #888888;
                    font-size: 85%;
                    width: 148.7px;
                    height: 28.94px;
                  "
                  >{{ claim.status }}</span
                >
              </td>
              <td class="text-center">{{ claim.sysDateI | date }}</td>
              <td class="text-center">{{ claim.repairOrderNumber }}</td>
              <td class="text-center">{{ claim.year }} {{ claim.make }} {{ claim.model }}</td>
              <td class="text-center">{{ claim.firstName }} {{ claim.lastName }}</td>
              <td class="text-center">{{ claim.totalAgreed | currency: "$" }}</td>
              <td class="text-center" [ngClass]="{ 'tx-bold tx-20': !claim.claimNumber }">{{ claim.claimNumber ? claim.claimNumber : "-" }}</td>
              <td class="text-center">
                <div class="row justify-content-center mg-r-0 align-items-center">
                <button
                  (click)="setContract(claim, true, i, false)"
                  class="btn btn-oblong table-button btn-primary btn btn-sm mg-r-10"
                >
                  Continue
                </button>
                <!-- <span (click)="setContract(claim, true, i, true)" placement="top" ngbTooltip="Edit Claim" class="icon icon--pencil_48_outline pd-x-5 col-xl-2 col-md-5 mg-r-0"> </span> -->
                <span (click)="setClaimDelete(claim?.id)"
                data-toggle="modal"
                attr.data-target="#confirmActionModal{{indexNum}}"
                id="deleteClaimButtonFromClaimInfo"
                style="cursor: pointer;"
                class="icon icon--trash_48_outline pd-x-0 col-xl-2 col-md-5  mg-r-0"> </span>
              </div>
              </td>
              
            </tr>
            <tr class="hide-table-padding">
              <td colspan="12" class="p-0 m-0">
                <div
                  id="collapse{{ i }}"
                  class="card row mg-l-0 mg-r-0 collapse out"
                >
                  <app-claim-list-detail-tabs
                    [customerInformation]="customerInformation1 + i"
                    [vehicleInformation]="vehicleInformation1 + i"
                    [contractInformation]="contractInformation1 + i"
                    [indexNum]="indexNum + i"
                    [draftClaim]="draftClaim"
                  ></app-claim-list-detail-tabs>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
      <div class="row justify-content-end">
        <ngb-pagination [(collectionSize)]="totalSize" [(page)]="page" [maxSize]="4" [rotate]="true" [(pageSize)]="pageSize" (pageChange)="onPageChange(page)" [boundaryLinks]="true"></ngb-pagination>
      </div>
    </div>
  </div>
</div>
<app-confirm-action 
      [header]="header"  
      [message]="message"
      [buttonMessage]="buttonMessage"    
      [indexNum]="indexNum"
      (confirmActionEvent)="deleteClaim($event)"
  ></app-confirm-action>
<div class="modal" id="filterModal">
  <div class="modal-dialog modal-lg">
    <form
      fxLayout="column"
      [formGroup]="filterForm"
      (ngSubmit)="submitFilterForm()"
    >
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Filter</h4>
        <button type="button" class="close" data-dismiss="modal">
          &times;
        </button>
      </div>

      
      <!-- Modal body -->
      <div class="modal-body pd-x-20">
        <div class="row">
          <div class="col-6">
            <!-- <div class="form-group">
              <label class="form-control-label">Authorization Number</label>
              <input class="form-control" type="text" [formControlName]="'claimNumber'" name="claimNumber" />
            </div> -->
            <div class="form-group">
              <label class="form-control-label">Vehicle Information</label>
              <input class="form-control" type="text" [formControlName]="'vehicleInfo'" name="vehicleInfo" />
            </div>
            <!-- col-4 -->

            <div class="form-group">
              <label class="form-control-label"
                >RO #</label
              >
              <input class="form-control" type="text" [formControlName]="'roNumber'" name="roNumber" />
            </div>
           
            <div class="form-group">
              <label class="form-control-label"
              >Created Date From</label
            >
            
            <div class="input-group">
              <input
                type="date"
                class="form-control fc-datepicker"
                placeholder="dd-mm-yyyy"
                [formControlName]="'createdDateFrom'"
              />
            </div>
            
          </div>
          <div class="form-group">
            <label class="form-control-label"
              >User Name</label
            >
            <input class="form-control" type="text" [formControlName]="'userName'" name="userName" />
          </div>
          </div>
          <!-- col-4 -->

          <div class="col-6">
            <div class="form-group">
              <label class="form-control-label">Customer Name</label>
              <input class="form-control" type="text" [formControlName]="'customerName'" name="customerName" />
            </div>

            <div class="form-group">
              <label class="form-control-label">Amount $</label>
              <input class="form-control" [formControlName]="'amount'" type="text" name="amount"
              [ngClass]="{
                'is-invalid': filterFormSubmitted && filterForm?.controls?.amount?.errors
              }" />
            </div>
            <div class="form-group">
              <label class="form-control-label"
              >Created Date To</label
            >
            
            <div class="input-group">
              <input
                type="date"
                class="form-control fc-datepicker"
                placeholder="dd-mm-yyyy"
                [formControlName]="'createdDateTo'"
              />
            </div>
          </div>
          </div>
        </div>
      </div>
      <!-- Modal footer -->
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-oblong btn-secondary btn"
          data-dismiss="modal"
        >
          Cancel
        </button>
        <button
          type="submit"
          class="btn btn-oblong btn-primary btn"
        >
          Apply Filter
         
        </button>
        <div  *ngIf="filterLoading" class="spinner-border row align-self-center" style="width: 2rem; height: 2rem;" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </form>
  </div>
</div>
