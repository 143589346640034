<!-- Header component -->
<div
  class="col-12 card card-body mg-b-0 pd-b-0 bg-info tx-white bd-0"
  *ngIf="displayBanner"
>
  <div class="container-fluid">
    <div class="row">
      <div class="col-1 align-self-center">
        <div class="containter-fluid">
          <i class="fa fa-5x fa-automobile"></i>
        </div>
      </div>

      <div class="column pd-l-50 col-8">
        <div class="row mg-l-0">
          <h4>{{ contractInfo?.vehicle.vIN }}</h4>
        </div>

        <div class="row">
          <div class="column col-6">
            <!-- <div class="row"> -->
            <div class="row justify-content-between">
              <div class="col-5">
                <label class="form-control-label font-weight-bold"
                  >User ID:</label
                >
              </div>
              <div class="col-6 pd-l-0">
                <label class="form-control-label">12345</label>
              </div>
            </div>

            <!-- </div> -->
            <!-- <div class="row"> -->
            <div class="row justify-content-between">
              <div class="col-5">
                <label class="form-control-label font-weight-bold"
                  >Contract Number:</label
                >
              </div>
              <div class="col-6 pd-l-0">
                <label class="form-control-label">{{
                  contractInfo?.contractNumber
                }}</label>
              </div>
            </div>
            <!-- </div> -->
          </div>
          <div class="column col-6">
            <div class="row justify-content-between">
              <div class="col-5 justify-content-start">
                <label class="form-control-label font-weight-bold">User:</label>
              </div>
              <div class="col-6 pd-l-0">
                <label class="form-control-label">{{
                  contractInfo?.seller?.name
                }}</label>
              </div>
            </div>
            <div class="row justify-content-between">
              <div class="col-6 justify-content-start">
                <label class="form-control-label font-weight-bold"
                  >Customer Last Name:</label
                >
              </div>
              <div class="col-6 pd-l-0">
                <label class="form-control-label">{{
                  contractInfo?.customer?.lastName
                }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="draftClaim" class="col-3 align-self-center">
        <div class="row justify-content-end">
          <button
            routerLink="/new-claim"
            class="btn btn-oblong tx-info bg-white col-7"
          >
            Continue
          </button>
        </div>
      </div>
      <div *ngIf="hideButton" class="col-3 align-self-center">
        <div class="row justify-content-end">
          <button
            routerLink="/submit"
            class="btn btn-oblong tx-info bg-white col-7"
          >
            Details
          </button>
        </div>
      </div>
      <div *ngIf="!hideButton && !draftClaim" class="col-3 align-self-center">
        <div class="row justify-content-end">
          <button
            routerLink="/new-claim"
            class="btn btn-oblong tx-info bg-white col-7"
          >
            Start new claim
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row mg-l-0 mg-r-0">
  <div class="col-md pd-l-0 pd-r-0">
    <div class="card-header pd-l-20 pd-r-20 bg-white" >
      <ul class="nav nav-tabs bd-b-0 card-header-tabs bg-white">
        <li class="nav-item"  (click)="setCustomerActive()">
          
          <a
          id="customerTabNav{{indexNum}}"
          [ngClass]="{ 'active-nav-link': customerTabActive, 'non-active-nav-link': !customerTabActive }"
         
            class="nav-link bg-white bd-0 zurich-blue"
            
            [class.tx-white]="!displayBanner"
            href="#{{ customerInformation }}"
            data-toggle="tab"
            >Customer
          </a>
        </li>
        <li class="nav-item" (click)="setVehicleActive()">
          <a
          id="vehicleTabNav{{indexNum}}"
          [ngClass]="{ active: vehicleTabActive, 'non-active-nav-link': !vehicleTabActive }"
          
          class="nav-link bg-white bd-0 zurich-blue"
            [class.tx-white]="!displayBanner"
            href="#{{ vehicleInformation }}"
            data-toggle="tab"
            >Vehicle
          </a>
        </li>
        <li class="nav-item"  (click)="setContractActive()">
          <a
          id="contractTabNav{{indexNum}}"
          [ngClass]="{ active: contractTabActive, 'non-active-nav-link': !contractTabActive }"
         
          class="nav-link bg-white bd-0 zurich-blue"
            [class.tx-white]="!displayBanner"
            href="#{{ contractInformation }}"
            data-toggle="tab"
            >Contract
          </a>
        </li>
      </ul>
    </div>
    <div class="card-body pd-b-0">
      <div class="tab-content pd-b-0">
        <!-- tab-pane Customer info-->
        <div class="tab-pane active" id="{{ customerInformation }}">
          <div class="row pd-r-20 justify-content-around pd-l-20">
            <div class="col-5">
              <div class="row justify-content-between bd-b pd-t-10">
                <label class="form-control-label tx-primary"
                  >Customer Name
                </label>
                <p class="form-control-label tx-primary text-break mg-b-10">
                  {{ contractInfo?.customer?.firstName }}
                  {{ contractInfo?.customer?.lastName }}
                </p>
              </div>
              <!-- <div class="row justify-content-between bd-b pd-t-10">
                <label class="form-control-label tx-primary"
                  >Purchase Date</label
                >
                <p class="form-control-label text-break mg-b-10">01/01/2021</p>
              </div> -->
              <div class="row justify-content-between bd-b pd-t-10">
                <label class="form-control-label tx-primary">Address</label>
                <p class="form-control-label tx-primary text-break mg-b-10">
                  {{ contractInfo?.customer?.address?.address1 }}
                  {{ contractInfo?.customer?.address?.city }}
                  {{ contractInfo?.customer?.address?.state }}
                  {{ contractInfo?.customer?.address?.zipCode }}
                </p>
              </div>
              <div class="row justify-content-between bd-b pd-t-10">
                <label class="form-control-label tx-primary"
                  >Primary Phone Number</label
                >
                <p class="form-control-label tx-primary text-break mg-b-10">
                  {{ contractInfo?.customer?.address.homePhone }}
                </p>
              </div>
            </div>

            <div class="col-5">
              <div class="row justify-content-between bd-b pd-t-10">
                <label class="form-control-label tx-primary"
                  >Secondary Phone Number</label
                >
                <p class="form-control-label tx-primary text-break mg-b-10">
                  {{ contractInfo?.customer?.address.workPhone }}
                </p>
              </div>
              <div class="row justify-content-between bd-b pd-t-10">
                <label class="form-control-label tx-primary">Email</label>
                <p class="form-control-label tx-primary text-break mg-b-10">
                  {{ contractInfo?.customer?.email }}
                </p>
              </div>
              <div class="row justify-content-between bd-b pd-t-10">
                <label class="form-control-label tx-primary"
                  >Alternate Contact</label
                >
                <p class="form-control-label tx-primary text-break mg-b-10">
                  {{ contractInfo?.customer?.alternateContact }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- Vehicle info -->
        <div class="tab-pane" id="{{ vehicleInformation }}">
          <div class="row pd-r-20 justify-content-around pd-l-20">
            <div class="col-5">
              <div class="row justify-content-between bd-b pd-t-10">
                <label class="form-control-label tx-primary">VIN </label>
                <p class="form-control-label tx-primary text-break mg-b-10">
                  {{ contractInfo?.vehicle.vIN }}
                </p>
              </div>
              <div class="row justify-content-between bd-b pd-t-10">
                <label class="form-control-label tx-primary">Vehicle</label>
                <p class="form-control-label tx-primary text-break mg-b-10">
                  {{ contractInfo?.vehicle.year }}
                  {{ contractInfo?.vehicle.make }}
                  {{ contractInfo?.vehicle.model }}
                </p>
              </div>
              <div class="row justify-content-between bd-b pd-t-10">
                <label class="form-control-label tx-primary"
                  >Sale Odometer
                </label>
                <p class="form-control-label tx-primary text-break mg-b-10">
                  {{ contractInfo?.vehicleOdometer | number: "1.0-0" }} miles
                </p>
              </div>
            </div>

            <div class="col-5">
              <div class="row justify-content-between bd-b pd-t-10">
                <label class="form-control-label tx-primary"
                  >Vehicle Purchase Date</label
                >
                <p class="form-control-label tx-primary text-break mg-b-10">
                  {{ contractInfo?.vehiclePurchaseDate | date }}
                </p>
              </div>
              <div class="row justify-content-between bd-b pd-t-10">
                <label class="form-control-label tx-primary"
                  >Manufacturing Warranty Start Date</label
                >
                <p class="form-control-label tx-primary text-break mg-b-10">
                  {{ contractInfo?.effectiveDate | date }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- Contract info -->
        <div class="tab-pane" id="{{ contractInformation }}">
          <div class="row pd-r-20 justify-content-around pd-l-20">
            <div class="col-5">
              <div class="row justify-content-between bd-b pd-t-10">
                <label class="form-control-label tx-primary"
                  >Program Description</label
                >
                <p class="form-control-label tx-primary text-break mg-b-10">
                  {{ contractInfo?.programDescription }}
                </p>
              </div>
              <div class="row justify-content-between bd-b pd-t-10">
                <label class="form-control-label tx-primary"
                  >Contract Number
                </label>
                <p class="form-control-label tx-primary text-break mg-b-10">
                  {{ contractInfo?.contractNumber }}
                </p>
              </div>
              <div class="row justify-content-between bd-b pd-t-10">
                <label class="form-control-label tx-primary"
                  >Seller Name
                </label>
                <p class="form-control-label tx-primary text-break mg-b-10">
                  {{ contractInfo?.seller?.name }}
                </p>
              </div>
              <div class="row justify-content-between bd-b pd-t-10">
                <label class="form-control-label tx-primary">Deductible </label>
                <p class="form-control-label tx-primary text-break mg-b-10">
                  {{ contractInfo?.deductible | currency: "$" }}
                </p>
              </div>
              <div class="row justify-content-between bd-b pd-t-10">
                <label class="form-control-label tx-primary"
                  >Term/Mileage
                </label>
                <p class="form-control-label tx-primary text-break mg-b-10">
                  {{ contractInfo?.term | number: "1.0-0" }}/{{
                    contractInfo?.mileage | number: "1.0-0"
                  }} miles
                </p>
              </div>
              
              <!-- <div class="row justify-content-between bd-b pd-t-10">
                <label class="form-control-label tx-primary"
                  >Contract purchase date</label
                >
                <p class="form-control-label text-break mg-b-10"></p>
              </div> -->
            </div>

            <div class="col-5">
              <div class="row justify-content-between bd-b pd-t-10">
                <label class="form-control-label tx-primary"
                  >Effective Date</label
                >
                <p class="form-control-label tx-primary text-break mg-b-10">
                  {{ contractInfo?.effectiveDate | date }}
                </p>
              </div>
              <div class="row justify-content-between bd-b pd-t-10">
                <label class="form-control-label tx-primary"
                  >Expiration Date</label
                >
                <p class="form-control-label tx-primary text-break mg-b-10">
                  {{ contractInfo?.expirationDate | date }}
                </p>
              </div>

              <div class="row justify-content-between bd-b pd-t-10">
                <label class="form-control-label tx-primary"
                  >Effective Odometer</label
                >
                <p class="form-control-label tx-primary text-break mg-b-10">
                  {{ contractInfo?.effectiveOdometer | number: "1.0-0" }} miles
                </p>
              </div>
              <div class="row justify-content-between bd-b pd-t-10">
                <label class="form-control-label tx-primary"
                  >Expiration Odometer</label
                >
                <p class="form-control-label tx-primary text-break mg-b-10">
                  {{ contractInfo?.expirationOdometer | number: "1.0-0" }} miles
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- tab-pane -->
    </div>
    <!-- tab-content -->
  </div>
  <!-- card-body -->
  <!-- card -->
</div>
