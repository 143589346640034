import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ClaimService } from '../_services/claim.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  dashboardPageActive: boolean = true;
  claimsPageActive: boolean = false;
  draftsPageActive: boolean = false;
  userInfo: any;
  reportABugForm: FormGroup;
  reportingABug: boolean = false;
  reportABugSubmitted: boolean = false;
  feedbackForm: FormGroup;
  feedbackSubmitted: boolean = false;
  feedbackSent: boolean = false;
  files: File[] = [];

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private claimService: ClaimService,
    private readonly fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.claimService.currentUrl.subscribe((value) => {
      if (value) {
        if (value == 'NONE') {
          this.dashboardPageActive = false;
          this.claimsPageActive = false;
          this.draftsPageActive = false;
        } else if (value == 'DRAFT') {
          this.setDraftsActive();
        } else if (value == 'ALL') {
          this.setClaimsActive();
        } else if (value == 'HOME') {
          this.dashboardPageActive = true;
          this.claimsPageActive = false;
          this.draftsPageActive = false;
        }
      }
    });
    this.userInfo = JSON.parse(localStorage.getItem('myAccountData'));
    this.reportABugForm = this.fb.group({
      reportABugText: [, Validators.required]
    });
    this.feedbackForm = this.fb.group({
      feedbackText: [, Validators.required]
    });
  }

  setDashboardActive(value: boolean) {
    this.dashboardPageActive = value;
    this.claimsPageActive = false;
    this.draftsPageActive = false;

    if (value) {
      this.router.navigate(['/']);
    } else {
      this.router.navigate(['/new-claim']);
    }
  }
  setClaimsActive() {
    this.dashboardPageActive = false;
    this.claimsPageActive = true;
    this.draftsPageActive = false;
  }
  setDraftsActive() {
    this.dashboardPageActive = false;
    this.claimsPageActive = false;
    this.draftsPageActive = true;
  }
  getUserInfo() {
    this.userInfo = JSON.parse(localStorage.getItem('myAccountData'));
    if (this.userInfo && this.userInfo.user_phone?.length > 4) {
      this.userInfo.user_phone = this.userInfo.user_phone.substring(0, 3) + "-" + this.userInfo.user_phone.substring(3);
      if (this.userInfo && this.userInfo.user_phone?.length > 7) {
        this.userInfo.user_phone = this.userInfo.user_phone.substring(0, 7) + "-" + this.userInfo.user_phone.substring(7);
      }
    }
  }
  get fReportABug(){
    return this.reportABugForm.controls;
  }

  get fFeedback(){
    return this.feedbackForm.controls;
  }
  resetReportABugForm() {
    this.reportABugForm.controls.reportABugText.setValue(null);
    this.reportABugSubmitted = false;
    this.reportingABug = false;
  }
  public resetFeedbackForm() {
    this.feedbackForm.controls.feedbackText.setValue(null);
    this.feedbackSubmitted = false;
    this.feedbackSent = false;
  }
  closeFeedback() {
    this.feedbackForm.controls.feedbackText.setValue(null);
    this.feedbackSubmitted = false;
    this.feedbackSent = false;
  }
  closeReportABug() {
    this.reportABugForm.controls.reportABugText.setValue(null);
    this.reportABugSubmitted = false;
    this.reportingABug = false;
  }
  submitFeedbackForm() {
    this.feedbackSubmitted = true;
   
    if (!this.feedbackForm.valid || !this.feedbackForm.controls.feedbackText?.value || this.feedbackSent) {
      this.feedbackSent = false;
      return;
    }
    this.feedbackSent = true;
    let feedback = this.feedbackForm.controls.feedbackText?.value;
    this.claimService.addFeedback(null, feedback).subscribe(
      (res) => {
        console.log(res);
          this.toastr.success('Successfully sent feedback', 'Feedback', {
            positionClass: 'toast-top-center',
          });
          document.getElementById('closeFeedbackModalButton').click();
          this.feedbackSubmitted = false;
          this.feedbackSent = false;
          this.feedbackForm.controls.feedbackText.setValue(null);

      },
      (error) => {
        console.log(error);
        this.feedbackSubmitted = false;
        this.feedbackSent = false;

        this.toastr.error(
          'Something went wrong while sending feedback, please try again. ' + 'Error code: '+error.status,
          'Feedback',
          {
            positionClass: 'toast-top-center', timeOut:6000
          }
        );
      }
    );
  }

  submitReportABugForm() {
    this.reportABugSubmitted = true;
    
    let type = "BUG_REPORT";
    if (!this.reportABugForm.valid || !this.reportABugForm.controls.reportABugText?.value || this.reportingABug) {
      this.reportingABug = false;
      return;
    }
    this.reportingABug = true;
    let comment = this.reportABugForm.controls.reportABugText?.value;
    if(this.files && this.files.length > 0){

      let tmpFiles:File[]= [];
      document.getElementById('closeAttachmentFormButton')?.click();
      for(let i = 0; i < this.files?.length; i++){

        console.log(this.files[i].name);
        console.log(this.files[i].type);
        const formData = new FormData();
        formData.append('file', this.files[i]);

        this.claimService.addAttachmentWithAdditionalParams(null, formData, null, type).subscribe(
          (res) => {
            tmpFiles.push(this.files[i]);
            console.log(res);

            if(this.files?.length == tmpFiles.length) {
              // this.toastr.success('Successfully reported a bug', 'Report a bug', {
              //   positionClass: 'toast-top-center',
              // });
              this.bugReportCall(comment, type);
              this.removeFiles();
            }
          },
          (error) => {
            console.log(error);
            this.removeFiles();
            this.reportABugSubmitted = false;
            this.reportingABug = false;

            this.toastr.error(
              'Something went wrong while reporting a bug, please try again. ' + 'Error code: '+error.status,
              'Report a bug',
              {
                positionClass: 'toast-top-center', timeOut:6000
              }
            );
          }
        );

      }
    } else {
      this.bugReportCall(comment, type);
    }

  }
  bugReportCall(comment, type) {
    this.claimService.addAttachmentWithAdditionalParams(null, null, comment, type).subscribe(
      (res) => {
        console.log(res);
          this.toastr.success('Successfully reported a bug', 'Report a bug', {
            positionClass: 'toast-top-center',
          });
          this.reportABugSubmitted = false;
          this.reportingABug = false;
          document.getElementById('closeReportABugModalButton').click();
          this.reportABugForm.controls.reportABugText.setValue(null);

          this.removeFiles();
        
      },
      (error) => {
        console.log(error);
        this.removeFiles();
        this.reportABugSubmitted = false;
        this.reportingABug = false;

        this.toastr.error(
          'Something went wrong while reporting a bug, please try again. ' + 'Error code: '+error.status,
          'Report a bug',
          {
            positionClass: 'toast-top-center', timeOut:6000
          }
        );
      }
    );
  }
  onSelectFiles($event) {
    if (!this.files || this.files.length <= 0) {
      this.files = $event.addedFiles;
      //this.uploadFiles();
    }
    else {
      for (let i = 0; i < $event?.addedFiles?.length; i++)
        this.files.push($event?.addedFiles[i]);
      // this.uploadFiles();
    }
  }

  removeFiles(){
    this.files=[];
}
onRemove(i:number){
  if(i >= 0 && i < this.files?.length)
    this.files.splice(i, 1);
}
}
