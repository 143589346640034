import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ClaimService } from '../_services/claim.service';
import { ContractService } from '../_services/contract.service';
import { OktaAuthService } from '@okta/okta-angular';
import { interval, Subscription } from 'rxjs';
import { Claim } from '../_model/claim';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit, OnDestroy {
  @Output() messageEvent = new EventEmitter<boolean>();
  checkBoxAgree: FormControl;
  isVinInvalid: boolean = false;
  isLastNameInvalid: boolean = false;
  searchContractForm: FormGroup;
  isAuthenticated: boolean;
  userName: string;
  error: Error;
  claimList: Claim[] = [];
  draftList: Claim[] = [];
  actionList: Claim[] = [];
  actionLoading: boolean = true;
  activeLoading: boolean = true;
  draftLoading: boolean = true;
  submitted: boolean = false;
  disableSearchButton: boolean = false;

  totalActionClaims:number = 0;
  totalActiveClaims:number = 0;
  totalDraftClaims:number = 0;

  account_number: string;
  account_name: string;
  user_name: string;
  user_email: string;
  user_roles: string;
  user_phone: string;
  user_accepts_text_msgs: boolean;
  addr_ln1: string;
  addr_ln2: string;
  state_abbr: string;
  zip_code: string;
  currentSortField: string = '';
  sortTableIndicator: number = 0;
  sortFieldAndDirection: string = "default";
  public href: string = '';
  counter: number = 0;
  hasNextDraft: boolean = false;
  hasPreviousDraft: boolean = false;
  header:string="Delete Claim";
  message:string = "";
  buttonMessage:string="Delete"
  indexNum:number = 0;
  deleteClaimId: string = "";
  statusUpdateSubscription: Subscription;
  paymentRequestForm: FormGroup;
  paymentRequesting: boolean = false;
  paymentRequestSubmitted: boolean = false;
  files: File[] = [];
  claimId: string;
  constructor(
    private readonly fb: FormBuilder,
    private router: Router,
    private claimService: ClaimService,
    private contractService: ContractService,
    public oktaAuth: OktaAuthService,
    private toastr: ToastrService
  ) {
    this.searchContractForm = this.fb.group({
      vinNumber: new FormControl(
        '',
        Validators.compose([
          Validators.maxLength(17),
          Validators.minLength(17),
          Validators.required,
        ])
      ),
      lastName: [, Validators.required],
    });
    this.oktaAuth.$authenticationState.subscribe(
      (isAuthenticated) => (this.isAuthenticated = isAuthenticated)
    );
  }
  async ngOnInit1() {
    this.loadAllClaims();
    this.claimService.accountName = "Jonas Reed";
  }
  async ngOnInit() {
    this.toastr.clear();
    this.paymentRequestForm = this.fb.group({
      paymentRequestText: [, Validators.required]
    });
    let isOktaEnabled = environment.isOktaEnabled;
    this.href = this.router.url;
    console.log(this.router.url);
    
    if (this.href.toString().includes('params')) {
      this.href = this.href.substring(9, this.href.length);
      console.log(atob(this.href));
      let userInfo = this.parseMyAccountData(atob(this.href));
      localStorage.setItem('myAccountData', JSON.stringify(userInfo));
      localStorage.setItem('userInfoData', this.href);
    }
    this.isAuthenticated = await this.oktaAuth.isAuthenticated();
    if(!isOktaEnabled) {
      if (!localStorage.getItem('userInfoData') || !localStorage.getItem('myAccountData')) {
        this.redirectToSalesforce();
      }

      if(localStorage.getItem('userInfoData')) {
        this.claimService.userInfoData = localStorage.getItem('userInfoData');
      }

      if(localStorage.getItem('myAccountData')){

        //emit value in sequence every 5 second
        const source = interval(5000);
        this.statusUpdateSubscription = source.subscribe(val => this.getUpdatedClaims());


        let userInfo = JSON.parse(localStorage.getItem('myAccountData'));
        this.userName = userInfo.user_name;
        this.claimService.accountName = userInfo.account_name;
        this.claimService.accountNumber = userInfo.account_number;
        this.claimService.email = userInfo.user_email;
        this.claimService.userName = userInfo.user_name;
        this.claimService.userId = userInfo.user_id;
      }

      this.loadAllClaims();
      this.loadSellerInfo();
      this.getUserConfig();
      
    }
    else if (this.isAuthenticated) {
      const userClaims = await this.oktaAuth.getUser();
      this.userName = userClaims.name;

      if (!localStorage.getItem('userInfoData') || !localStorage.getItem('myAccountData')) {
        this.redirectToSalesforce();
      }

      if(localStorage.getItem('userInfoData')) {
        this.claimService.userInfoData = localStorage.getItem('userInfoData');
      }

      if(localStorage.getItem('myAccountData')){ 

      let userInfo = JSON.parse(localStorage.getItem('myAccountData'));
      console.log(userInfo);
      this.claimService.accountName = userInfo.account_name;
      this.claimService.accountNumber = userInfo.account_number;
      this.claimService.email = userInfo.user_email;
      this.claimService.userId = userInfo.user_id;

      this.claimService.userName = userInfo.user_name;

      const source = interval(5000);
      this.statusUpdateSubscription = source.subscribe(val => this.getUpdatedClaims());
      }
      
      this.loadAllClaims();
      this.loadSellerInfo();
      this.getUserConfig();
      //console.log(atob(localStorage.getItem('myAccountData')));
    } else {
      this.login();
    }

  }

  redirectToSalesforce() {
    document.location.href = environment?.salesforceUrl;
  }

  get fPaymentRequest(){
    return this.paymentRequestForm.controls;
  }
  paymentRequestClose() {
    this.paymentRequestForm.controls.paymentRequestText.setValue(null);
    this.paymentRequestSubmitted = false;
    this.paymentRequesting = false;
  }
  resetPaymentRequest() {
    this.paymentRequestForm.controls.paymentRequestText.setValue(null);
    this.paymentRequestSubmitted = false;
    this.paymentRequesting = false;
  }
  ngOnDestroy() {
    this.statusUpdateSubscription.unsubscribe();
  }
  async login() {
    try {
      await this.oktaAuth.signInWithRedirect();
    } catch (err) {
      console.error(err);
      this.error = err;
    }
  }

  getUpdatedClaims(){
    this.claimService.getEventLog(10).subscribe((result:any)=>{
      // result = [
      //     {
      //       "claimId": "53A7F136-167C-4E3F-A6D1-EF89211C2CA6",
      //       "claimStatus": "Payment issued",
      //       "claimStatusCode": 60,
      //       "dealership": "123456",
      //       "desc": "Claim 12345 was updated. Status changed from Claim under review to Payment issued",
      //       "time": "2022-03-29T12:03:23.877Z"
      //     }
      //   ];
      if(result?.length > 0){
        this.loadAllClaims();


        // for(let i = 0; i< result?.length; i++){
        //   if(result[i]?.claimId){
        //     for(let k = 0; k < this.claimList?.length; k++){
        //       if(result[i].claimId == this.claimList[k].id){
        //         this.claimList[k].statusCode = result[i].claimStatusCode;
        //         this.claimList[k].status = result[i].claimStatus;
        //       }
        //     }
        //   }
        // }
      }
    },
     // this.toastr.info(
            //   result[i].desc,
            //   'Updated claim status',
            //   {
            //     timeOut:0,
            //     extendedTimeOut:0,
            //     positionClass: 'toast-top-center',
            //   }
            // );
    (error) => {
      console.log(error);
      if(error?.status && error.status == 401){
        this.statusUpdateSubscription.unsubscribe();
      }
    });
  }
  get f() {
    return this.searchContractForm.controls;
  }
  goToNextPage() {
    this.messageEvent.emit(true);
  }
  getUserConfig() {
    this.claimService.getUserConfig().subscribe((value:any)=>{
      if (value.firstLoginAfterUpgrade) {
        document.getElementById("releaseNotesLink").click();
      }
      }, error=>{
            console.log(error);
      }
     );
  }
  onClickSearch() {
    this.submitted = true;
    console.log(this.searchContractForm.controls.value);
    this.isVinInvalid = false;
    this.isLastNameInvalid = false;

    if (!this.searchContractForm.valid) {
      if (!this.searchContractForm.controls.vinNumber.valid) {
        this.isVinInvalid = true;
      }
      if (!this.searchContractForm.controls.lastName.valid)
        this.isLastNameInvalid = true;

      this.toastr.error(
        "Enter the 17 character VIN and customer's last name",
        'Error',
        {
          positionClass: 'toast-top-center',
        }
      );
      return;
    }
    this.submitted = false;
    let contractInfo = {
      lastName: this.searchContractForm.controls.lastName.value,
      vin: this.searchContractForm.controls.vinNumber.value,
    };
    
    this.disableSearchButton = true;

    this.contractService.searchContract(contractInfo).subscribe(
      (res: any) => {
        console.log(res);
        this.toastr.clear();
        if(!res || !res?.Contracts || res?.Contracts?.length == 0){
          this.toastr.info('No results found', 'Search info', {
            positionClass: 'toast-top-center',
          });
          this.disableSearchButton = false;
          return;
        }

        let tmpContracts = [];
        let counter: number = 0;
        for(let i = 0; i < res.Contracts.length; i++) //&& (res.Contracts[i].productCode?.toLowerCase() == "VSC".toLowerCase() || res.Contracts[i].productCode?.toLowerCase() == "TW".toLowerCase())
          if((res.Contracts[i].customer?.lastName?.toLowerCase() == this.searchContractForm.controls.lastName.value?.trim()?.toLowerCase())){
            tmpContracts[counter] = res.Contracts[i];
            counter++;
          }
        if(!tmpContracts || tmpContracts?.length == 0){
          this.toastr.info('No results found', 'Search info', {
            positionClass: 'toast-top-center',
          });
          this.disableSearchButton = false;
          return;
        }
        this.contractService.contracts.next(tmpContracts);

        this.claimService.currentUrl.next('NONE');
        this.router.navigate(['/search-contract-results']);
      },
      (error) => {
        this.disableSearchButton = false;
        console.log(error);
        if(error.status == 404)
          this.toastr.info('No results found', 'Search info', {
            positionClass: 'toast-top-center',
          });
        else
          this.toastr.error('Something went wrong during your search, please try again. ' + 'Error code: '+error.status, 'Search info', {
            positionClass: 'toast-top-center', timeOut:12000
          });
      }
    );
  }

  loadSellerInfo() {
    this.claimService.getSellerInfoById(this.claimService.accountNumber).subscribe((value:any)=>{
            
      this.claimService.billingMethod = value?.billingMethod;
      this.claimService.laborBillingRate = value?.laborRate;
      this.claimService.partTax = value?.partTax;
      this.claimService.laborTax = value?.laborTax;
      this.claimService.payeeNameLabel = value?.name;
      this.claimService.paymentMethod = value?.paymentMethod;
      this.claimService.partMarkup = value?.partMarkup;
      this.claimService.faxesSeller = value?.faxes;
      this.claimService.emailsSeller = value?.emails;
      this.claimService.billingEditable = value?.billingEditable;
      this.claimService.automationEnabled = value?.automationEnabled;
      this.claimService.selfAuthMax = value?.selfAuthMax;
      
      setTimeout(()=>this.claimService.sellerDataReceived.next(true),0);

    },

    (error)=> {
      console.log(error);
      this.toastr.error(
        'Something went wrong while fetching seller info, please try again. ' +
          'Error code: ' +
          error.status,
        'Create claim',
        {
          positionClass: 'toast-top-center',
        }
      );
    });
  }

  loadAllClaims() {
    let accountNumber = '&accountNumber=' + this.claimService.accountNumber;
    let userIdParam = '&userId=' + encodeURIComponent(this.claimService.userId);

    if(this.sortTableIndicator == 0){
      this.claimService.getAllActive(userIdParam + accountNumber, "&size=10").subscribe(
        (res: any) => {
          this.claimList = [];
          this.claimList = res;
          this.activeLoading = false;
        },
        (error) => {
          console.log(error);
          this.toastr.error('Something went wrong while fetching active claims, please try again. ' + 'Error code: '+error.status, 'Home page', {
            positionClass: 'toast-top-center', timeOut:12000
          });
          this.activeLoading = false;
        }
      );
    } else {
      --this.sortTableIndicator;
      if(this.sortTableIndicator < 0){
        this.sortTableIndicator = 0;
      }
      this.sort(this.currentSortField, true);
    }
    this.claimService.getClaimsCount("?v=ACTIVE" + accountNumber + userIdParam).subscribe((value:any)=>{
      this.totalActiveClaims = value;
      }, error=>{
            console.log(error);
            this.toastr.error('Something went wrong while fetching number of active claims, please try again. ' + 'Error code: '+error.status, 'Active Claims', {
              positionClass: 'toast-top-center'
            });
      }
     );


    this.claimService.getAllDraft(userIdParam + accountNumber, "").subscribe(
      (res: any) => {
        this.draftList = [];
        this.counter = 0;
        this.hasPreviousDraft = false;
        for(let i = 0; i< res?.length; i++){
          if(res[i].statusCode == 10)
            this.draftList.push(res[i]);
        }
        this.totalDraftClaims = this.draftList.length;
        if(this.draftList?.length > 3){
          this.hasNextDraft = true;
        }
        this.draftLoading = false;
      },
      (error) => {
        console.log(error);
        this.toastr.error('Something went wrong while fetching draft claims, please try again. ' + 'Error code: '+error.status, 'Home page', {
          positionClass: 'toast-top-center'
        });
        this.draftLoading = false;
      }
    );
    this.claimService.getClaimsCount("?v=DRAFT&statusCode_eq=10" + accountNumber + userIdParam).subscribe((value:any)=>{
      this.totalDraftClaims = value;
      if(!this.draftLoading && value > this.draftList?.length){
        this.totalDraftClaims = this.draftList.length;
      }
      }, error=>{
            console.log(error);
            this.toastr.error('Something went wrong while fetching number of draft claims, please try again. ' + 'Error code: '+error.status, 'Active Claims', {
              positionClass: 'toast-top-center', timeOut:12000
            });   
      }
     );

    this.claimService.getAllAction(userIdParam + accountNumber, "&size=3").subscribe(
      (res: any) => {
        this.actionList = res;
        this.actionLoading = false;
      },
      (error) => {
        console.log(error);
        this.toastr.error('Something went wrong while fetching action claims, please try again. ' + 'Error code: '+error.status, 'Home page', {
          positionClass: 'toast-top-center', timeOut:12000
        });
        this.actionLoading = false;
      }
    );
    this.claimService.getClaimsCount("?v=ACTION" + accountNumber + userIdParam).subscribe((value:any)=>{
      this.totalActionClaims = value;
      }, error=>{
            console.log(error);
            this.toastr.error('Something went wrong while fetching number of action claims, please try again. ' + 'Error code: '+error.status, 'Action Claims', {
              positionClass: 'toast-top-center', timeOut:12000
            }); 
      }
     );
  }

  continueWithClaim(claim: Claim) {
    if(claim.statusCode != 70){
      this.claimService.claim.next(claim);
    if (claim.jobs) 
      this.claimService.job.next(claim.jobs[0]);
      
    this.claimService.currentStep = claim.step;

    if(claim.step == 0)
        this.claimService.editClaim = true;
    else
      this.claimService.editClaim = false;

    this.claimService.previewActiveClaim = false;
    this.claimService.startClaimFromSearch = false;
    this.claimService.currentStep = claim.step;
    this.claimService.currentUrl.next('NONE');
    this.router.navigate(['/new-claim']);
    } else {
      {
        this.claimService.editClaim = true;
        this.claimService.previewActiveClaim = true;
        this.claimService.startClaimFromSearch = false;
        this.claimService.claim.next(claim);
        if (claim.jobs)
          this.claimService.job.next(claim.jobs[0]);
        this.claimService.currentStep = 3;
        this.claimService.currentUrl.next("NONE");
        this.router.navigate(['/new-claim']);
      }
    }

  }
  continueToDetails(claim: Claim) {
    this.claimService.claim.next(claim);
    this.claimService.currentUrl.next('NONE');
    this.router.navigate(['/submit']);
  }
  continueToStepper(claim:Claim){
    this.claimService.editClaim = true;
    this.claimService.previewActiveClaim = true;
    this.claimService.startClaimFromSearch = false;
    this.claimService.claim.next(claim);
    if (claim.jobs)
      this.claimService.job.next(claim.jobs[0]);
    this.claimService.currentStep = 3;
    this.claimService.currentUrl.next("NONE");
    this.router.navigate(['/new-claim']);
  }
  setDraft() {
    this.claimService.currentUrl.next('DRAFT');
    this.router.navigate(['/drafts']);
  }
  setAll() {
    this.claimService.currentUrl.next('ALL');
    this.router.navigate(['/claims']);
  }
  getNoteForActionClaim(actionClaim){
    if(actionClaim?.notes && actionClaim?.notes?.length > 0)
      for(let i = actionClaim?.notes?.length - 1; i >= 0; i--){
        if(actionClaim.notes[i].noteTypeDescription == "X-COMMUNICATION")
          return actionClaim.notes[i].text;
      }
    return "No notes found";
  }
  increaseCounter(){
    if(this.counter >= this.draftList?.length){
      this.hasNextDraft = false;
      return;
    }
    

    if(this.counter+3 < this.draftList?.length){
      this.counter += 3;
      if((this.draftList?.length - this.counter) < 3){
        this.hasNextDraft = false;
      } else {
        this.hasNextDraft = true;
      }
      this.hasPreviousDraft = true;
      return;
    } else if(this.counter+2 < this.draftList?.length){
      if(this.draftList?.length %3 == 2){
        this.counter += 2;
        this.hasNextDraft = false;
        this.hasPreviousDraft = true;
        return;
      }
    } else if(this.counter+1 < this.draftList?.length){
      if(this.draftList?.length %3 == 1){
        this.counter += 1;
        this.hasNextDraft = false;
        this.hasPreviousDraft = true;
        return;
      }
    }
    this.hasNextDraft = false;
    if(this.counter > 3){
      this.hasPreviousDraft = true;
    } else {
      this.hasPreviousDraft = false;
    }
    
  }
  decreaseCounter(){
    if((this.draftList?.length - this.counter) > 0 && this.draftList?.length > 3){
      this.hasNextDraft = true;
    }
    if(this.counter >= 3)
      this.counter -= 3;

    if(this.counter < 3){
      this.counter = 0;
      this.hasPreviousDraft = false;
    }
  }
  parseMyAccountData(data) {
    let userInfo: any = {};
    // data =
    //   'account_number=BPAA639   	account_name=PREMIER SUBARU WATERTOWN	user_name=Aleksandar Matic	user_email=aleksandar.matic@zurich.ch	user_roles=DLR_Service Advisor	user_phone=3876597735	user_accepts_text_msgs=true	addr_ln1=795 Straits Tpke	addr_ln2=	city=Watertown	state_abbr=CT	zip_code=06795 3320';
    var splitted = data.split('\t', 13);
    for (let index = 0; index < splitted.length; index++) {
      const element = splitted[index];
      var innerSplitpted = element.split('=', 2);
      userInfo[innerSplitpted[0]] = innerSplitpted[1].trim();
    }
    this.claimService.accountName = userInfo.account_name;
    return userInfo;
  }
  setClaimDelete(claimId: string){
    this.deleteClaimId = claimId;
    this.message="Are you sure you want to delete this draft claim?";
  }
  deleteClaim($event){
    if($event){
      this.claimService.deleteClaim(this.deleteClaimId).subscribe((result:any)=>{
        this.draftLoading = true;
        this.claimService.getAllDraft('&userId=' +encodeURIComponent(this.claimService.userId) + '&accountNumber=' + this.claimService.accountNumber, "").subscribe(
          (res: any) => {
            this.draftList = [];
            for(let i = 0; i< res?.length; i++){
              if(res[i].statusCode == 10)
                this.draftList.push(res[i]);
            }
            this.totalDraftClaims = this.draftList.length;
            if(this.draftList?.length > 3){
              this.hasNextDraft = true;
            }
            this.draftLoading = false;
          },
          (error) => {
            console.log(error);
            this.toastr.error('Something went wrong while fetching draft claims, please try again. ' + 'Error code: '+error.status, 'Home page', {
              positionClass: 'toast-top-center'
            });
            this.draftLoading = false;
          }
        );
        this.claimService.getClaimsCount("?v=DRAFT&statusCode_eq=10" + '&userId=' + encodeURIComponent(this.claimService.userId) + '&accountNumber=' + this.claimService.accountNumber).subscribe((value:any)=>{
          this.totalDraftClaims = value;
          if(!this.draftLoading && value > this.draftList?.length){
            this.totalDraftClaims = this.draftList.length;
          }
          }, error=>{
                console.log(error);
                this.toastr.error('Something went wrong while fetching number of draft claims, please try again. ' + 'Error code: '+error.status, 'Active Claims', {
                  positionClass: 'toast-top-center', timeOut:12000
                });   
          }
         );
        document.getElementById("closeConfirmActionButton0").click();
        this.toastr.success('Successfully deleted claim', '', {
          positionClass: 'toast-top-center',
        });
      }, (error) => {
        console.log(error);
        this.toastr.error(
          'Something went wrong while deleting claim, please try again. ' + 'Error code: '+error.status,
          'Create claim',
          {
            positionClass: 'toast-top-center',
          }
        );
      })
    }
  }
  sort(field:string, showSpinner?: boolean){
    if(field !== this.currentSortField){
      this.sortTableIndicator = 1;
      this.currentSortField = field;
    } else {
      this.sortTableIndicator = ++this.sortTableIndicator % 3;
    }
   
    let userIdParam = '&userId=' + encodeURIComponent(this.claimService.userId) + '&accountNumber=' + this.claimService.accountNumber;
    let url: string = "?v=ACTIVE";
    let size: string = "&size=10";
    switch(this.sortTableIndicator){
      //DEFAULT SORT
      case 0:{
        //this.claimList = [];
        if(!showSpinner)
          this.activeLoading = true;
        this.sortFieldAndDirection = "default";
    this.claimService.getAllActive(userIdParam, "&size=10").subscribe(
      (res: any) => {
        this.claimList = res;
        this.activeLoading = false;
      },
      (error) => {
        console.log(error);
        this.toastr.error('Something went wrong while fetching active claims, please try again. ' + 'Error code: '+error.status, 'Home page', {
          positionClass: 'toast-top-center', timeOut:12000
        });
        this.activeLoading = false;
      }
      
    );
        break;
      }
      //ASCENDING
      case 1:{
        //this.claimList = [];
        console.log(this.sortTableIndicator);
        if(!showSpinner)
          this.activeLoading = true;
        let sortQuery:string = "&sort=" + field + ",asc";
        this.sortFieldAndDirection = field + "ASC";

        this.claimService.getFilteredClaims(url + userIdParam + size + sortQuery).subscribe(
          (res: any) => {
            this.claimList = res;
            this.activeLoading = false;
          },
          (error) => {
            console.log(error);
            this.toastr.error('Something went wrong while fetching active claims, please try again. ' + 'Error code: '+error.status, 'Home page', {
              positionClass: 'toast-top-center', timeOut:12000
            });
            this.activeLoading = false;
          }
          
        );
        break;
      }
      //DESCENDING
      case 2:{
        //this.claimList = [];
        if(!showSpinner)
          this.activeLoading = true;
        let sortQuery:string = "&sort=" + field + ",desc";
        this.sortFieldAndDirection = field + "DESC";

        this.claimService.getFilteredClaims(url + userIdParam + size + sortQuery).subscribe(
          (res: any) => {
            this.claimList = res;
            this.activeLoading = false;
          },
          (error) => {
            console.log(error);
            this.toastr.error('Something went wrong while fetching active claims, please try again. ' + 'Error code: '+error.status, 'Home page', {
              positionClass: 'toast-top-center', timeOut:12000
            });
            this.activeLoading = false;
          }
          
        );
        console.log(this.sortTableIndicator);
        break;
      }
    }
  }
  setClaimId(claimId) {
    this.claimId = claimId;
  }
  submitPaymentRequestForm() {
    this.paymentRequestSubmitted = true;
   
    let type = "PAYMENT_REQUEST";
    if (!this.paymentRequestForm.valid || !this.paymentRequestForm.controls.paymentRequestText?.value || this.paymentRequesting) {
      this.paymentRequesting = false;
      return;
    }
    let types: string = "image/bmp,text/csv,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/gif,text/html,image/vnd.microsoft.icon,image/jpeg,application/pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.rar,application/rtf,image/tiff,text/plain,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/xml,text/xml,application/zip,image/x-png,image/png";
    let typeList:string[] = types.split(',');

    this.paymentRequesting = true;
    let comment = this.paymentRequestForm.controls.paymentRequestText?.value;
    if(this.files && this.files.length > 0){

      let tmpFiles:File[]= [];
      for(let i = 0; i < this.files?.length; i++){
      if(typeList.some((type)=>this.files[i].type == type)) {
        console.log(this.files[i].name);
        console.log(this.files[i].type);
        const formData = new FormData();
        formData.append('file', this.files[i]);

        this.claimService.addAttachmentWithAdditionalParams(this.claimId, formData, null, type).subscribe(
          (res) => {
            tmpFiles.push(this.files[i]);
            console.log(res);

            if(this.files?.length == tmpFiles.length) {
              // this.toastr.success('Successfully requested payment', 'Request payment', {
              //   positionClass: 'toast-top-center',
              // });
              this.requestPaymentCall(comment, type);

              this.paymentRequestForm.controls.paymentRequestText?.value;
              this.removeFiles();
            }
          },
          (error) => {
            console.log(error);
            this.removeFiles();
            this.paymentRequestSubmitted = false;
            this.paymentRequesting = false;

            this.toastr.error(
              'Something went wrong while requesting payment, please try again. ' + 'Error code: '+error.status,
              'Request payment',
              {
                positionClass: 'toast-top-center', timeOut:6000
              }
            );
          }
        );

      } else {
        this.paymentRequestSubmitted = false;
        this.paymentRequesting = false;
        this.toastr.error(
          'File: "' + this.files[i].name + '" has unsuported type.',
          'Attachments',
          {
            positionClass: 'toast-top-center', timeOut:6000
          }
        );
      }
    } 
    } else {
      this.requestPaymentCall(comment, type);
    }

  }
  requestPaymentCall(comment, type) {
    this.claimService.addAttachmentWithAdditionalParams(this.claimId, null, comment, type).subscribe(
      (res) => {
        console.log(res);
          this.toastr.success('Successfully requested payment', 'Request Payment', {
            positionClass: 'toast-top-center',
          });
          document.getElementById('paymentRequestModalButton').click();
          this.paymentRequestForm.controls.paymentRequestText?.value;
          this.removeFiles();
          this.paymentRequestSubmitted = false;
          this.paymentRequesting = false;
          this.claimId = null;
        
      },
      (error) => {
        console.log(error);
        this.removeFiles();
        this.paymentRequestSubmitted = false;
        this.paymentRequesting = false;

        this.toastr.error(
          'Something went wrong while requesting payment, please try again. ' + 'Error code: '+error.status,
          'Request payment',
          {
            positionClass: 'toast-top-center', timeOut:6000
          }
        );
      }
    );
  }
  onSelectFiles($event) {
    if (!this.files || this.files.length <= 0) {
      this.files = $event.addedFiles;
      //this.uploadFiles();
    }
    else {
      for (let i = 0; i < $event?.addedFiles?.length; i++)
        this.files.push($event?.addedFiles[i]);
      // this.uploadFiles();
    }
  }

  removeFiles(){
    this.files=[];
}
onRemove(i:number){
  if(i >= 0 && i < this.files?.length)
    this.files.splice(i, 1);
}
}
