<!-- Button to Open the Modal -->

<div class="card-body pd-b-0">
  <div class="row mg-b-30 justify-content-center">
    <div class="column justify-content-md-center align-middle">
      <div *ngIf="!showJobSummary" class="row justify-content-center">
        <i class="ion-briefcase fa-5x tx-primary"></i>
      </div>
      <div *ngIf="!showJobSummary" class="row justify-content-center">
        <p [ngClass]="{ 'tx-danger': noJobsAdded }">
          No jobs added. Please add a new job.
        </p>
    </div>
      <div *ngIf="!disabledField" class="row justify-content-center">
        <button
            data-toggle="modal"
            data-target="#newJobModal"
            class="btn btn-oblong btn-primary tx-white btn-sm mg-r-5 btn-zurich-border"
            id="openModalButton"
            (click)="resetForm()"
            type="button"
          >
          <i class="fa fa-plus pd-r-10" style="font-size: 10px;"></i> 
          Add New Job
        </button>
        <button
        data-toggle="modal"
        data-target="#newRoadsideModal"
        id="openRentalModalButton"
        class="btn btn-oblong btn-primary tx-white btn-sm mg-r-5 btn-zurich-border"
        [disabled]="disabledField"
        (click)="resetRentalForm()"
        type="button"
        >
          <i class="fa fa-plus pd-r-10" style="font-size: 10px;"></i> 
        Add New Rental/Roadside Assistance
        </button>
      </div>
      <div *ngIf="disabledField" class="row justify-content-center">
        <button
            data-toggle="modal"
            data-target="#updateClaim"
            class="btn btn-oblong btn-primary tx-white btn-sm mg-r-5 btn-zurich-border"
            id="openModalButton"
            type="button"
          >
          <i class="fa fa-plus pd-r-10" style="font-size: 10px;"></i> 
          Add New Job
        </button>
        <button
        data-toggle="modal"
        data-target="#updateClaim"
        id="openRentalModalButton"
        class="btn btn-oblong btn-primary tx-white btn-sm mg-r-5 btn-zurich-border"
        type="button"
        >
          <i class="fa fa-plus pd-r-10" style="font-size: 10px;"></i> 
        Add New Rental/Roadside Assistance
        </button>
      </div>

     
    </div>
  </div>
  <app-job-summary *ngIf="showJobSummary" (editJobEvent)="editJob($event)"></app-job-summary>
  <div class="row justify-content-between pd-b-20">
    <div class="row justify-content-start-custom">
    <button
    type="button"
    class="btn btn-oblong btn-info btn btn-block mg-t-8 mg-r-30 chevron-button"
    (click)="setClaimDelete()"
    data-toggle="modal"
    attr.data-target="#confirmActionModal{{indexNum}}"
    id="deleteClaimButtonFromClaimInfo"
    [disabled]="disabledField"
    >
    Delete
  </button>
</div>
  <div>
    <div class="row justify-content-end-custom">
  <button
          type="button"
          class="btn btn-oblong btn-info btn btn-block mg-t-8 chevron-button"
          [disabled]="disabledField"
          (click)="saveAsDraft()"
          
        >
          <span
            *ngIf="showSpinnerDraft"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Save as Draft
        </button>
    <button
      (click)="goToNextPage()"
      class="btn btn-oblong btn-primary btn btn-block mg-xs-l-30 mg-sm-l-30 mg-md-l-30  mg-lg-l-30 mg-xl-l-30 mg-xl-r-30 btn btn-block chevron-button"
      >
      Continue
    </button>
    
    <div *ngIf="pressedContinue"  class="spinner-border row align-self-center mg-l-15" style="width: 2rem; height: 2rem;" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>
  </div>
  <app-confirm-action 
  [header]="header"  
  [message]="message"
  [buttonMessage]="buttonMessage"    
  [indexNum]="indexNum"
  (confirmActionEvent)="deleteClaim($event)"
></app-confirm-action>
</div>

<!-- The JOB Modal -->

<div class="modal" id="newJobModal">
  <div class="modal-dialog modal-lg modal-job-width" >
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Add New Job</h4>
        <button type="button" class="close" data-dismiss="modal">
          &times;
        </button>
      </div>

      <form
        fxLayout="column"
        [formGroup]="claimDetailsForm"
        (ngSubmit)="submitForm()"
      >
        <!-- Modal body -->
        <div class="modal-body pd-x-20 modal-job-width">
          <div class="row">
            <div class="col-sm-6 col-12">
              <div class="form-group mg-b-10-force">
                <label class="form-control-label"
                  >Job Component <span class="tx-danger">*</span><span
                  *ngIf="jobComponents?.length <= 0"
                  class="spinner-border mg-l-3 spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span></label
                >
                <select
                tabindex="1"
                *ngIf="!disableJobComponentField"
                  (blur)="setStyle()"
                  [formControlName]="'jobComponent'"
                  [ngClass]="{
                    'is-invalid': submitted && f.jobComponent.errors
                  }"
                  class="form-control select2"
                  data-placeholder="Select job component"
                  #selectFieldA
                >
                
                  <option
                    label="Select"
                    [selected]="!editJobBoolean"
                    [hidden]="true"
                  ></option>
                  <option
                    *ngFor="let component of jobComponents"
                    [ngValue]="component"
                  >
                    {{ component.name }}
                  </option>
                  
                </select>
                <input
                *ngIf="disableJobComponentField"
                disabled
                  class="form-control"
                  value=""
                  [formControlName]="'jobComponentIndividual'"
                />
              </div>
            </div>
            <div class="col-sm-6 col-12">
              <!-- col-4 -->

              <!-- col-4 -->

              <div class="form-group mg-b-10-force">
                <label class="form-control-label"
                  >Job Loss Code Description
                  <span class="tx-danger">*</span></label
                >
                <!-- <input id="typeahead-format" (focus)="focus()" type="text" class="form-control" [formControlName]="'model'" [ngbTypeahead]="search" [resultFormatter]="formatter" /> -->
                <input
                tabindex="2"
                id="typeahead-focus"
                type="text"
                [resultFormatter]="formatter"
                [inputFormatter]="formatter"
                class="form-control"
                [formControlName]="'model'"
                [ngbTypeahead]="search"
                (focus)="focus$.next($any($event).target.value)"
                (click)="setStyle()"
                #instance="ngbTypeahead"
                (selectItem)="itemSelected($event)"
                [ngClass]="{
                  'is-invalid': submitted && f.model.errors
                }"
              />
              </div>
            </div>
              <div class="col-12 pd-b-10 tx-danger">*If the Job Component or Job Loss Code Description value does not display, it is not a valid benefit for this contract.</div>

            <div class="col-sm-6 col-12">
              <div class="form-group" *ngIf="isWheelAndTireSelected">
                <label class="form-control-label"
                  >Tire Tax per tire</label
                >
                    <input
                    tabindex="14"
                      class="form-control"
                      name="tireTaxPrice"
                      value="$ .00"
                      (blur)="transformAmount($event, 'tireTax')" [(ngModel)]="formattedTireTaxPrice"
                      placeholder="$ .00"
                      [formControlName]="'tireTaxPrice'"
                      [ngClass]="{
                        'is-invalid': (submitted && f.tireTaxPrice.errors) || tireTaxPriceError
                      }"
                    />
                    <div *ngIf="(submitted && f.tireTaxPrice.errors) || tireTaxPriceError" class="tx-danger">
                      Tire tax should be a value between $0.00 and $5.00.
                    </div>
                  </div>

              <!-- transmission fields -->

              <div class="form-group" *ngIf="isTransmissionSelected">
                <label class="form-control-label"
                  >What is the transmission fluid level and condition? <span class="tx-danger">*</span></label
                >
                <input
                tabindex="8"
                  class="form-control"
                  type="text"
                  name="transmissionFluidLevelAndCondition"
                  value=""
                  placeholder="Enter answer"
                  [formControlName]="'transmissionFluidLevelAndCondition'"
                  [ngClass]="{
                    'is-invalid':
                      submitted && f.transmissionFluidLevelAndCondition.errors
                  }"
                />
              </div>


              <div class="form-group" *ngIf="isTransmissionSelected">
                <label class="form-control-label"
                  >Describe internal parts or repairs offered by the factory
                  <span class="tx-danger">*</span></label
                >
                <input
                  tabindex="10"
                  (blur)="setStyle()"
                  class="form-control"
                  type="text"
                  name="transmissionFactoryOfferInternalParts"
                  value=""
                  placeholder="Enter answer"
                  [formControlName]="'transmissionFactoryOfferInternalParts'"
                  [ngClass]="{
                    'is-invalid':
                      submitted &&
                      f.transmissionFactoryOfferInternalParts.errors
                  }"
                />
              </div>

              <div
                class="form-group mg-b-10-force"
                *ngIf="isTransmissionSelected"
              >
                <label class="form-control-label"
                  >Any fault codes present?
                  <span class="tx-danger">*</span></label
                >
                <select
                  tabindex="12"
                  [formControlName]="'transmissionFaultCodesPresent'"
                  class="form-control select2"
                  data-placeholder="Select"
                  [ngClass]="{
                    'is-invalid':
                      submitted && f.transmissionFaultCodesPresent.errors
                  }"
                >
                  <option label="Select" [selected]="!editJobBoolean" [hidden]="true">
                    Select
                  </option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>

              <!-- engine fields -->

              <div class="form-group" *ngIf="isEngineSelected">
                <label class="form-control-label"
                  >What is the fluid level and condition for coolant and oil?
                  <span class="tx-danger">*</span></label
                >
                <input
                  tabindex="3"
                  class="form-control"
                  type="text"
                  name="engineFluidAndCondition"
                  value=""
                  placeholder="Enter answer"
                  [formControlName]="'engineFluidAndCondition'"
                  [ngClass]="{
                    'is-invalid': submitted && f.engineFluidAndCondition.errors
                  }"
                />
              </div>
              <!-- col-4 -->

              <!-- col-4 -->

              <div class="form-group mg-b-10-force" *ngIf="isEngineSelected">
                <label class="form-control-label"
                  >Any fault codes present?
                  <span class="tx-danger">*</span></label
                >
                <select
                tabindex="5"
                  [formControlName]="'engineFaultCodePresent'"
                  class="form-control select2"
                  data-placeholder="Select"
                  [ngClass]="{
                    'is-invalid': submitted && f.engineFaultCodePresent.errors
                  }"
                >
                  <option label="Select" [selected]="!editJobBoolean" [hidden]="true">
                    Select
                  </option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>

              <!-- col-4 -->

              <div class="form-group" *ngIf="isEngineSelected">
                <label class="form-control-label"
                  >Describe the level of tear down
                  <span class="tx-danger">*</span></label
                >
                <input
                  tabindex="7"
                  (blur)="setStyle()"
                  class="form-control"
                  type="text"
                  name="engineTearDownConfirmFailure"
                  value=""
                  placeholder="Enter answer"
                  [formControlName]="'engineTearDownConfirmFailure'"
                  [ngClass]="{
                    'is-invalid':
                      submitted && f.engineTearDownConfirmFailure.errors
                  }"
                />
              </div>
            </div>
            <!-- col-4 -->

            <div class="col-sm-6 col-12">


              <div class="form-group" *ngIf="isTransmissionSelected">
                <label class="form-control-label"
                  >Any fluid leaks? <span class="tx-danger">*</span></label
                >
                <input
                  tabindex="9"
                  (blur)="setStyle()"
                  class="form-control"
                  type="text"
                  name="transmissionFluidLeaks"
                  value=""
                  placeholder="Enter answer"
                  [formControlName]="'transmissionFluidLeaks'"
                  [ngClass]="{
                    'is-invalid': submitted && f.transmissionFluidLeaks.errors
                  }"
                />
              </div>
              <div class="form-group" *ngIf="isEngineSelected">
                <label class="form-control-label"
                  >Any fluid leaks? <span class="tx-danger">*</span></label
                >
                <input
                tabindex="4"
                  (blur)="setStyle()"
                  class="form-control"
                  type="text"
                  name="engineFluidLeaks"
                  value=""
                  placeholder="Enter answer"
                  [formControlName]="'engineFluidLeaks'"
                  [ngClass]="{
                    'is-invalid': submitted && f.engineFluidLeaks.errors
                  }"
                />
              </div>
              <div
                class="form-group"
                *ngIf="isEngineSelected"
                [hidden]="
                  !claimDetailsForm.controls.engineFaultCodePresent.value ||
                  claimDetailsForm.controls.engineFaultCodePresent.value ==
                    'false'
                "
              >
                <label class="form-control-label"
                  >Please list Engine fault codes present.
                  <span class="tx-danger">*</span></label
                >
                <input
                tabindex="6"
                  class="form-control"
                  type="text"
                  name="engineFaultCodes"
                  value=""
                  placeholder="Enter answer"
                  [formControlName]="'engineFaultCodes'"
                  [ngClass]="{
                    'is-invalid': submitted && f.engineFaultCodes.errors
                  }"
                />
              </div>
              <div class="form-group" *ngIf="isTransmissionSelected">
                <label class="form-control-label"
                  >Has any tear down been done to confirm the failure?
                  <span class="tx-danger">*</span></label
                >
                <input
                  tabindex="11"
                  class="form-control"
                  type="text"
                  name="transmissionTearDownConfirmFailure"
                  value=""
                  placeholder="Enter answer"
                  [formControlName]="'transmissionTearDownConfirmFailure'"
                  [ngClass]="{
                    'is-invalid':
                      submitted && f.transmissionTearDownConfirmFailure.errors
                  }"
                />
              </div>
              <div
                class="form-group"
                *ngIf="isTransmissionSelected"
                [hidden]="
                  !claimDetailsForm.controls.transmissionFaultCodesPresent
                    .value ||
                  claimDetailsForm.controls.transmissionFaultCodesPresent
                    .value == 'false'
                "
              >
                <label class="form-control-label"
                  >Please list Transmission fault codes present.
                  <span class="tx-danger">*</span></label
                >
                <input
                  tabindex="13"
                  class="form-control"
                  type="text"
                  name="transmissionFaultCodes"
                  value=""
                  placeholder="Enter answer"
                  [formControlName]="'transmissionFaultCodes'"
                  [ngClass]="{
                    'is-invalid': submitted && f.transmissionFaultCodes.errors
                  }"
                />
              </div>

            </div>
            <div class="col-12" *ngIf="isWheelAndTireSelected">
              Select the Damaged Tire/Wheel(s)  <span class="tx-danger">*</span>  
            </div>
          </div>
          
          <div class="row" *ngIf="isWheelAndTireSelected" [class.red-box]="submitted && noneOfTreadDepthsSelected()"> 
            <div class="col-sm-3 col-12">
              <div class="form-check form-check-inline">
                <input tabindex="15" class="form-check-input" type="checkbox" id="inlineCheckbox1" [formControlName]="'driverFront'" value="true">
                <label class="form-check-label" for="inlineCheckbox1">Driver Front</label>
              </div>
              <div class="form-group" *ngIf="f.driverFront.value">
                <label class="form-control-label">Tread depth <span class="tx-danger">*</span></label>
                <select tabindex="16" data-placeholder="Select" class="form-control" type="number" name="treadDepth" value=""
                  [formControlName]="'driverFrontTreadDepth'" [ngClass]="{
                                  'is-invalid': submitted && f.driverFrontTreadDepth.errors
                                }">
                  <option label="Select" [selected]="!editJobBoolean" [hidden]="true"></option>
                  <option value="1/32">1/32</option>
                  <option value="2/32">2/32</option>
                  <option value="3/32">3/32</option>
                  <option value="4/32">4/32</option>
                  <option value="5/32">5/32</option>
                  <option value="6/32">6/32</option>
                  <option value="7/32">7/32</option>
                  <option value="8/32">8/32</option>
                  <option value="9/32">9/32</option>
                  <option value="10/32">10/32</option>
                  <option value="11/32">11/32</option>
                  <option value="12/32">12/32</option>
                </select>
              </div>
            </div>
            <div class="col-sm-3 col-12">
              <div class="form-check form-check-inline">
                <input tabindex="17" class="form-check-input" type="checkbox" id="inlineCheckbox2" [formControlName]="'passengerFront'" value="true">
                <label class="form-check-label" for="inlineCheckbox2">Passenger Front</label>
              </div>
              <div class="form-group" *ngIf="f.passengerFront.value">
                <label class="form-control-label">Tread depth <span class="tx-danger">*</span></label>
                <select tabindex="18" data-placeholder="Select" class="form-control" type="number" name="treadDepth" value=""
                  [formControlName]="'passengerFrontTreadDepth'" [ngClass]="{
                                  'is-invalid': submitted && f.passengerFrontTreadDepth.errors
                                }">
                  <option label="Select" [selected]="!editJobBoolean" [hidden]="true"></option>
                  <option value="1/32">1/32</option>
                  <option value="2/32">2/32</option>
                  <option value="3/32">3/32</option>
                  <option value="4/32">4/32</option>
                  <option value="5/32">5/32</option>
                  <option value="6/32">6/32</option>
                  <option value="7/32">7/32</option>
                  <option value="8/32">8/32</option>
                  <option value="9/32">9/32</option>
                  <option value="10/32">10/32</option>
                  <option value="11/32">11/32</option>
                  <option value="12/32">12/32</option>
                </select>
              </div>
            </div>
            <div class="col-sm-3 col-12">
              <div class="form-check form-check-inline">
                <input tabindex="19" class="form-check-input" type="checkbox" [formControlName]="'driverRear'" id="inlineCheckbox3" value="option1">
                <label class="form-check-label" for="inlineCheckbox3">Driver Rear</label>
              </div>
              <div class="form-group" *ngIf="f.driverRear.value">
                <label class="form-control-label">Tread depth <span class="tx-danger">*</span></label>
                <select tabindex="20" data-placeholder="Select" class="form-control" type="number" name="treadDepth" value=""
                  [formControlName]="'driverRearTreadDepth'" [ngClass]="{
                                  'is-invalid': submitted && f.driverRearTreadDepth.errors
                                }">
                  <option label="Select" [selected]="!editJobBoolean" [hidden]="true"></option>
                  <option value="1/32">1/32</option>
                  <option value="2/32">2/32</option>
                  <option value="3/32">3/32</option>
                  <option value="4/32">4/32</option>
                  <option value="5/32">5/32</option>
                  <option value="6/32">6/32</option>
                  <option value="7/32">7/32</option>
                  <option value="8/32">8/32</option>
                  <option value="9/32">9/32</option>
                  <option value="10/32">10/32</option>
                  <option value="11/32">11/32</option>
                  <option value="12/32">12/32</option>
                </select>
              </div>
            </div>
            <div class="col-sm-3 col-12">
              <div class="form-check form-check-inline">
                <input tabindex="21" class="form-check-input" type="checkbox" id="inlineCheckbox4" [formControlName]="'passengerRear'" value="option1">
                <label class="form-check-label" for="inlineCheckbox4">Passenger Rear</label>
              </div>
              <div class="form-group" *ngIf="f.passengerRear.value">
                <label class="form-control-label">Tread depth <span class="tx-danger">*</span></label>
                <select tabindex="22" data-placeholder="Select" class="form-control" type="number" name="treadDepth" value=""
                  [formControlName]="'passengerRearTreadDepth'" [ngClass]="{
                                  'is-invalid': submitted && f.passengerRearTreadDepth.errors
                                }">
                  <option label="Select" [selected]="!editJobBoolean" [hidden]="true"></option>
                  <option value="1/32">1/32</option>
                  <option value="2/32">2/32</option>
                  <option value="3/32">3/32</option>
                  <option value="4/32">4/32</option>
                  <option value="5/32">5/32</option>
                  <option value="6/32">6/32</option>
                  <option value="7/32">7/32</option>
                  <option value="8/32">8/32</option>
                  <option value="9/32">9/32</option>
                  <option value="10/32">10/32</option>
                  <option value="11/32">11/32</option>
                  <option value="12/32">12/32</option>
                </select>
              </div>
            </div>
          </div>

          <div class="row pd-t-20 mg-b-5">
            <div class="col-12">
              <div class="form-group">
                <label class="form-control-label"
                  >Customer Complaint <span class="tx-danger"> *</span></label
                >
                <textarea
                tabindex="23"
                  (blur)="setStyle()"
                  rows="2"
                  class="form-control"
                  placeholder="Type Customer Complaint here"
                  [formControlName]="'customerComplaintDesc'"
                  [ngClass]="{
                    'is-invalid': (submitted && f.customerComplaintDesc.errors) || f.customerComplaintDesc?.value?.length > 900
                  }"
                  required
                ></textarea>
                <div class="row justify-content-between pd-r-15">
                  <div><label
                  class="tx-danger pd-l-15"
                  *ngIf="f.customerComplaintDesc?.errors?.maxlength"
                  >Character count cannot exceed 900
                </label></div>
                  <span>{{f.customerComplaintDesc?.value?.length}}/900</span>
                </div>
               
              </div>
            </div>
          </div>
          <div class="row mg-b-5">
            <div class="col-12">
              <div class="form-group">
                <label class="form-control-label"
                  >Cause<span class="tx-danger"> *</span></label
                >
                <textarea *ngIf="!isWheelAndTireJobSelected"
                tabindex="24"
                  rows="2"
                  class="form-control"
                  placeholder="Type Cause here"
                  [formControlName]="'causeDesc'"
                  [ngClass]="{
                    'is-invalid': (submitted && f.causeDesc.errors) || f.causeDesc?.value?.length > 900
                  }"
                  required
                ></textarea>
                <div *ngIf="!isWheelAndTireJobSelected" class="row justify-content-between pd-r-15">
                  <div>
                    <label
                    class="tx-danger pd-l-15"
                    *ngIf="f.causeDesc?.errors?.maxlength"
                   >Character count cannot exceed 900
                   </label>
                  </div>
                  <span>{{f.causeDesc?.value?.length}}/900</span>
                </div>
                <select
                tabindex="25"
                *ngIf="isWheelAndTireJobSelected"
                  [formControlName]="'causeSelect'"
                  [ngClass]="{
                    'is-invalid': submitted && f.causeSelect.errors
                  }"
                  class="form-control select2"
                  data-placeholder="Select Cause"
                  #selectFieldCause
                >
                
                <option label="Select" value="select" [selected]="!editJobBoolean" [hidden]="true">
                  Select
                </option>
                  <option
                    *ngFor="let component of tireAndWheelComponents"
                    value="{{component.value}}"
                  >
                    {{ component.value }}
                  </option>
                  
                </select>
              </div>
            </div>
            <!-- col-4 -->
          </div>
          <div class="row mg-b-5">
            <div class="col-12">
              <div class="form-group">
                <label class="form-control-label"
                  >Correction<span class="tx-danger"> *</span></label
                >
                <textarea
                tabindex="26"
                  rows="2"
                  class="form-control"
                  placeholder="Type Correction here"
                  [formControlName]="'correctionDesc'"
                  [ngClass]="{
                    'is-invalid': (submitted && f.correctionDesc.errors) || f.correctionDesc?.value?.length > 900
                  }"
                  required
                ></textarea>
                <div class="row justify-content-between pd-r-15">
                  <div>
                    <label
                    class="tx-danger pd-l-15"
                    *ngIf="f.correctionDesc?.errors?.maxlength"
                   >Character count cannot exceed 900
                   </label>
                  </div>
                  <span>{{f.correctionDesc?.value?.length}}/900</span>
                </div>
              </div>
            </div>
            <!-- col-4 -->
          </div>
        </div>
        <!-- Modal footer -->
        <div class="modal-footer">
          <div>
            <input
            tabindex="27"
              class="form-check-input"
              type="checkbox"
              value=""
              [checked]="createAnother"
              (click)="createAnotherClicked()"
            />
            <label class="form-check-label" for="flexCheckChecked"
              >Create another</label
            >
          </div>
          <button
          tabindex="28"
            type="button"
            class="btn btn-oblong btn-secondary btn"
            data-dismiss="modal"
            id="closeModalButton"
            (click)="resetForm()"
          >
            Cancel
          </button>
          <button type="submit" class="btn btn-oblong btn-primary btn"  tabindex="29">
            Add
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- The Roadside Modal -->
<div class="modal" id="newRoadsideModal">
  <div class="modal-dialog modal-lg modal-job-width">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Add New Rental/Roadside Assistance</h4>
        <button type="button" class="close" data-dismiss="modal">
          &times;
        </button>
      </div>

      <form
        fxLayout="column"
        [formGroup]="rentalRoadsideForm"
        (ngSubmit)="submitRentalRoadsideForm()"
      >
        <!-- Modal body -->
        <div class="modal-body pd-x-20 modal-job-width">
          <div class="row">
            <div class="col-sm-6 col-12 pd-r-10">
              <div class="form-group mg-b-10-force">
                <label class="form-control-label"
                  >Job Component <span class="tx-danger">*</span> <span
                  *ngIf="jobComponentsBenefit?.length <= 0"
                  class="spinner-border mg-l-3 spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span></label
                >
                <select

                *ngIf="!disableJobComponentBenefitField"
                  [formControlName]="'jobComponentRental'"
                  [ngClass]="{
                    'is-invalid': submittedRental && fRental.jobComponentRental.errors
                  }"
                  class="form-control select2"
                  data-placeholder="Select job component"
                  #selectFieldA
                >
               
                  <option
                    label="Select"
                    [selected]="!editJobBoolean"
                    [hidden]="true"
                  ></option>
                  <option
                    *ngFor="let component of jobComponentsBenefit"
                    [ngValue]="component"
                    
                  >
                    {{ component.name }}
                  </option>
                </select>
                <input
                *ngIf="disableJobComponentBenefitField"
                disabled
                  class="form-control"
                  value=""
                  [formControlName]="'jobComponentRentalIndividual'"
                />
              </div>
            </div>

            <div class="col-sm-6 col-12 pd-l-10">
              <div class="form-group mg-b-10-force">
                <label class="form-control-label"
                  >Job Loss Code Description
                  <span class="tx-danger">*</span></label
                >
                <select
                  class="form-control select2"
                  data-placeholder="Select job component"
                  [formControlName]="'jobLossCodeDescRental'"
                  [ngClass]="{
                    'is-invalid': submittedRental && fRental.jobLossCodeDescRental.errors
                  }"
                >
                <option label="Select" [selected]="!editJobBoolean" [hidden]="true">
                  Select
                </option>
                  <option *ngFor="let code of codesBenefit" [ngValue]="code">
                    {{ code.partDesc }}
                  </option>
                </select>
              </div>
          </div>
          <div class="col-12 tx-danger pd-b-10">*If the Job Component or Job Loss Code Description value does not display, it is not a valid benefit for this contract.</div>
        </div>
          <div *ngIf="showRental" class="row pd-t-20 mg-b-5">
            <div class="col-7 col-sm-4">
              <div class="form-group">
                <label class="form-control-label"
                  >Rental Rate Per Day <span class="tx-danger">*</span>&nbsp;</label
                >
                <input
                  rows="2"
                  class="form-control"
                  name="formattedRentalRatePerDay"
                  value="$ .00"
                  (blur)="transformAmount($event, 'rental')" [(ngModel)]="formattedRentalRatePerDay"
                  placeholder="$ .00"
                  [formControlName]="'rentalRatePerDay'"
                  [ngClass]="{
                    'is-invalid': (submittedRental && fRental.rentalRatePerDay.errors) || rentalRatePerDayError
                  }"
                  required
                />
                <label
                class="tx-danger"
                *ngIf="showMaxAmountError"
                >{{maxAmountError}}{{fRental.jobLossCodeDescRental?.value?.maxPrice}}.
              </label>
              </div>

                
            </div>
            <div class="align-self-center tx-black" style="font-size: 22px; margin-top: 17px;">X</div>
            <div class="col-7 col-sm-4">
              <div class="form-group">
                <label class="form-control-label"
                  >Number of Rental Days <span class="tx-danger">*</span></label
                >
                <input
                  rows="2"
                  type="number"
                  class="form-control"
                  placeholder=""
                  (blur)="getTotalRental()"
                  [formControlName]="'numberOfRentalDays'"
                  [ngClass]="{
                    'is-invalid': (submittedRental && fRental.numberOfRentalDays.errors) || fRental.numberOfRentalDays?.errors?.min || fRental.numberOfRentalDays?.errors?.max
                  }"
                  required
                />
              </div>
            </div>
            <div class="align-self-center tx-black" style="font-size: 22px; margin-top: 17px;">=</div>
            <div class="col-7 col-sm-3">
              <div class="form-group">
                <label class="form-control-label"
                  >Total <span class="tx-danger">*</span></label
                >
                <input
                  rows="2"
                  class="form-control"
                  placeholder=""
                  [formControlName]="'total'"
                  disabled
                  [ngClass]="{
                    'is-invalid': submittedRental && fRental.total.errors
                  }"
                  
                />
              </div>
            </div>
          </div>

          <div *ngIf="showRoadSide" class="row pd-t-20 mg-b-5">
            <div class="col-3">
              <div class="form-group">
                <label class="form-control-label"
                  >Amount <span class="tx-danger">*</span></label
                >
                <input
                  rows="2"
                  class="form-control"
                  name="formattedAmount"
                  value="$ .00"
                  (blur)="transformAmount($event, 'roadside')" [(ngModel)]="formattedAmount"
                  placeholder="$ .00"
                  [formControlName]="'roadsideAmount'"
                  [ngClass]="{
                    'is-invalid': (submittedRental && fRental.roadsideAmount.errors) || roadSideAmountError
                  }"
                  
                />

              </div>
            </div>
            <div class="col-12">
              <label
              class="tx-danger"
              *ngIf="showMaxAmountError"
              >{{maxAmountError}}{{fRental.jobLossCodeDescRental?.value?.maxPrice}}.
            </label>
          </div>
          </div>
          <div *ngIf="showRoadSide && !hideRentalQuestion" class="row">
            <div
                class="form-group col-9 mg-b-10-force"
                
              >
                <label class="form-control-label"
                  >Is this a Roadside Assistance/Towing only claim?
                  <span class="tx-danger">*</span></label
                >
                <select
                  [formControlName]="'roadsideDescChoice'"
                  class="form-control select2"
                  data-placeholder="Select"
                  [ngClass]="{
                    'is-invalid':
                    submittedRental && fRental.roadsideDescChoice.errors
                  }"
                >
                  <option label="Select" [selected]="!editJobBoolean" [hidden]="true">
                    Select
                  </option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
            </div>
        </div>
        <div *ngIf="showRoadSideDesc" class="row mg-b-5">
          <div class="col-12">
            <div class="form-group">
              <label class="form-control-label"
                >Provide brief description of repairs associated with this request.<span class="tx-danger"> *</span></label
              >
              <textarea
                rows="2"
                class="form-control"
                placeholder="Type description here"
                [formControlName]="'roadsideDesc'"
                [ngClass]="{
                  'is-invalid': submittedRental && fRental.roadsideDesc.errors
                }"
                required
              ></textarea>
            </div>
          </div>
          <!-- col-4 -->
        </div>

        <div *ngIf="showRental && !hideRentalQuestion" class="row">
          <div
              class="form-group col-9 mg-b-10-force"
              
            >
              <label class="form-control-label"
                >Is this a Rental only claim?
                <span class="tx-danger">*</span></label
              >
              <select
                [formControlName]="'rentalDescChoice'"
                class="form-control select2"
                data-placeholder="Select"
                [ngClass]="{
                  'is-invalid':
                  submittedRental && fRental.rentalDescChoice.errors
                }"
              >
                <option label="Select" [selected]="!editJobBoolean" [hidden]="true">
                  Select
                </option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
          </div>
      </div>
      <div *ngIf="showRentalDesc" class="row mg-b-5">
        <div class="col-12">
          <div class="form-group">
            <label class="form-control-label"
              >Provide brief description of repairs associated with this rental request.<span class="tx-danger"> *</span></label
            >
            <textarea
              rows="2"
              class="form-control"
              placeholder="Type description here"
              [formControlName]="'rentalDesc'"
              [ngClass]="{
                'is-invalid': submittedRental && fRental.rentalDesc.errors
              }"
              required
            ></textarea>
          </div>
        </div>
        <!-- col-4 -->
      </div>
        </div>
        <!-- Modal footer -->
        <div class="modal-footer">
          <div>
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              [checked]="createAnotherRental"
              (click)="createAnotherRentalClicked()"
            />
            <label class="form-check-label" for="flexCheckChecked"
              >Create another</label
            >
          </div>
          <button
            type="button"
            class="btn btn-oblong btn-secondary btn"
            data-dismiss="modal"
            id="closeModalRentalButton"
            (click)="resetRentalForm()"
          >
            Cancel
          </button>
          <button type="submit" class="btn btn-oblong btn-primary btn">
            Add
          </button>
        </div>
      </form>
    </div>
  </div>
</div>