import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { fromEvent, Subscription } from 'rxjs';
import { Claim } from 'src/app/_model/claim';
import { Job } from 'src/app/_model/job';
import { Labor } from 'src/app/_model/labor';
import { Part } from 'src/app/_model/part';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

import { ClaimService } from 'src/app/_services/claim.service';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { Sublet } from 'src/app/_model/sublet';
import { Contract } from 'src/app/_model/contract';
import { ContractService } from 'src/app/_services/contract.service';

@Component({
  selector: 'app-job-summary',
  templateUrl: './job-summary.component.html',
  styleUrls: ['./job-summary.component.css'],
})
export class JobSummaryComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('partNumber') partNumber: ElementRef;
  @ViewChild('laborDescription') laborDescription: ElementRef;
  @ViewChild('subletDescription') subletDescription: ElementRef;
  @Output() editJobEvent = new EventEmitter<Job>();

  showDetails1: boolean = false;
  showDetails2: boolean = false;
  showDetailsTotal: boolean = false;
  showDescField: boolean = false;

  showDeductibleWaiver: boolean = false;

  showAfterMarketSelectField: boolean = false;
  indexNum=0;
  claimSubscription: Subscription;
  claimSubscriptionClaim: Subscription;
  job: Job;
  addAnotherIndex:number = -1;
  claim: Claim;
  laborForm: FormGroup;
  submittedLabor: boolean = false;
  createAnother: boolean = true;
  jobList: Job[] = [];
  jobListShow: boolean[] = [];
  partForm: FormGroup;
  subletForm: FormGroup;
  createAnotherSublet: boolean = true;
  submittedSublet: boolean = false;
  submittedPart: boolean = false;
  submittedPartPressed: boolean = false;
  submittedSubletPressed: boolean = false;
  submittedLaborPressed: boolean = false;
  createAnotherPart: boolean = true;
  formattedUnitPricePart = '$ .00';
  formattedUnitPriceSublet = '$ .00';
  unitPriceBeforeFormatting: number = 0;
  unitPricePartError: boolean = false;
  unitPriceSubletError: boolean = false;
  laborRateError: boolean = false;
  formattedLaborRate = '$ .00';
  deleteIndex=-1;
  disabledField: boolean = false;
  header:string="Delete Job";
  message:string = "";
  buttonMessage:string="Delete";
  clickedFromCode: boolean = false;
  editPartBoolean: boolean = false;
  editLaborBoolean: boolean = false;
  editSubletBoolean: boolean = false;
  editPartValue:Part;
  partId: string;
  laborId: string;
  subletId: string;
  deleteSubletPressed: boolean = false;
  deleteLaborPressed: boolean = false;
  deletePartPressed: boolean = false;
  deleteJobPressed: any;
  contract: Contract;
  defaultLaborRate: number;
  partTax: number = 0;
  laborTax: number = 0;
  partMarkup: number = 0;
  hidePartMarkup: boolean = false;
  constructor(
    private readonly fb: FormBuilder,
    private claimService: ClaimService,
    private toastr: ToastrService,
    private currencyPipe: CurrencyPipe,
    private contractService: ContractService,
    public datepipe: DatePipe
  ) {
    this.laborForm = this.fb.group({
      laborDescription: [, Validators.required],
      laborHours: [
        ,
        Validators.compose([Validators.required, Validators.min(0.1)]),
      ],
      laborRate:[,Validators.required],
      laborGuide: [ ],
      laborGuideOther: [],
      total:[]
    });
    this.partForm = this.fb.group({
      partNumber: [, Validators.compose([Validators.required, Validators.maxLength(20)]),],
      unitPrice: [, Validators.required],
      quantity: [
        ,
        Validators.compose([Validators.required, Validators.min(0.1)]),
      ],
      partDescription: [],
      isAfterMarket: [, Validators.required],
      afterMarketPartDescription: [, Validators.compose([Validators.required, Validators.maxLength(60)])],
      total:[]
    });
    this.subletForm = this.fb.group({
      subletDescription: [, Validators.required],
      subletUnitPrice: [, Validators.required],
    });
  }
  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    this.toastr.clear();

    this.claimSubscriptionClaim = this.claimService.claim.subscribe((value) => {
      this.claim = value;
      if (this.claim?.deductibleType == "Disappearing") {
        this.showDeductibleWaiver = true;
      } else {
        this.showDeductibleWaiver = false;
      }
      let contractInfo = {
        lastName: value?.lastName,
        vin: value?.vin,
        contractNumber: value?.contractNumber,
      };
      
      //this.defaultLaborRate = this.claimService.laborBillingRate;
     // this.laborTax = this.claimService.laborTax;
      //this.partTax = this.claimService.partTax;
      this.getLaborInfo();
      //this.setLossDateRelatedFieldsByDefault();
      this.contractService
        .searchContract(contractInfo)
        .subscribe((resultContract: any) => {
          for (
            let index = 0;
            index < resultContract?.Contracts?.length;
            index++
          ) {
            const element: Contract = resultContract.Contracts[index];
            if (element.contractNumber === value.contractNumber) {
              this.contract = element;
            }
          }
        });
    });
    this.disabledField = this.claimService.previewActiveClaim;
    this.claimSubscription = this.claimService.job.subscribe((value) => {
      this.job = value;
      let claimId = this.claim?.id ? this.claim.id : this.job?.claimId;
      this.claimService.getJobs(claimId).subscribe(
        (result: any) => {
          console.log(result);
          this.jobList = result;
          
          if (this.jobList) {
            for (let i = 0; i < this.jobList.length; i++) {
              this.jobListShow[i] = false;
            }
          }
          this.openJobDetails();
        },
        (error) => {
          console.log(error);
          this.toastr.error(
            'Something went wrong while fetching jobs, please try again. ' +
              'Error code: ' +
              error.status,
            'Create claim',
            {
              positionClass: 'toast-top-center',
            }
          );
        }
      );
    });
  }
  openJobDetails() {
    setTimeout(() => {
      for (let i = 0; i < this.jobList.length; i++) {
        if (!this.jobListShow[i])
          document.getElementById('arrowDown' + i)?.click();
        else document.getElementById('arrowUp' + i)?.click();
      }
      //document.getElementById('arrowUp0').className;
    }, 0);
  }

  getLaborInfo() {
    let contractInfo = {
      contractNumber: this.claim?.contractNumber,
      lastName: this.claim?.lastName,
      vin: this.claim?.vin
    };
    let date = new Date(this.claim.lossDate);
    let dateOffset = new Date().getTimezoneOffset() / 60;
    let tmpDate: Date = date;
    tmpDate.setHours(date.getHours() + dateOffset);

    this.claimService.getLaborRateByLossDate(this.datepipe.transform(tmpDate, 'yyyy-MM-dd'), contractInfo, this.claimService.accountNumber).subscribe((result:any)=>{
      console.log(result);
      if (result) {
        if (result.laborTax || result.laborTax == 0)
            this.laborTax = result.laborTax;
          if (result.partTax || result.partTax == 0)
            this.partTax = result.partTax;
          if (result.partMarkup) {
            this.partMarkup = result.partMarkup;
          }

          if (result.laborRate) {
          this.formattedLaborRate = this.currencyPipe.transform(
            result.laborRate,
            '$'
          );
          this.defaultLaborRate = result.laborRate;
        } else {
          this.formattedLaborRate = this.currencyPipe.transform(
            this.claimService.laborBillingRate,
            '$'
          );
          this.defaultLaborRate = this.claimService.laborBillingRate;
        }
      } else {
        this.setLossDateRelatedFieldsByDefault();
      }
    }, (error)=> {
      console.log(error);
      this.setLossDateRelatedFieldsByDefault();
      this.toastr.error(
        'Something went wrong while getting labor info, please try again. ' +
        'Error code: ' +
        error.status,
        'Create claim',
        {
          positionClass: 'toast-top-center',
        }
      );
    });
  }

  setLossDateRelatedFieldsByDefault() {
      this.formattedLaborRate = this.currencyPipe.transform(
        this.claimService.laborBillingRate,
        '$'
      );
      this.defaultLaborRate = this.claimService.laborBillingRate;
    
    this.laborTax = this.claimService.laborTax;
    this.partTax = this.claimService.partTax;
    this.partMarkup = this.claimService.partMarkup;
  }
  ngOnDestroy() {
    this.claimSubscription?.unsubscribe();
    this.claimSubscriptionClaim?.unsubscribe();
  }
  transformAmount(element, formName) {
    this.unitPricePartError = false;
    this.unitPriceSubletError = false;
    this.laborRateError = false;

    const searchCurrency = '$';
    const replaceWith = '';
    const searchComma = ',';
    let bottomLimit: number = 0.01;
    let upperLimit: number = 9999999999;

    let amountTmp;
    if (formName == 'part')
      amountTmp = this.formattedUnitPricePart
        ?.split(searchCurrency)
        .join(replaceWith)
        .split(searchComma)
        .join(replaceWith);
    else if(formName == 'sublet')
      amountTmp = this.formattedUnitPriceSublet
        ?.split(searchCurrency)
        .join(replaceWith)
        .split(searchComma)
        .join(replaceWith);
     else if(formName == 'labor')
      amountTmp = this.formattedLaborRate
        ?.split(searchCurrency)
        .join(replaceWith)
        .split(searchComma)
        .join(replaceWith);

    if (isNaN(+amountTmp) || +amountTmp < bottomLimit || +amountTmp > upperLimit) {
      if (formName == 'part') this.unitPricePartError = true;
      else if (formName == 'sublet') this.unitPriceSubletError = true;
      else if (formName == 'labor') this.laborRateError = true;
      return;
    } else if((formName == 'sublet' || formName == 'labor') && amountTmp.toString().split(".")[0]?.length > 10){
      console.log(amountTmp.toString().length);
      if (formName == 'sublet')
        this.unitPriceSubletError = true;
      else if (formName == 'labor')
        this.laborRateError = true;
      return;
    }
    
    let amount = this.currencyPipe.transform(amountTmp, '$');
    let amountSplitted = amount.split(".");
    if((amountSplitted?.length == 2 && (amountSplitted[0]?.length > 15 || amountSplitted[1]?.length > 2))){
      if (formName == 'part') {
        this.unitPricePartError = true;
      }
      else  if (formName == 'sublet') 
        this.unitPriceSubletError = true;
      else if (formName == 'labor')
        this.laborRateError = true;
      return;
    }
    if (formName == 'part') {
      this.formattedUnitPricePart = amount;
      this.getTotalPart();
    }
    else if (formName == 'sublet')  this.formattedUnitPriceSublet = amount;
    else if (formName == 'labor') {
      this.formattedLaborRate = amount;
      this.getTotalLabor();
    }

    element.target.value = amount;
  }
  setJobIndex(index:number) {
      this.addAnotherIndex = index;
      this.job = this.jobList[index];
      if (this.job?.jobComponent?.toLowerCase().includes("wheel") && this.job?.jobComponent?.toLowerCase().includes("tire")) {
        this.hidePartMarkup = true;
      }
  }
  showDescripitonField() {
    this.showDescField = true;
    //this.partForm.controls.afterMarketPartDescription.setValue("");
  }
  blurPartDescription() {
    let searchTerm = this.partNumber.nativeElement.value;
    if(!searchTerm || searchTerm?.length < 3) {
      if (searchTerm?.length > 0 && searchTerm?.length < 3) {
        this.showAfterMarketSelectField = true;
        this.showDescField = true;
      }
      return;
    }
    searchTerm = searchTerm?.split("-").join("");
    if (this.editPartBoolean && searchTerm == this.editPartValue?.partNumber) {
      return;
    }
    if (this.editPartBoolean && searchTerm != this.editPartValue?.partNumber) {
      this.editPartValue = null;
    }
    let partData = {
      vin: this.claim.vin,
      partNumber: searchTerm
      //maxRecord: 2,
    };
    this.partForm.controls.partNumber.setValue(searchTerm);
    this.claimService.findPartDescriptionPost(partData).subscribe(
      (value: any) => {
        if (!value || (value && value?.length <= 0)) {
          this.showAfterMarketSelectField = true;
          this.showDescField = true;
          this.partForm.controls.afterMarketPartDescription.setValue("");
          this.toastr.info('No part found under that number', 'Create part', {
            positionClass: 'toast-top-center',
          });
        }
        else {
          this.partForm.controls.partDescription.setValue('');
          for (let index = 0; index < value.length; index++) {
            if (value[index].partNumber?.toLowerCase() === searchTerm?.toLowerCase()) {

              this.partForm.controls.partDescription.setValue(value[index].description);

              
              if (value[index].price) {
                let amount = this.currencyPipe.transform(value[index].price, '$');
                //this.formattedUnitPricePart = amount;
              }

              this.showAfterMarketSelectField = false;
              this.showDescField = false;
              console.log(value);
            } // this.showAfterMarketSelectField = true; dodati ovo
          }

          setTimeout(()=>{  if (!this.partForm.controls.partDescription.value) {
            this.showAfterMarketSelectField = true;
            this.showDescField = true;
            this.partForm.controls.afterMarketPartDescription.setValue("");
            this.toastr.info('No part found under that number', 'Create part', {
              positionClass: 'toast-top-center',
            });
          }}, 100);
        }
      },
      (error) => {
        console.log(error);
        this.showAfterMarketSelectField = true;
        this.showDescField = true;
        this.partForm.controls.partDescription.setValue('');
      }
    );
  
    // if(!this.partForm.controls.partNumber.value)
    //   return;
  
  }
  previewDetails1() {
    this.showDetails1 = !this.showDetails1;
  }
  previewDetailsJobsTable(i) {
    let status = !this.jobListShow[i];
    this.jobListShow[i] = status;
  }
  previewDetails2() {
    this.showDetails2 = !this.showDetails2;
  }
  previewDetailsTotal() {
    this.showDetailsTotal = !this.showDetailsTotal;
  }

  createAnotherClicked() {
    this.createAnother = !this.createAnother;
  }
  createAnotherPartClicked() {
    this.createAnotherPart = !this.createAnotherPart;
  }
  createAnotherSubletClicked() {
    this.createAnotherSublet = !this.createAnotherSublet;
  }

  get f() {
    return this.laborForm.controls;
  }

  get fPart() {
    return this.partForm.controls;
  }

  get fSublet() {
    return this.subletForm.controls;
  }
  submitLaborForm() {
    this.submittedLabor = true;

    if (!this.laborForm.valid || this.laborRateError) return;
    this.submittedLabor = false;

    if(this.submittedLaborPressed)
      return;

    this.submittedLaborPressed = true;

    const searchCurrency = '$';
    const replaceWith = '';
    const searchComma = ',';
    let amountTmp = this.formattedLaborRate
    .split(searchCurrency)
    .join(replaceWith)
    .split(searchComma)
    .join(replaceWith);
    let laborInfo: Labor = {
      laborHours: this.laborForm.controls.laborHours.value,
      claimId: this.job.claimId,
      jobNumber: this.job.jobNumber,
      laborDescription: this.laborForm.controls.laborDescription.value,
      laborBillingRate: +amountTmp
      //dodati update claim-a na step 3
    };
    if(this.editLaborBoolean)
      laborInfo.id = this.laborId;
     
    if(!this.editLaborBoolean)
    this.claimService
      .addLabor(this.job.claimId, this.job.jobNumber, laborInfo)
      .subscribe(
        (result: any) => {
          console.log(result);

          document.getElementById('closeLaborModalButton').click();
          this.partForm.reset();
          this.laborForm.reset();
          this.laborForm.reset();
          if (this.createAnother){
            this.clickedFromCode = true;
            document.getElementById('openLaborModalButton'+this.addAnotherIndex).click();
          }
          this.getJobDetails();
          this.toastr.success('Successfully added labor', 'Create claim', {
            positionClass: 'toast-top-center',
          });
          
          this.submittedLaborPressed = false;
        },
        (error) => {
          this.submittedLaborPressed = false;
          console.log(error);
          if(error.status && error.status == 403){
            this.toastr.error(
              'The claim is being edited by another user, please try again later.',
              'Create claim',
              {
                positionClass: 'toast-top-center',
              }
            );
          } else 
          this.toastr.error(
            'Something went wrong while adding labor, please try again. ' +
              'Error code: ' +
              error.status,
            'Create claim',
            {
              positionClass: 'toast-top-center',
            }
          );
        }
      ); else 
      this.claimService
      .updateLabor(laborInfo)
      .subscribe(
        (result: any) => {
          console.log(result);
          document.getElementById('closeLaborModalButton').click();

          this.getJobDetails();
          this.toastr.success('Successfully updated labor', 'Create claim', {
            positionClass: 'toast-top-center',
          });
          this.partForm.reset();
          this.laborForm.reset();
          this.laborForm.reset();
          this.submittedLaborPressed = false;
          this.editLaborBoolean = false;
        },
        (error) => {
          this.submittedLaborPressed = false;
          console.log(error);
          if(error.status && error.status == 403){
            this.toastr.error(
              'The claim is being edited by another user, please try again later.',
              'Create claim',
              {
                positionClass: 'toast-top-center',
              }
            );
          } else 
          this.toastr.error(
            'Something went wrong while updating labor, please try again. ' +
              'Error code: ' +
              error.status,
            'Create claim',
            {
              positionClass: 'toast-top-center',
            }
          );
        }
      );

    console.log(this.laborForm.controls.value);
  }

  submitPartForm() {
    this.submittedPart = true;

    if (!this.partForm.valid || this.unitPricePartError) {
      if (!this.showAfterMarketSelectField)
        this.partForm.controls.isAfterMarket.setErrors(null);
      if (!this.showDescField)
        this.partForm.controls.afterMarketPartDescription.setErrors(null);
      if (!this.partForm.valid || this.unitPricePartError) return;
    }
    if(this.submittedPartPressed)
      return;

    this.submittedPartPressed = true;
    this.submittedPart = false;

    const searchCurrency = '$';
    const replaceWith = '';
    const searchComma = ',';
    let amountTmp = this.formattedUnitPricePart
      .split(searchCurrency)
      .join(replaceWith)
      .split(searchComma)
      .join(replaceWith);

    let partInfo: Part = {
      partNumber: this.partForm.controls.partNumber.value,
      unitPrice: +amountTmp,
      partDescription: this.partForm.controls.partDescription.value,

      quantity: this.partForm.controls.quantity.value,
      isAfterMarket: this.partForm.controls.isAfterMarket.value == "Yes" ? true : false,
      claimId: this.job.claimId,
      jobNumber: this.job.jobNumber,

      //dodati update claim-a na step 3
    };
    if (this.showDescField) {
      partInfo.afterMarketPartDescription =
        this.partForm.controls.afterMarketPartDescription.value;
      partInfo.partDescription =
        this.partForm.controls.afterMarketPartDescription.value;
    }
    if(this.editPartBoolean)
      partInfo.id = this.partId;
    
    if(!this.editPartBoolean)
    this.claimService
      .addPart(this.job.claimId, this.job.jobNumber, partInfo)
      .subscribe(
        (result: any) => {
          console.log(result);
          document.getElementById('closePartModalButton').click();
          if (this.createAnotherPart){
            this.clickedFromCode = true;
            document.getElementById('openPartModalButton'+this.addAnotherIndex).click();
          }
          
          this.getJobDetails();
          this.toastr.success('Successfully added part', 'Create claim', {
            positionClass: 'toast-top-center',
          });
          this.showAfterMarketSelectField = false;
          this.showDescField = false;
          this.partForm.reset();
          this.submittedPartPressed = false;
        },
        (error) => {
          this.submittedPartPressed = false;
          console.log(error);
          if(error.status && error.status == 403){
            this.toastr.error(
              'The claim is being edited by another user, please try again later.',
              'Create claim',
              {
                positionClass: 'toast-top-center',
              }
            );
          } else 
          this.toastr.error(
            'Something went wrong while adding part, please try again. ' +
              'Error code: ' +
              error.status,
            'Create claim',
            {
              positionClass: 'toast-top-center',
            }
          );
        }
      ); else
      this.claimService
      .updatePart(partInfo)
      .subscribe(
        (result: any) => {
          console.log(result);
          document.getElementById('closePartModalButton').click();
          this.getJobDetails();
          this.toastr.success('Successfully updated part', 'Create claim', {
            positionClass: 'toast-top-center',
          });
          this.showAfterMarketSelectField = false;
          this.showDescField = false;
          this.partForm.reset();
          this.submittedPartPressed = false;
          this.editPartBoolean = false;
        },
        (error) => {
          this.submittedPartPressed = false;
          console.log(error);
          if(error.status && error.status == 403){
            this.toastr.error(
              'The claim is being edited by another user, please try again later.',
              'Create claim',
              {
                positionClass: 'toast-top-center',
              }
            );
          } else 
          this.toastr.error(
            'Something went wrong while updating part, please try again. ' +
              'Error code: ' +
              error.status,
            'Create claim',
            {
              positionClass: 'toast-top-center',
            }
          );
        }
      );
  }

  submitSubletForm() {
    this.submittedSublet = true;

    if (!this.subletForm.valid || this.unitPriceSubletError) return;

    this.submittedSublet = false;

    if(this.submittedSubletPressed)
      return;

    this.submittedSubletPressed = true;

    const searchCurrency = '$';
    const replaceWith = '';
    const searchComma = ',';
    let amountTmp = this.formattedUnitPriceSublet
      .split(searchCurrency)
      .join(replaceWith)
      .split(searchComma)
      .join(replaceWith);

    let subletInfo: Sublet = {
      description: this.subletForm.controls.subletDescription.value,
      jobId: this.job.id,
      unitPrice: +amountTmp
    };
    if(this.editSubletBoolean)
      subletInfo.id = this.subletId;
    if(!this.editSubletBoolean)
    this.claimService
      .addSublet(subletInfo)
      .subscribe(
        (result: any) => {
          console.log(result);
          document.getElementById('closeSubletModalButton').click();
          if (this.createAnotherSublet){
            this.clickedFromCode = true;
            document.getElementById('openSubletModalButton'+this.addAnotherIndex).click();
          }
          this.getJobDetails();
          this.toastr.success('Successfully added sublet', 'Create claim', {
            positionClass: 'toast-top-center',
          });
          this.partForm.reset();
          this.laborForm.reset();
          this.subletForm.reset();
          this.submittedSubletPressed = false;
        },
        (error) => {
          this.submittedSubletPressed = false;
          console.log(error);
          if(error.status && error.status == 403){
            this.toastr.error(
              'The claim is being edited by another user, please try again later.',
              'Create claim',
              {
                positionClass: 'toast-top-center',
              }
            );
          } else 
          this.toastr.error(
            'Something went wrong while adding sublet, please try again. ' +
              'Error code: ' +
              error.status,
            'Create claim',
            {
              positionClass: 'toast-top-center',
            }
          );
        }
      ); else 
      this.claimService
      .updateSublet(subletInfo)
      .subscribe(
        (result: any) => {
          console.log(result);
          document.getElementById('closeSubletModalButton').click();
          this.getJobDetails();
          this.toastr.success('Successfully updated sublet', 'Create claim', {
            positionClass: 'toast-top-center',
          });
          this.partForm.reset();
          this.laborForm.reset();
          this.subletForm.reset();
          this.submittedSubletPressed = false;
          this.editSubletBoolean = false;
        },
        (error) => {
          this.submittedSubletPressed = false;
          console.log(error);
          if(error.status && error.status == 403){
            this.toastr.error(
              'The claim is being edited by another user, please try again later.',
              'Create claim',
              {
                positionClass: 'toast-top-center',
              }
            );
          } else 
          this.toastr.error(
            'Something went wrong while updating sublet, please try again. ' +
              'Error code: ' +
              error.status,
            'Create claim',
            {
              positionClass: 'toast-top-center',
            }
          );
        }
      );

    console.log(this.laborForm.controls.value);
  }

  getJobDetails() {
    this.claimService.getJobs(this.job.claimId).subscribe(
      (result: any) => {
        console.log(result);
        this.jobList = result;
        
        if(this.createAnotherSublet || this.createAnother || this.createAnotherPart)
          this.job=this.jobList[this.addAnotherIndex];

          this.createAnother = true;
          this.createAnotherSublet = true;
          this.createAnotherPart = true;

          this.claimService.getClaimById(this.job.claimId).subscribe(
            (result: any) => {
              this.claimService.claim.next(result);
            }
          );
        
        // for(let i =0; i<this.jobListShow.length;i++)
        //   console.log(this.jobListShow[i]);
        // if (this.jobList) {
        //   this.jobListShow = [];
        //   for (let i = 0; i < this.jobList.length; i++) {
        //     this.jobListShow[i] = false;
        //   }
        // }
        // this.openJobDetails();
      },
      (error) => {
        console.log(error);
        this.toastr.error(
          'Something went wrong while fetching jobs, please try again. ' +
            'Error code: ' +
            error.code,
          'Create claim',
          {
            positionClass: 'toast-top-center',
          }
        );
      }
    );
  }
  deleteJob(deleteJob:boolean) {
    if(deleteJob){
      if(this.deleteJobPressed){
        return;
      }
      this.deleteJobPressed = true;
    this.claimService.deleteJob(this.job.id).subscribe(
      (result: any) => {
        console.log('deletedJob' + result.id);
        this.getJobDetails();
        this.toastr.success('Successfully deleted job', 'Create claim', {
          positionClass: 'toast-top-center',
        });
        document.getElementById("closeConfirmActionButton"+this.deleteIndex).click();
        this.claimService.getClaimById(this.job.claimId).subscribe(
          (result: any) => {
            this.claimService.claim.next(result);
          }
        );
        this.deleteJobPressed = false;
      },
      (error) => {
        this.deleteJobPressed = false;

        console.log(error);
        if(error.status && error.status == 403){
          this.toastr.error(
            'The claim is being edited by another user, please try again later.',
            'Create claim',
            {
              positionClass: 'toast-top-center',
            }
          );
        } else 
        this.toastr.error(
          'Something went wrong while deleting job, please try again',
          'Create claim',
          {
            positionClass: 'toast-top-center',
          }
        );
      }
    );
    }
  }
  deletePart(part) {
    if(this.deletePartPressed){
      return;
    }
    this.deletePartPressed = true;
    this.claimService.deletePart(part.id).subscribe(
      (result: any) => {
        console.log('deletedpart' + result.id);
        this.getJobDetails();
        this.toastr.success('Successfully deleted part', 'Create claim', {
          positionClass: 'toast-top-center',
        });
        this.claimService.getClaimById(this.job.claimId).subscribe(
          (result: any) => {
            this.claimService.claim.next(result);
          }
        );
        this.deletePartPressed = false;
      },
      (error) => {
        this.deletePartPressed = false;

        console.log(error);
        if(error.status && error.status == 403){
          this.toastr.error(
            'The claim is being edited by another user, please try again later.',
            'Create claim',
            {
              positionClass: 'toast-top-center',
            }
          );
        } else 
        this.toastr.error(
          'Something went wrong while deleting part, please try again',
          'Create claim',
          {
            positionClass: 'toast-top-center',
          }
        );
      }
    );
  }
  setJobDelete(index){
    this.message="Are you sure you want to delete job ";
    this.message+=this.jobList[index].jobNumber;
    this.message+="?";
    this.job = this.jobList[index];
    this.deleteIndex = index;
  }
  deleteLabor(labor) {
    if(this.deleteLaborPressed){
      return;
    }
    this.deleteLaborPressed = true;

    this.claimService.deleteLabor(labor.id).subscribe(
      (result: any) => {
        console.log('deletedLabor ' + result.id);
        this.getJobDetails();
        this.toastr.success('Successfully deleted labor', 'Create claim', {
          positionClass: 'toast-top-center',
        });
        this.claimService.getClaimById(this.job.claimId).subscribe(
          (result: any) => {
            this.claimService.claim.next(result);
          }
        );
        this.deleteLaborPressed = false;

      },
      (error) => {
        this.deleteLaborPressed = false;

        console.log(error);
        if(error.status && error.status == 403){
          this.toastr.error(
            'The claim is being edited by another user, please try again later.',
            'Create claim',
            {
              positionClass: 'toast-top-center',
            }
          );
        } else 
        this.toastr.error(
          'Something went wrong while deleting labor, please try again',
          'Create claim',
          {
            positionClass: 'toast-top-center',
          }
        );
      }
    );
  }
  deleteSublet(sublet){
    if(this.deleteSubletPressed){
      return;
    }
    this.deleteSubletPressed = true;

    this.claimService.deleteSublet(sublet.id).subscribe(
      (result: any) => {
        console.log('deletedSublet ' + result.id);
        this.getJobDetails();
        this.toastr.success('Successfully deleted sublet', 'Create claim', {
          positionClass: 'toast-top-center',
        });
        this.claimService.getClaimById(this.job.claimId).subscribe(
          (result: any) => {
            this.claimService.claim.next(result);
          }
        );
        this.deleteSubletPressed = false;
      },
      (error) => {
        this.deleteSubletPressed = false;
        
        console.log(error);
        if(error.status && error.status == 403){
          this.toastr.error(
            'The claim is being edited by another user, please try again later.',
            'Create claim',
            {
              positionClass: 'toast-top-center',
            }
          );
        } else 
        this.toastr.error(
          'Something went wrong while deleting sublet, please try again',
          'Create claim',
          {
            positionClass: 'toast-top-center',
          }
        );
      }
    );
  }
  editJob(job:Job){
    this.editJobEvent.emit(job);
  }
  resetPartForm(edit: boolean) {
    this.submittedPart = false;
    this.editPartBoolean = edit;
    this.editPartValue = null;
    this.partForm.reset();
    this.showAfterMarketSelectField = false;
    this.createAnother = true;
    this.createAnotherSublet = true;
    this.createAnotherPart = true;
    this.showDescField = false;
    this.unitPricePartError = false;
    setTimeout(this.partNumber.nativeElement?.focus(),0);
  }
  focusPartNumber(){
    setTimeout(()=>this.partNumber.nativeElement?.focus(),100);
  }
  resetLaborForm(edit: boolean) {
    this.submittedLabor = false;
    this.submittedLaborPressed = false;
    this.editLaborBoolean=edit;
    this.createAnotherSublet = true;
    this.createAnotherPart = true;
    this.laborRateError = false;
    this.laborForm.reset();
    if(!this.editLaborBoolean || this.createAnother) {
      //this.laborForm.controls.laborRate?.setValue(this.currencyPipe.transform(this.claim?.laborBillingRate, '$'));
      this.setDefaultLaborRate();
    }
    this.createAnother = true;
    document.getElementById("laborDescription")?.focus();
  }
  focusLaborDescription(){
    setTimeout(()=>this.laborDescription.nativeElement?.focus(),100);
  }
  resetSubletForm(edit: boolean) {
    this.editLaborBoolean=edit;
    this.submittedSublet = false;
    this.createAnother = true;
    this.createAnotherSublet = true;
    this.createAnotherPart = true;    this.subletForm.reset();
    this.unitPriceSubletError = false;
  }  
  focusSubletDescription(){
    setTimeout(()=>this.subletDescription.nativeElement?.focus(),100);
  }
  editPart(part:Part){
    this.editPartBoolean = true;
    this.editPartValue = part;
    this.partId = part.id;
    this.partForm.controls.partNumber.setValue(part.partNumber); 
    let amount = this.currencyPipe.transform(part.unitPrice, '$');
    this.formattedUnitPricePart = amount;
    this.partForm.controls.partDescription.setValue(part.partDescription);

    this.partForm.controls.quantity.setValue(part.quantity);
    this.showDescField = part.afterMarketPartDescription ? true : false;
    
    if (this.showDescField) {
      this.partForm.controls.afterMarketPartDescription.setValue(part.afterMarketPartDescription);
    }
    this.getTotalPart();
  }
  setDefaultLaborRate() {
    let amount = this.currencyPipe.transform(this.defaultLaborRate, '$');
    this.formattedLaborRate = amount;
    this.laborForm.controls.laborRate?.setValue(this.currencyPipe.transform(this.defaultLaborRate, '$'));
  }
  editLabor(labor:Labor){
    this.editLaborBoolean = true;
    this.laborId = labor.id;
   
    this.laborForm.controls.laborHours.setValue(labor.laborHours);
    this.laborForm.controls.laborDescription.setValue(labor.laborDescription);
    this.laborForm.controls.laborRate.setValue(this.currencyPipe.transform(labor.laborBillingRate, '$'));
    this.getTotalLabor();
  }
  editSublet(sublet:Sublet){
    this.editSubletBoolean = true;
    this.subletId = sublet.id;
    this.subletForm.controls.subletDescription.setValue(sublet.description);
    let amount = this.currencyPipe.transform(sublet.unitPrice, '$');
    this.formattedUnitPriceSublet = amount;
  }
  getTotalPart(){
    if(this.unitPricePartError || this.partForm.controls.quantity.errors)//{min: 0, actual: -585}
      return;
    if(this.formattedUnitPricePart && this.partForm.controls.quantity.value){

      const searchCurrency = '$';
      const replaceWith = '';
      const searchComma = ',';
      let amountTmp = this.formattedUnitPricePart
        .split(searchCurrency)
        .join(replaceWith)
        .split(searchComma)
        .join(replaceWith);
    if(!(isNaN(+amountTmp) || +amountTmp < 0))  
      this.partForm.controls.total.setValue( this.currencyPipe.transform(this.partForm.controls.quantity.value * +amountTmp, '$'));
    }
}
  getTotalLabor(){
    if(this.laborForm.controls.laborRate.errors || this.laborForm.controls.laborHours.errors)//{min: 0, actual: -585}
      return;
    if(this.laborForm.controls.laborRate.value && this.laborForm.controls.laborHours.value){

      const searchCurrency = '$';
      const replaceWith = '';
      const searchComma = ',';
      let amountTmp = this.laborForm.controls.laborRate.value
        .split(searchCurrency)
        .join(replaceWith)
        .split(searchComma)
        .join(replaceWith);
    if(!(isNaN(+amountTmp) || +amountTmp < 0))  
      this.laborForm.controls.total.setValue( this.currencyPipe.transform(this.laborForm.controls.laborHours.value * amountTmp, '$'));
    }
}
openNotesSection() {
  document.getElementById('updateClaimModalButton').click();
  setTimeout(()=>document.getElementById('noteSectionOpener').click(), 500);
}

checkIfTireAndWheelJobsContainTireTax() {
  for (let i = 0; i < this.jobList?.length; i++) {
    if (this.jobList[i].tax) {
      return true;
    }
  }
  return false;
}
}
