<div class="row" style="background: #f8f9fa; height: 450px; overflow-y: scroll;">
  <div class="col-md bd-0">
    <div class="card bd-0">
      <div class="card-body bd-0 pd-t-0">
        <div class="row pd-20 bg-primary justify-content-around">
          <img src={{imgSourcePath}} class="modal-width-image" alt="" [ngClass]="{ 'modal-width-image-full': imageWidthFull }" />
        </div>
        <div class="row justify-content-center">
          <div class="col-12 tx-primary tx-bold">
            <p [class.mb-0]="detailsTitleSecondRow">{{ detailsTitle }}</p>
          </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-12 tx-primary">
              <p>{{ detailsTitleSecondRow }}</p>
            </div>
          </div>
        <div class="row justify-content-center">
          <!-- <i class="fa fa-2x fa-check" style="color:green"></i> -->
          <div class="col-12 tx-primary">
            <p>{{ details }}</p>
          </div>
        </div>
        <div *ngIf="detailsSecondRowText?.length > 0" class="row justify-content-center">
          <div class="col-12 tx-primary">
            <p class="mg-b-0">{{ detailsSecondRowText }}</p>
          </div>
        </div>
        <!-- tab-content -->

        <div class="row justify-content-end">
          <div
            class="col-3 col-sm-3 col-md-2 pd-l-0 pd-r-0 tx-primary justify-content-end"
          >
            <p class="tx-primary">{{ pageNum }}</p>
          </div>
        </div>
      </div>
      <!-- card-body -->
    </div>
    <!-- card -->
  </div>
</div>
