<div class="slim-mainpanel">
  <form
    fxLayout="column"
    [formGroup]="startNewClaimForm"
    (ngSubmit)="submitForm(false)"
   
  >
    <div class="form-layout pd-r-20 pd-t-50 pd-l-20">
      <div class="row">
      <div class="col-lg-3">
        <div class="pd-b-15" style="background: #f8f9fa;">
        <div class="row pd-b-0 mg-x-5">
          <h5 class="tx-primary">
            <svg width="45" height="35" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 82.5 82.5"><defs><style>.cls-1{fill:#2367af;}</style></defs><title>Automobile_Blue_Outline_RGB</title><g id="icons"><path class="cls-1" d="M66.26,35.37v-.26h3.67a1.25,1.25,0,0,0,0-2.5H66.26V27A10.76,10.76,0,0,0,55.52,16.25H27A10.76,10.76,0,0,0,16.28,27v5.56H12.52a1.25,1.25,0,1,0,0,2.5h3.76v.3a10,10,0,0,0-5,8.53V55h0v4.87a6.28,6.28,0,0,0,6.17,6.38h.14a6.29,6.29,0,0,0,6.18-6.38V56.25h35v3.62a6.28,6.28,0,0,0,6.17,6.38h.14a6.28,6.28,0,0,0,6.17-6.38v-16A10,10,0,0,0,66.26,35.37ZM18.78,27A8.26,8.26,0,0,1,27,18.75H55.52A8.26,8.26,0,0,1,63.76,27v7.26a11.16,11.16,0,0,0-3.34-.51H22.1a11.3,11.3,0,0,0-3.32.5Zm2.48,32.87a3.79,3.79,0,0,1-3.68,3.88h-.14a3.79,3.79,0,0,1-3.67-3.88V56.25h7.49Zm1.25-6.12H13.77V43.89a7.29,7.29,0,0,1,2.51-5.45,8.22,8.22,0,0,1,2.5-1.55,8.88,8.88,0,0,1,3.32-.64H60.42a9,9,0,0,1,3.34.65,8.44,8.44,0,0,1,2.5,1.56,7.34,7.34,0,0,1,2.49,5.43v9.86H22.51Zm46.24,6.12a3.79,3.79,0,0,1-3.67,3.88h-.14a3.78,3.78,0,0,1-3.67-3.88V56.25h7.48Z"/><path class="cls-1" d="M24.48,40a4.76,4.76,0,0,0-5.7-.55,5,5,0,1,0,7.42,4.3A5.17,5.17,0,0,0,26,42.5,4.94,4.94,0,0,0,24.48,40Zm-.78,3.75a2.45,2.45,0,1,1-4.9,0,2.45,2.45,0,0,1,4.9,0Z"/><path class="cls-1" d="M66.2,43.75a5.16,5.16,0,0,0-2.44-4.28,4.93,4.93,0,0,0-7.28,3,5.17,5.17,0,0,0-.18,1.25,5,5,0,1,0,9.9,0ZM59.15,42.5a2.45,2.45,0,0,1,4.55,1.25,2.45,2.45,0,1,1-4.9,0A2.42,2.42,0,0,1,59.15,42.5Z"/></g></svg>
            Dealer Information
            <span
            *ngIf="!sellerDataReceived"
            class="spinner-border spinner-border-md"
            role="status"
            aria-hidden="true"
          ></span>
        </h5>
        </div>
        <div class="row justify-content-between mg-x-15 ">
          <label class="form-control-label mb-0 tx-bold tx-primary"
            >{{ payeeNameLabel }}
          </label>
          <p class="form-control-label mb-0 tx-primary tx-bold text-break">
            {{ accountNumber }}
          </p>
        </div>
        <div class="row justify-content-between mg-x-15 ">
          <label class="form-control-label mb-0 tx-bold tx-primary"
            >Payment Method
          </label>
          <p class="form-control-label mb-0 tx-primary tx-bold text-break">
            {{ paymentMethod }}
          </p>
        </div>
        <div class="row justify-content-start bd-b mg-x-15 pd-t-20">
          <label class="form-control-label mb-0 tx-bold tx-primary"
            >Rates
            <i class="fa fa-info-circle pd-t-8 zurich-blue" style=" cursor: pointer;" ngbTooltip="Claim payments are set up at your Dealer's reimbursement rates."></i>
            <span
            *ngIf="ratesCheck"
            class="spinner-border spinner-border-sm ml-1"
            role="status"
            aria-hidden="true"
          ></span>
          </label>
        </div>
        <div class="row justify-content-between bd-b mg-x-15 pd-t-10">
          <label class="form-control-label mb-0  tx-primary"
            >Labor Rate
          </label>
          <p class="form-control-label mb-0 tx-primary  text-break">
            {{ laborBillingRateFormatted }}
          </p>
        </div>
        <div class="row justify-content-between bd-b mg-x-15 pd-t-10">
          <label class="form-control-label mb-0  tx-primary"
            >Labor Tax
          </label>
          <p class="form-control-label mb-0 tx-primary  text-break">
            {{ laborTax }}%
          </p>
        </div>
        <div class="row justify-content-between bd-b mg-x-15 pd-t-10">
          <label class="form-control-label mb-0  tx-primary"
            >Part Tax
          </label>
          <p class="form-control-label mb-0 tx-primary  text-break">
            {{ partTax }}%
          </p>
        </div>
        <div *ngIf="partMarkup != 0" class="row justify-content-between bd-b mg-b-10 mg-x-15 pd-t-10">
          <label class="form-control-label mb-0 tx-primary"
            >Parts Adjustment
          </label>
          <p class="form-control-label mb-0 tx-primary text-break">
            {{ partMarkup }}%
          </p>
        </div>
      </div>
      </div>
      <div class="col-lg-9">
      <div class="row pd-b-20">
        <h5 class="tx-primary">
          <svg  width="45" height="35" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 82.5 82.5"><defs><style>.cls-1{fill:#2267ae;}</style></defs><title>Document_Blue_Outline_RGB</title><g id="icons"><path class="cls-1" d="M61.41,7.5H31.14a1.65,1.65,0,0,0-.32.06l-.12,0a1.38,1.38,0,0,0-.36.23h0l-7.5,7.5-7.5,7.5a1.24,1.24,0,0,0-.24.37.49.49,0,0,0,0,.11,1.3,1.3,0,0,0-.07.33s0,0,0,.08V63.8A6.19,6.19,0,0,0,21.16,70H61.23a6.24,6.24,0,0,0,6.24-6.23V13.55A6.06,6.06,0,0,0,61.41,7.5ZM30,11.76v9a1.75,1.75,0,0,1-1.76,1.74h-9l5.37-5.36Zm35,52a3.74,3.74,0,0,1-3.74,3.73H21.16a3.69,3.69,0,0,1-3.69-3.69V25H28.21a4.25,4.25,0,0,0,4.26-4.24V10H61.41A3.56,3.56,0,0,1,65,13.55Z"/><path class="cls-1" d="M56.21,22.5h-15a1.25,1.25,0,0,0,0,2.5h15a1.25,1.25,0,1,0,0-2.5Z"/><path class="cls-1" d="M56.21,32.5h-30a1.25,1.25,0,0,0,0,2.5h30a1.25,1.25,0,1,0,0-2.5Z"/><path class="cls-1" d="M56.21,42.5h-30a1.25,1.25,0,0,0,0,2.5h30a1.25,1.25,0,1,0,0-2.5Z"/><path class="cls-1" d="M41.21,52.5h-15a1.25,1.25,0,0,0,0,2.5h15a1.25,1.25,0,1,0,0-2.5Z"/></g></svg>
          Repair Order Information</h5>
      </div>
      <div class="row justify-content-center mg-b-25">
        <div class="col-lg-4">
          <div class="form-group row pd-x-15">
            <label class="tx-primary tx-bold label-field-width mg-r-3 col-form-label"
              >Number <span class="tx-danger mg-r-2">*</span> <span
              *ngIf="roCheckSpinner"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span></label
            >
            <input
              tabindex="1"
              class="form-control col input-field-width"
              value=""
              (blur)="checkRepairOrderNumber()"
              [formControlName]="'repairOrderNumber'"
              [ngClass]="{
                'is-invalid': (submitted && f.repairOrderNumber.errors) || showRoError
              }"
              required
            />
            <label
            class="tx-danger"
            *ngIf="showRoError"
            >{{roErrorMessage}}
          </label>

          </div>
        </div>
        <div class="col-lg-4 ">
          <div class="form-group row pd-x-15">
          <label class=" tx-primary tx-bold label-field-width-date mg-r-3 col-form-label"
            >Date <span class="tx-danger">*</span></label
          >
          <!--div class="input-group col input-field-width"-->
            <input
            tabindex="2"
              type="date"
              class="form-control col fc-datepicker"
              placeholder="dd-mm-yyyy"
              [formControlName]="'lossDate'"
              (blur)="blurLossDate()"
              [ngClass]="{ 'is-invalid': ( submitted && f.lossDate.errors ) || lossDateIncorrectError }"
              required
              min="{{ lossDateMin }}"
              max="{{ lossDateMax }}"
            />
          <!--/div-->
          <!-- input-group -->
          <!-- <label
            class="tx-danger"
            *ngIf="lossDateEmptyError && submitted && f.lossDate.errors"
            >Please provide a value
          </label> -->
          <label
            class="tx-danger"
            *ngIf="lossDateIncorrectError ||(submitted && f.lossDate.errors)"
            >Repair Order Date does not fall within the terms of this contract - please
            confirm the Repair Order Date
          </label>
        </div>
        </div>
        <!-- col-4 -->
        
        <div class="col-lg-4">
          <div class="form-group row pd-x-15">
            <label class="tx-primary tx-bold label-field-width mg-r-3 col-form-label"
              >Odometer <span class="tx-danger">*</span></label
            >
            <input
            tabindex="3"
              class="form-control col input-field-width"
              value=""
              required
              (change)="validateOdometer($event)"
              [(ngModel)]="lossOdometerValue"
              name="lossOdometerValue"
              [formControlName]="'lossOdometer'"
              [ngClass]="{
                'is-invalid':
                  (submitted && f.lossOdometer.errors) || odometerError
              }"
            />
            <div *ngIf="(submitted && f.lossOdometer.errors) || odometerError" class="tx-danger">
              Loss odometer is outside the eligible mileage.
            </div>
            <!-- <label
              class="tx-danger"
              *ngIf="
                lossOdometerEmptyError && f.lossOdometer.errors && submitted
              "
              >Please provide a value
            </label> -->
            <label
              class="tx-info"
              *ngIf="
                lossOdometerIncorrectError && f.lossOdometer.errors && submitted
              "
              >Loss odometer is less than Sale Odometer, - please confirm the
              loss odometer
            </label>
          </div>
        </div>
        <div class="col-lg-4">
        <div class="form-group row pd-t-10 pd-x-15">
          <label class="tx-primary tx-bold label-guid-field-width col-form-label"
            >Labor Guide <span class="tx-danger mg-r-2">*</span></label
          >
          <select
          tabindex="4"
            class="form-control col select2"
            [formControlName]="'laborGuide'"
            data-placeholder="Select"
            (change)="showLaborGuideOtherDescField()"
            [ngClass]="{
              'is-invalid': submitted && f.laborGuide.errors
            }"
            required
          >
            <option label="Select" value="Select" [selected]="!startNewClaimForm.controls.laborGuide.value" [hidden]="true">
              Please input labor guide used
            </option>
            <option value="Chilton">Chilton</option>
            <option value="Mitchell">Mitchell</option>
            <option value="Motor">Motor</option>
            <option value="Other">Other</option>
          </select>

        </div>
      </div>
      <div class="col-lg-4 form-group pd-t-10">
        <div *ngIf="showLaborGuideOtherDesc" class="form-group row pd-x-15">
          <input
          tabindex="5"
          class="form-control col input-field-width"
          placeholder="Please input, labor guide used"
          [formControlName]="'laborGuideOther'"
          [ngClass]="{
            'is-invalid': submitted && f.laborGuideOther?.errors
          }"
        />
        </div>
      </div>
      <div class="col-lg-4"></div>
      </div>
      <!-- <div class="row pd-l-50 mg-b-5"> -->
        <div class="row pd-b-20">
          <h5 class="tx-primary">
            <svg width="45" height="35" id="icono" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 82.5 82.5"><defs><style>.cls-1{fill:#2167ae;}</style></defs><path class="cls-1" d="M74.83,49.27c-2.63-2.69-7-4.08-13-4.16C60,43.38,50.31,35,36.25,35c-4.44,0-10.13,1.31-16.91,3.9a.67.67,0,0,0-.18.11.78.78,0,0,0-.21,0A118.5,118.5,0,0,0,5.64,45.19,1.27,1.27,0,0,0,5,46.29v10c0,4.82,4.53,6.32,6.18,6.32h2.25a1,1,0,0,0,.22-.05,8.29,8.29,0,0,0,15.16.05H56.18a8.29,8.29,0,0,0,15.14,0h4.92a1.25,1.25,0,0,0,1.25-1.25v-5.1A9.36,9.36,0,0,0,74.83,49.27Zm-17-4.18H39.37V37.67A37.53,37.53,0,0,1,57.87,45.09Zm-21-7.56v7.56H28.75c-5.71,0-7.45-3.76-7.54-3.94l-.1-.24c6.1-2.25,11.2-3.41,15.14-3.41C36.46,37.5,36.66,37.52,36.87,37.53ZM21.25,65a5.81,5.81,0,1,1,5.8-5.81A5.81,5.81,0,0,1,21.25,65Zm0-14.11a8.31,8.31,0,0,0-8.3,8.3,6.7,6.7,0,0,0,.05.9H11.2s-3.69-.4-3.69-3.82V51.6H9.15a1.25,1.25,0,0,0,0-2.5H7.5V47a119.93,119.93,0,0,1,11.26-5.22l.17.36c.1.22,2.41,5.42,9.82,5.42h8.12v12.5H29.5a6.7,6.7,0,0,0,.05-.9A8.31,8.31,0,0,0,21.25,50.89ZM63.75,65a5.81,5.81,0,1,1,5.8-5.81A5.81,5.81,0,0,1,63.75,65ZM75,60.09H72a6.7,6.7,0,0,0,0-.9,8.3,8.3,0,0,0-16.6,0,6.7,6.7,0,0,0,0,.9H39.37V47.59H61.25c5.59,0,9.56,1.15,11.79,3.43a6.89,6.89,0,0,1,2,5.17Z"/><path class="cls-1" d="M63.73,10A13.75,13.75,0,1,0,77.48,23.75,13.77,13.77,0,0,0,63.73,10Zm0,25A11.25,11.25,0,1,1,75,23.75,11.27,11.27,0,0,1,63.73,35Z"/><path class="cls-1" d="M63.76,14.81a2.81,2.81,0,1,0,2.81,2.81A2.81,2.81,0,0,0,63.76,14.81Z"/><rect class="cls-1" x="60.95" y="22.45" width="5.62" height="10.47" rx="2.02"/></svg>
            Vehicle Condition</h5>
        </div>
      <div class="row justify-content-center mg-b-25">
        <div class="col-lg-4">
          <div class="form-group mg-b-10-force">
            <label class="tx-primary tx-bold form-control-label"
              >Vehicle Towed? <span class="tx-danger">*</span></label
            >
            <select
            tabindex="6"
              class="form-control select2"
              data-placeholder="Select"
              (change)="selectYesNo(selectField1.value, '1')"
              #selectField1
              [ngClass]="{ 'is-invalid': submitted && f.isCarTowedIn.errors }"
              [formControlName]="'isCarTowedIn'"
            >
              <option label="Select" value="Select" [selected]="!editClaim" [hidden]="true">
                Select
              </option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
            <!-- <span class="tx-info" *ngIf="selectedQuestion1"
              >A tow bill is required for payment of tow services</span
            > -->
            <!-- <div class="custom-file" *ngIf="selectedQuestion1">
          <input type="file" class="custom-file-input" id="customFile2"  />
          <label
            class="custom-file-label custom-file-label-primary"
            for="customFile"
            >Choose file</label
          >
        </div> -->
            <!-- <div class="card pd-20 col-12" *ngIf="selectedQuestion1">
          
              <form
                [formGroup]="uploadForm"
                (ngSubmit)="onSubmit()"
                class="custom-file pd-b-90"
              >
                <div>
                  <input
                    [formControlName]="'uploadFile'"
                    type="file"
                    name="profile"
                    (change)="onFileSelect($event)"
                  />
                </div>
                <div class="mg-t-10">
                  <button
                    type="submit"
                    class="btn-medium btn-primary btn-oblong"
                  >
                    Upload
                  </button>
                </div>
              </form>
              <label class="col-12 control-label"> Uploaded files </label>

              <ul class="list-group col-12 pd-5 justify-content-around">
                <li
                  *ngFor="let attachment of attachments; let i = index"
                  class="row"
                >
                  <div class="col-8">{{ attachment.filename }}</div>
                  <button
                    class="btn btn-oblong btn-light btn-sm tx-dark font-weight-bold col-2"
                    (click)="deleteAttachment(attachment)"
                    type="button"
                  >
                    <i class="ion-trash-a mg-r-5"></i>
                  </button>
                  <button
                    class="btn btn-oblong btn-light btn-sm tx-dark font-weight-bold col-2"
                    (click)="downloadAttachment(attachment)"
                    type="button"
                  >
                    <i class="fa fa-download mg-r-5"></i>
                  </button>
                </li>
              </ul>
            </div> -->
            <!-- custom-file -->
          </div>
        </div>

        <div class="col-lg-4">
          <div class="form-group mg-b-10-force">
            <label class="tx-primary tx-bold form-control-label"
              >Vehicle Modifications <span class="tx-danger">*</span>
              <i class="fa mg-l-5 fa-info-circle zurich-blue" style="font-size: 1.35em; cursor: pointer;" ngbTooltip="Please list any modifications to the vehicle and provide a detailed description for those repairs that are a direct result of any modification."></i>

              </label
            >
            <select
            tabindex="7"
              class="form-control select2"
              data-placeholder="Select"
              (change)="selectYesNo(selectField2.value, '2')"
              #selectField2
              [formControlName]="'anyModificationsBoolean'"
              [ngClass]="{
                'is-invalid': submitted && f.anyModificationsBoolean.errors
              }"
            >
              <option label="Select" value="Select" [selected]="!editClaim" [hidden]="true">
                Select
              </option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
          <div class="form-group" *ngIf="selectedQuestion2">
            <!-- <label class="form-control-label tx-info"
              >Please provide a brief description of the modification(s)
              <span class="tx-danger">*</span></label
            > -->
            <textarea
            tabindex="8"
              class="form-control"
              type="text"
              name="modificationDesc"
              value=""
              placeholder="Please provide a brief description"
              [formControlName]="'modificationsDesc'"
              [ngClass]="{
                'is-invalid':
                  submitted && selectedQuestion2 && f.modificationsDesc.errors
              }"
            ></textarea>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="form-group mg-b-10-force">
            <label class="tx-primary tx-bold form-control-label"
              >Commercial Use Vehicle
              <span class="tx-danger">*</span>
              <i class="fa mg-l-5 fa-info-circle zurich-blue" style="font-size: 1.35em; cursor: pointer;" ngbTooltip="Please describe any commercial use.  Commercial use includes, but is not limited to: Vehicles used for business, commercial towing or hauling, livery / delivery or ridesharing (Uber, Lyft, etc)."></i>
            </label>
            <select
            tabindex="9"
              class="form-control select"
              data-placeholder="Select"
              (change)="selectYesNo(selectField3.value, '3')"
              #selectField3
              [formControlName]="'commercialUse'"
              [ngClass]="{ 'is-invalid': submitted && f.commercialUse.errors }"
            >
              <option label="Select" value="Select" [selected]="!editClaim" [hidden]="true">
                Select
              </option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
          <div class="form-group" *ngIf="selectedQuestion3">
            <!-- <label class="form-control-label tx-info"
              >Please provide a brief description of the commercial, delivery or
              ride share use<span class="tx-danger">*</span></label
            > -->
            <textarea
            tabindex="10"
              class="form-control"
              type="text"
              name="modificationDesc"
              value=""
              placeholder="Please provide a brief description"
              [formControlName]="'commercialUseDesc'"
              [ngClass]="{
                'is-invalid':
                  submitted && selectedQuestion3 && f.commercialUseDesc.errors
              }"
            ></textarea>
            <!-- <div class="card pd-20 col-12">
      
              <form
                [formGroup]="uploadForm"
                (ngSubmit)="onSubmit()"
                class="custom-file pd-b-90"
              >
                <div>
                  <input
                    [formControlName]="'uploadFile'"
                    type="file"
                    name="profile"
                    (change)="onFileSelect($event)"
                  />
                </div>
                <div class="mg-t-10">
                  <button
                    type="submit"
                    class="btn-medium btn-primary btn-oblong"
                  >
                    Upload
                  </button>
                </div>
              </form>
              <label class="col-12 control-label"> Uploaded files </label>

              <ul class="list-group col-12 pd-5 justify-content-around">
                <li
                  *ngFor="let attachment of attachments; let i = index"
                  class="row"
                >
                  <div class="col-8">{{ attachment.filename }}</div>
                  <button
                    class="btn btn-oblong btn-light btn-sm tx-dark font-weight-bold col-2"
                    (click)="deleteAttachment(attachment)"
                    type="button"
                  >
                    <i class="ion-trash-a mg-r-5"></i>
                  </button>
                  <button
                    class="btn btn-oblong btn-light btn-sm tx-dark font-weight-bold col-2"
                    (click)="downloadAttachment(attachment)"
                    type="button"
                  >
                    <i class="fa fa-download mg-r-5"></i>
                  </button>
                </li>
              </ul>
            </div> -->
          </div>
        </div>
      </div>


      <!-- <div class="row justify-content-center"> -->

        <!-- <div class="col-lg-6"> -->
          <div *ngIf="!hidePaymentsSection || !sellerDataReceived" class="row pd-b-0">
            <h5 class="tx-primary">
              <svg width="45" height="35" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 82.5 82.5"><defs><style>.cls-1{fill:#2267ae;}</style></defs><title>Dollar_Blue_Outline_RGB</title><g id="icons"><path class="cls-1" d="M48.75,32.45a1.25,1.25,0,0,0,2.5,0,10,10,0,0,0-8.75-9.9v-3.8a1.25,1.25,0,0,0-2.5,0v3.8a10,10,0,0,0,0,19.81v15a7.5,7.5,0,0,1-6.25-7.38,1.25,1.25,0,0,0-2.5,0A10,10,0,0,0,40,59.84v3.91a1.25,1.25,0,0,0,2.5,0V59.84A10,10,0,0,0,42.5,40v-15A7.49,7.49,0,0,1,48.75,32.45Zm-15,0A7.5,7.5,0,0,1,40,25.08V39.83A7.51,7.51,0,0,1,33.75,32.45Zm15,17.48a7.49,7.49,0,0,1-6.25,7.38V42.56A7.49,7.49,0,0,1,48.75,49.93Z"/><path class="cls-1" d="M41.25,10A31.25,31.25,0,1,0,72.5,41.25,31.29,31.29,0,0,0,41.25,10Zm0,60A28.75,28.75,0,1,1,70,41.25,28.79,28.79,0,0,1,41.25,70Z"/></g></svg>
              Payments sent to:

            </h5>
            <i class="fa mg-l-5 fa-info-circle pd-t-8 zurich-blue" style="font-size: 1.35em; cursor: pointer;" ngbTooltip="Payment method defaults can only be adjusted by contacting your Zurich Account Representative.  If you need additional fax/email information added to the drop down, please submit an update using the 'Feedback' section within 'Help'."></i>
            
          </div>
          <span
            *ngIf="!sellerDataReceived"
            class="spinner-border spinner-border-md"
            role="status"
            aria-hidden="true"
          ></span>
          <!-- <div class="row justify-content-between bd-b mg-l-15 pd-t-10">
            <label class="form-control-label mb-0 tx-bold tx-primary"
              >Payee Name
            </label>
            <p class="form-control-label mb-0 tx-primary tx-bold text-break">
              {{ payeeNameLabel }}
            </p>
          </div>
          <div class="row justify-content-between bd-b mg-l-15 pd-t-10">
            <label class="form-control-label mb-0 tx-bold tx-primary"
              >Payment Method
            </label>
            <p class="form-control-label mb-0 tx-primary tx-bold text-break">
              {{ paymentMethod }}
            </p>
          </div>  -->


<div *ngIf="!hidePaymentsSection" class="row">
          <div class="form-group col-lg-4 ">
            <div class="form-group row pd-x-15">
              <!-- <label class="tx-primary tx-bold label-communication-field-width mg-r-3 col-form-label"
                >via:  <span class="tx-danger mg-r-2">*</span></label
              > -->
            <select
            tabindex="13"
              class="form-control col select2"
              data-placeholder="Select"
              [formControlName]="'billingMethodValue'"
              [ngClass]="{
                'is-invalid': submitted && f.billingMethodValue.errors
              }"
              
              
              required
            >
              <option label="Select" value="Select"  [hidden]="true">
                Select
              </option>

              <ng-container *ngIf="f.billingMethod.value == 'E-Mail'">
                <ng-container *ngFor="let emailElem of emails">
                  <option  [value]="emailElem.value">{{emailElem.value}}</option>
                  
                </ng-container>
                <option value="OtherEmail">Other</option>
              </ng-container>

              <ng-container *ngIf="f.billingMethod.value == 'Fax'">
                <ng-container *ngFor="let element of faxes">
                  <option  [value]="element.value">{{transformFax(null, element)}}</option>
                </ng-container>
                <option value="OtherFax">Other</option>
              </ng-container>
              
            </select>
          </div>
          </div>
          <div
          class="form-group col-lg-4 "
          *ngIf="paymentMethod == 'Credit Card' && f.billingMethod.value == 'E-Mail' && f.billingMethodValue.value == 'OtherEmail'"
        >
          <!--label class="form-control-label"
            >Notification E-Mail<span class="tx-danger">*</span></label
          -->
          <input
          tabindex="14"
            class="form-control "
            value=""
            placeholder="Please input E-Mail"
            [formControlName]="'email'"
            [ngClass]="{
              'is-invalid': (submitted || f.email.touched) && f.email.errors
            }"
          />
         
        </div>
        <div
          class="form-group col-lg-4 "
          *ngIf="paymentMethod == 'Credit Card' && f.billingMethod.value == 'Fax' && f.billingMethodValue.value == 'OtherFax'"
        >
          <!--label class="form-control-label"
            >Notification Fax Number<span class="tx-danger">*</span></label
         -->
          <input
          tabindex="15"
            class="form-control "
            value=""
            (change)="transformFax($event)"
            
            [(ngModel)]="formattedFax"
            placeholder="Please input fax number"
            [formControlName]="'fax'"
            name="fax"
            [ngClass]="{
              'is-invalid': (submitted && f.fax.errors) || faxError
            }"
          />
        </div>
        <div class="form-group col-lg-4 ">
          <div class="form-group row pd-x-15">
          <label class="tx-primary tx-bold label-communication-field-width mg-r-3 col-form-label"
            >via:  <span class="tx-danger mg-r-2">*</span></label
          >
          <select
          tabindex="16"
            class="form-control col select2"
            data-placeholder="Select"
            (change)="billingMethodChanged($event?.target?.value)"
            [formControlName]="'billingMethod'"
            [ngClass]="{
              'is-invalid': submitted && f.billingMethod.errors
            }"
            
            
            required
          >
            <option label="Select" [selected]="!editClaim && !startNewClaimForm.controls.billingMethod.value" [hidden]="true">
              Select
            </option>
            <option value="E-Mail">E-Mail</option>
            <option value="Fax">Fax</option>
          </select>
        </div>
        </div>
        </div>
      
</div>
</div>
      <div class="row justify-content-between pd-b-20">
        <div class="row justify-content-start-custom">
          <button
          tabindex="18"
          type="button"
          class="btn btn-oblong btn-info btn btn-block mg-t-8 mg-r-30 chevron-button"
          (click)="setClaimDelete()"
          data-toggle="modal"
          attr.data-target="#confirmActionModal{{indexNum}}"
          id="deleteClaimButtonFromClaimInfo"
          [disabled]="disableDelete"
          >
          Delete
          </button>
        </div>
    <div>
      <div class="row justify-content-end-custom">
        <button
        tabindex="17"
          type="button"
          class="btn btn-oblong btn-info btn btn-block mg-t-8 chevron-button"
          (click)="saveAsDraft(false)"
          [disabled]="disableDelete || showSpinnerDraft || (LOCK_CALLS_UPDATE || LOCK_CALLS_UNLOCK)"
        >
          <span
            *ngIf="showSpinnerDraft"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Save as Draft
        </button>

        <button
        tabindex="16"
          type="submit"
          class="btn btn-oblong btn-primary btn btn-block mg-xs-l-30 mg-sm-l-30 mg-md-l-30  mg-lg-l-30 mg-xl-l-30 mg-xl-r-30 btn btn-block chevron-button"
          [disabled]="(!disableDelete && !sellerDataReceived) || (LOCK_CALLS_UPDATE || LOCK_CALLS_UNLOCK)"
        >
          <span
            *ngIf="showSpinner"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Continue
        </button>
      </div>
    </div>
      </div>
      <app-confirm-action
         
        [header]="header"  
        [message]="message"
        [buttonMessage]="buttonMessage"    
        [indexNum]="indexNum"
        (confirmActionEvent)="deleteClaim($event)"
    ></app-confirm-action>
      <!-- row -->
    </div>
  </form>
  <!-- form-layout -->
</div>
<!-- section-wrapper -->
