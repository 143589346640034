import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { Claim } from '../_model/claim';
import { Contract } from '../_model/contract';
import { ClaimService } from '../_services/claim.service';
import { ContractService } from '../_services/contract.service';

@Component({
  selector: 'app-claim-list-detail-tabs',
  templateUrl: './claim-list-detail-tabs.component.html',
  styleUrls: ['./claim-list-detail-tabs.component.css'],
})
export class ClaimListDetailTabsComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() customerInformation: string;
  @Input() vehicleInformation: string;
  @Input() contractInformation: string;

  @Input() indexNum: number;
  @Input() hideButton: boolean;
  @Input() draftClaim: boolean;
  @Input() activeClaim: boolean;


  displayBanner: boolean = false;

  contractsSubscription: Subscription;
  claimSubscription: Subscription;
  contractSubscription: Subscription;

  contract: Contract;
  contractInfo: Contract;
  contractId: string;
  claim: Claim;
  customerTabActive:boolean = true;
  vehicleTabActive:boolean = false;
  contractTabActive: boolean = false;

  constructor(private contractService: ContractService, private claimService: ClaimService) {}
  ngAfterViewInit(): void {
    document.getElementById("customerTabNav" + this.indexNum)?.click();
  }

  ngOnChanges(changes) {
    //document.getElementById("customerTabNav")?.click();
    this.contractsSubscription = this.contractService.contractId.subscribe(
      (value) => {
        this.contractId = value;

       this.claimSubscription = this.claimService.claim.subscribe((result) => {
          this.claim = result;
        });
        this.contractsSubscription = this.contractService.contract.subscribe((contractResult:any)=>{
          this.contract = contractResult;
        });
        let contractInfo ;
        
          contractInfo = {
            lastName: this.contract?.customer?.lastName,
            vin: this.contract?.vehicle?.vIN,
            contractNumber: this.contract?.contractNumber
          }
        if(!contractInfo?.lastName || ((this.activeClaim || this.draftClaim) && this.claim)){
          let lastName = this.claim.lastName;
          let vin = this.claim.vin;
          contractInfo = {
            lastName: lastName,
            vin: vin,
            contractNumber: this.contractId
        };
      
      }
        //console.log(this.indexNum);
        if (
          value &&
          this.indexNum == this.contractService.contractIndex.getValue()
        ) {
          this.contractService
            .searchContract(contractInfo)
            .subscribe(
              (result: any) => {
                console.log(result);
                if(result){
                  for (let index = 0; index < result.Contracts.length; index++) {
                    const element = result.Contracts[index];
                    if(element.contractNumber === contractInfo.contractNumber){
                      this.contractInfo = element;
                      if(this.contractInfo?.customer?.address?.homePhone)
                        this.contractInfo.customer.address.homePhone= this.transformPhoneNumber(this.contractInfo?.customer?.address?.homePhone);
                      if(this.contractInfo?.customer?.address?.workPhone)
                        this.contractInfo.customer.address.workPhone= this.transformPhoneNumber(this.contractInfo?.customer?.address?.workPhone);
                      
                      this.contractInfo.deductible= this.contractInfo.deductible.trim();

                      if(this.contractInfo.deductible?.endsWith("Disappearing")){
                        this.contractInfo.deductible = this.contractInfo.deductible.replace("Disappearing","");
                        this.contractInfo.deductible = this.contractInfo.deductible.trim();
                        this.contractInfo.deductible="-"+this.contractInfo.deductible;
                      }  
                        break;
                    }
                  }
                  
                }
                //document.getElementById("customerTabNav")?.click();
              },
              (error) => console.log(error)
            );
        }
      }
    );
  }
  ngOnDestroy() {
    this.contractsSubscription?.unsubscribe();
    this.contractSubscription?.unsubscribe();
    this.claimSubscription?.unsubscribe();
  }
  ngOnInit(): void {
    // this.contractsSubscription = this.contractService.contract.subscribe(
    //   (value) => {
    //     this.contract = value;
    //     console.log(this.contract);
    //     if (this.contract?.contractNumber) {
    //       this.contractService
    //         .getClaimByContractNumber(this.contract?.contractNumber)
    //         .subscribe(
    //           (result: any) => {
    //             console.log(result);
    //             this.contractInfo = result;
    //           },
    //           (error) => console.log(error)
    //         );
    //     }
    //   }
    // );
  }
  setContractActive(){
    this.customerTabActive = false;
    this.vehicleTabActive = false;
    this.contractTabActive = true;
    document.getElementById("contractTabNav" + this.indexNum)?.click();
  }
  setVehicleActive(){
    this.customerTabActive = false;
    this.vehicleTabActive = true;
    this.contractTabActive = false;
    document.getElementById("vehicleTabNav" + this.indexNum)?.click();
  }
  setCustomerActive(){
    this.customerTabActive = true;
    this.vehicleTabActive = false;
    this.contractTabActive = false;
    document.getElementById("customerTabNav" + this.indexNum)?.click();
  }
  setInfo() {}
  transformPhoneNumber(number) {
    if (!number) return;
    let newVal =number?.replace(/\D/g, '');
    if (!newVal)
      return;
    
   
    if (newVal?.length === 0) {
      newVal = '';
    } else if (newVal?.length <= 3) {
      newVal = newVal?.replace(/^(\d{0,3})/, '($1)');
    } else if (newVal?.length <= 6) {
      newVal = newVal?.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
    } else if (newVal?.length <= 10) {
      newVal = newVal?.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
    } else {
      newVal = newVal?.substring(0, 10);
      newVal = newVal?.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
    }
    return newVal;
  }
}
